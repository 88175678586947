import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

const ALL_DEBIT_ENTRIES = loader('./graphql/allDebitEntries.graphql')

export default function DebitEntryList() {
	const {
		data: { allDebitEntries = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_DEBIT_ENTRIES)

	const columns = [
		{ field: 'date', headerName: 'Date', flex: 1 },
		{
			field: 'debitItem',
			headerName: 'Debit Item',
			flex: 1,
			renderCell: ({ row }) => row?.debitItem?.item,
		},
		{ field: 'amount', headerName: 'Amount', flex: 1 },
		{
			field: 'currency',
			headerName: 'Currency',
			flex: 1,
			renderCell: ({ row }) => row?.currency?.currency,
		},
		{
			field: 'createdBy',
			headerName: 'User',
			flex: 1,
			renderCell: ({ row }) => row?.createdBy?.name,
		},
	]

	if (localStorage.getItem('debitEntryState') !== '') {
		toast.success(localStorage.getItem('debitEntryState'))
	}

	if (localStorage.getItem('createDebitEntryState') !== '') {
		toast.success(localStorage.getItem('createDebitEntryState'))
	}

	useEffect(() => {
		// Clear the local storage
		const timer = setTimeout(() => {
			localStorage.setItem('debitEntryState', '')
			localStorage.setItem('createDebitEntryState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allDebitEntries])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allDebitEntries && (
				<DataGrid
					rows={allDebitEntries}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
