import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import SaveIcon from '@material-ui/icons/Save'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PageviewIcon from '@mui/icons-material/Pageview'
import EventIcon from '@mui/icons-material/Event'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import DeleteIcon from '@mui/icons-material/Delete'
import * as moment from 'moment'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import { Button, TableCell } from '@material-ui/core'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormLabel from '@mui/material/FormLabel'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Badge from '@mui/material/Badge'

import Spinner from '../../UI/Spinner'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import CompanyIcon from '@mui/icons-material/Apartment'
import EmployeeIcon from '@mui/icons-material/Badge'
import DirectionsIcon from '@mui/icons-material/Directions'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'

import '../../../asset/style.css'

const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const ALL_MARCHANDISES = loader('./graphql/allMarchandises.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')
const CREATE_BOOKING = loader('./graphql/createBooking.graphql')
const CREATE_CLIENT = loader('./graphql/createClient.graphql')
const CREATE_MARCHANDISE = loader('./graphql/createMarchandise.graphql')
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')
const VOYAGE = loader('./graphql/voyagesByDestinationAndDate.graphql')
const CONTRACT = loader('./graphql/contract.graphql')
const ALL_DEPOTS_BY_PORT = loader('./graphql/depotsByPort.graphql')

const CreateBooking = () => {
	const currentUser = useCurrentUser()

	const [estimateWeight, setEstimateWeight] = useState()
	const [containerTypeInContractQuantity, setContainerTypeInContractQuantity] =
		useState()
	const [selectedContainerPrice, setSelectedContainerPrice] = useState()
	const [selectedVoyage, setSelectedVoyage] = useState()
	const [selectedDepot, setSelectedDepot] = useState()
	const [selectedContainerType, setSelectedContainerType] = useState()
	const [quantity, setQuantity] = useState()
	const [selectedContainersArray, setSelectedContainersArray] = useState([])

	const [totalTeu, setTotalTeu] = useState(0)
	const [marchandiseInput, setMarchandiseInput] = useState(false)
	const [clientInput, setClientInput] = useState(false)
	const [marchandiseName, setMarchandiseName] = useState('')
	const [marchandisePrice, setMarchandisePrice] = useState(0)
	const [disablePriceFields, setDisablePriceFields] = useState(true)
	const [clientValues, setClientValues] = useState({
		name: '',
		company: '',
		email: '',
		address: '',
		language: 'en',
		currency: 'eur',
	})
	const [fees, setFees] = useState({
		transport: 0,
		waiver: 0,
		thco: 0,
		tsco: 0,
	})
	const [voyageValues, setVoyageValues] = useState({})
	const [pickupDate, setPickupDate] = useState()
	const [totalContainersPrice, setTotalContainersPrice] = useState(0)
	const [commodityPrice, setCommodityPrice] = useState()
	const [contractNumber, setContractNumber] = useState('')
	const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)

	const [values, setValues] = useState({
		clientId: null,
		clientRef: '',
		marchandiseId: null,
		userId: '',
		documentRef: '',
		price: 0,
		priceTransportMaritime: 0,
		document: 0,
		transport: 0,
		waiver: 0,
		thco: 0,
		tsco: 0,
		marineFuelRecovery: 0,
		thcd: 0,
		ose: 0,
		surveyExpenses: 0,
		terminalSecurityDestination: 0,
		carrierSecurityFee: 0,
		clearanceExportCustom: 0,
		priceDetails: false,
		voyageId: '',
		note: '',
	})

	const { data: { allClients = null } = {}, loading } = useQuery(ALL_CLIENTS)

	const { data: { allMarchandises = null } = {}, loading: marchandiseLoading } =
		useQuery(ALL_MARCHANDISES)

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const [
		getDepotsByPort,
		{ data: { depotsByPort = null } = {}, loading: depotsLoading, refetch },
	] = useLazyQuery(ALL_DEPOTS_BY_PORT)

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => `${option.name} ${option.company}`,
	})

	const [
		getVoyages,
		{
			data: { voyagesByDestinationAndDate = null } = {},
			loading: voyageLoading,
		},
	] = useLazyQuery(VOYAGE)

	const [
		getContract,
		{
			data: { contract = null } = {},
			loading: contractLoading,
			error: contractError,
		},
	] = useLazyQuery(CONTRACT)

	const {
		data: { allContainerTypes = null } = {},
		loading: allContainerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const [createBooking, { data = null, error, loading: createBookingLoading }] =
		useMutation(CREATE_BOOKING)

	const [
		createClient,
		{
			data: clientData,
			error: createClientError,
			loading: createClientLoading,
		},
	] = useMutation(CREATE_CLIENT)

	const [
		createMarchandise,
		{
			data: marchandiseData,
			error: createMarchandiseError,
			loading: createMarchandiseLoading,
		},
	] = useMutation(CREATE_MARCHANDISE)

	useEffect(() => {
		setValues({ ...values, userId: currentUser.id })
	}, [currentUser])

	useEffect(() => {
		if (contract) {
			setValues({ ...values, clientId: contract.client.id })
		}
	}, [contract])

	useEffect(() => {
		if (selectedVoyage) {
			//Pickup date is voyage departure date - 9 days
			setPickupDate(
				new Date(
					new Date(Number(selectedVoyage.departureDate)).setDate(
						new Date(Number(selectedVoyage.departureDate)).getDate() - 9
					)
				)
					.toISOString()
					.substring(0, 10)
			)
			getDepotsByPort({
				variables: {
					portId: voyageValues.loadingPortId,
				},
			})
		}
	}, [selectedVoyage])

	useEffect(() => {
		refetch()
	}, [depotsByPort])

	useEffect(() => {
		if (selectedContainerType && contract) {
			const containerInContract = contract.containers.find(
				({ containerType }) =>
					containerType.id === selectedContainerType.containerType.id
			)
			if (containerInContract) {
				setContainerTypeInContractQuantity(
					containerInContract.quantity - containerInContract.quantityTaken
				)
			}
		}
	}, [selectedContainerType, contract])

	useEffect(() => {
		if (selectedContainerType) {
			const containerTypeFromVoyage = selectedVoyage.containerTypesPrice.find(
				(containerTypesPricePerUnite) =>
					containerTypesPricePerUnite.containerType.id ===
					selectedContainerType.containerType.id
			)
			const containerPrice = contract
				? containerTypeFromVoyage.contractPrice
				: containerTypeFromVoyage.price
			setSelectedContainerPrice(containerPrice)
		}
	}, [selectedContainerType])

	const handleMarchendiseChange = ({ target }) => {
		if (target.checked) {
			setMarchandiseInput(true)
		} else {
			setMarchandiseInput(false)
		}
	}

	const handleClientChange = ({ target }) => {
		if (target.checked) {
			setClientInput(true)
		} else {
			setClientInput(false)
		}
	}

	useEffect(() => {
		const totalFees = Object.values(fees).reduce((a, b) => a + b)

		//If user creates a marchandise we take marchandisePrice value
		//If user selects a marchandise we take commodityPrice value
		const addedMarchandisePrice = commodityPrice
			? commodityPrice
			: marchandisePrice

		setTotalContainersPrice(addedMarchandisePrice || 0)

		if (selectedContainersArray.length) {
			setTotalContainersPrice(
				Number(
					selectedContainersArray
						.map(({ pricePerContainer, quantity }) =>
							Number(pricePerContainer * quantity)
						)
						.reduce((a, b) => a + b)
				) +
					Number(addedMarchandisePrice || 0) +
					Number(totalFees)
			)
		}
	}, [selectedContainersArray, commodityPrice, marchandisePrice, fees])

	useEffect(() => {
		if (
			(marchandiseInput || values.marchandiseId) &&
			(values.clientId || clientInput) &&
			selectedContainersArray.length &&
			values.voyageId
		) {
			setDisabledSubmitButton(false)
		}
	}, [values, marchandiseInput, clientInput, selectedContainersArray])

	const handleCreateBooking = () => {
		if (marchandiseInput && clientInput) {
			handleCreateClient()
			return handleCreateMarchandise()
		} else if (marchandiseInput && !clientInput) {
			return handleCreateMarchandise()
		} else if (!marchandiseInput && clientInput) {
			return handleCreateClient()
		} else {
			return createBooking({
				variables: {
					input: {
						...values,
						...fees,
						containers: selectedContainersArray.map(
							({
								depot,
								container,
								pricePerContainer,
								quantity,
								estimateWeight,
							}) => ({
								typeId: container.containerType.id,
								quantity,
								estimateWeight,
								pricePerContainer,
								depotId: depot.id,
							})
						),
						contractId: contract ? contract.id : null,
						total: Number(totalContainersPrice),
						pickupDate,
					},
				},
			})
		}
	}

	const handleCreateClient = () => {
		createClient({
			variables: {
				input: clientValues,
			},
		})
	}

	const handleCreateMarchandise = () => {
		createMarchandise({
			variables: {
				input: {
					name: marchandiseName,
					price: Number(marchandisePrice),
				},
			},
		})
	}

	const handleFetchVoyages = () => {
		return getVoyages({
			variables: voyageValues,
		})
	}

	useEffect(() => {
		if (!disablePriceFields) {
			calcTotal(values)
		}
	}, [
		disablePriceFields,
		values.priceTransportMaritime,
		values.transport,
		values.document,
		values.waiver,
		values.thco,
		values.tsco,
		values.marineFuelRecovery,
		values.thcd,
		values.ose,
		values.surveyExpenses,
		values.terminalSecurityDestination,
		values.carrierSecurityFee,
		values.clearanceExportCustom,
	])

	const calcTotal = (values) => {
		const {
			priceTransportMaritime,
			document,
			transport,
			waiver,
			thco,
			tsco,
			marineFuelRecovery,
			thcd,
			ose,
			surveyExpenses,
			terminalSecurityDestination,
			carrierSecurityFee,
			clearanceExportCustom,
		} = values

		const newTotal =
			Number(priceTransportMaritime) +
			Number(transport) +
			Number(document) +
			Number(waiver) +
			Number(thco) +
			Number(tsco) +
			Number(marineFuelRecovery) +
			Number(thcd) +
			Number(ose) +
			Number(surveyExpenses) +
			Number(terminalSecurityDestination) +
			Number(carrierSecurityFee) +
			Number(clearanceExportCustom)
		return setValues({ ...values, price: Number(newTotal) })
	}

	useEffect(() => {
		setSelectedContainerType(null)
		setQuantity(0)
		setSelectedContainerPrice()
		setEstimateWeight()
	}, [selectedDepot])

	useEffect(() => {
		//We can trigger the createBooking mutation
		if (clientData && marchandiseData) {
			createBooking({
				variables: {
					input: {
						...values,
						...fees,
						containers: selectedContainersArray.map(
							({
								depot,
								container,
								pricePerContainer,
								quantity,
								estimateWeight,
							}) => ({
								typeId: container.containerType.id,
								quantity,
								estimateWeight,
								pricePerContainer,
								depotId: depot.id,
							})
						),
						clientId: clientData?.createClient?.client?.id,
						marchandiseId: marchandiseData?.createMarchandise?.marchandise?.id,
						contractId: contract ? contract.id : null,
						total: Number(totalContainersPrice),
						pickupDate,
					},
				},
			})
		}
		//We can trigger the createBooking mutation
		if (clientData && !marchandiseData) {
			createBooking({
				variables: {
					input: {
						...values,
						...fees,
						clientId: clientData?.createClient?.client?.id,
						containers: selectedContainersArray.map(
							({
								depot,
								container,
								pricePerContainer,
								quantity,
								estimateWeight,
							}) => ({
								typeId: container.containerType.id,
								quantity,
								estimateWeight,
								pricePerContainer,
								depotId: depot.id,
							})
						),
						contractId: contract ? contract.id : null,
						total: Number(totalContainersPrice),
						pickupDate,
					},
				},
			})
		}
		if (!clientData && marchandiseData) {
			createBooking({
				variables: {
					input: {
						...values,
						...fees,
						marchandiseId: marchandiseData?.createMarchandise?.marchandise?.id,
						containers: selectedContainersArray.map(
							({
								depot,
								container,
								pricePerContainer,
								quantity,
								estimateWeight,
							}) => ({
								typeId: container.containerType.id,
								quantity,
								estimateWeight,
								pricePerContainer,
								depotId: depot.id,
							})
						),
						contractId: contract ? contract.id : null,
						total: Number(totalContainersPrice),
						pickupDate,
					},
				},
			})
		}
	}, [clientData, marchandiseData])

	if (
		data &&
		!error &&
		!createMarchandiseError &&
		!createClientError &&
		data.createBooking
	)
		return (
			<Redirect
				to={{
					pathname: `/edit-booking/${data.createBooking.booking.id}`,
					state: {
						success: true,
						msg: '✔️ Booking created successfully',
					},
				}}
			/>
		)
	else if (error || createMarchandiseError || createClientError) {
		var errorTxt = ''
		var separetor = '\n***************************************\n'
		if (createMarchandiseError) {
			errorTxt += createMarchandiseError?.message + separetor
		}
		if (createClientError) {
			errorTxt += createClientError?.message + separetor
		}
		if (error) {
			errorTxt += error?.message + separetor
		}
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	const handleRemoveContainerRow = (i) => {
		setSelectedContainersArray([
			...selectedContainersArray.filter((e, index) => index !== i),
		])

		setSelectedDepot(null)
		setSelectedContainerType(null)
		setQuantity(0)
		setSelectedContainerPrice()
		setEstimateWeight()
	}

	const handleAddingContainerRow = () => {
		const findSameContainerTypeInSameDepot = selectedContainersArray.find(
			({ depot, container }) =>
				depot.id === selectedDepot.id &&
				container.containerType.id === selectedContainerType.containerType.id
		)
		if (findSameContainerTypeInSameDepot) {
			const clonedSelectedContainersArrayWithNewQuantity = [
				...selectedContainersArray,
			].map((el) => {
				const { depot, container } = el
				if (
					depot.id === selectedDepot.id &&
					container.containerType.id === selectedContainerType.containerType.id
				) {
					return {
						...el,
						quantity: el.quantity + quantity,
					}
				}
			})

			setSelectedContainersArray([
				...clonedSelectedContainersArrayWithNewQuantity,
			])
		} else {
			setSelectedContainersArray([
				...selectedContainersArray,
				{
					depot: selectedDepot,
					container: selectedContainerType,
					quantity,
					pricePerContainer: selectedContainerPrice,
					estimateWeight,
				},
			])
		}

		setSelectedDepot(null)
		setSelectedContainerType(null)
		setQuantity(0)
		setSelectedContainerPrice()
		setEstimateWeight()
	}

	const handleFetchContract = () => {
		return getContract({
			variables: {
				contractNumber,
			},
		})
	}

	if (!currentUser) return <Redirect to="/" />

	if (
		marchandiseLoading ||
		loading ||
		createBookingLoading ||
		createMarchandiseLoading ||
		createClientLoading ||
		allContainerTypeLoading ||
		portsLoading
	)
		return <Spinner />

	return (
		<>
			<Box className="mainZone" component="form" noValidate autoComplete="off">
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Booking</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							variant="filled"
							size="small"
							margin="dense"
							label="Contract Number"
							onChange={(e) => setContractNumber(e.target.value)}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						{' '}
						<Button
							onClick={handleFetchContract}
							disabled={!contractNumber.trim().length}
						>
							Apply
						</Button>
						{contractError && (
							<span>{contractError.graphQLErrors[0].message}</span>
						)}
					</FormGroup>
				</fieldset>
				<fieldset>
					<legend>Client</legend>
					{contract && contract.client ? (
						<>
							<span>{contract.client.name}</span>{' '}
							<span>{contract.client.company}</span>
						</>
					) : (
						<>
							{' '}
							<FormGroup className="formGrp" row>
								{allClients && (
									<Autocomplete
										margin="dense"
										sx={{ width: 700 }}
										disabled={clientInput}
										options={allClients}
										getOptionLabel={(option) => option.company}
										filterOptions={filterOptions}
										renderOption={(props, option) => (
											<Box
												component="li"
												{...props}
												className="clientElemContainer"
											>
												<span className="contactSelectItemsCompany">
													<CompanyIcon />
													<p>{option?.company}</p>
												</span>
												<span className="contactSelectItemsName">
													<EmployeeIcon />
													<p>{option?.name}</p>
												</span>
											</Box>
										)}
										getOptionSelected={(option, value) =>
											option.company === value.company
										}
										onChange={(event, value) =>
											setValues({ ...values, clientId: value?.id })
										}
										renderInput={(params) => (
											<TextField
												required
												{...params}
												variant="filled"
												size="small"
												margin="dense"
												label="Client"
											/>
										)}
									/>
								)}
								<FormControlLabel
									control={
										<Switch name="checkedA" onChange={handleClientChange} />
									}
									label="New client ?"
									size="small"
									margin="none"
								/>
							</FormGroup>
							{clientInput && (
								<>
									<FormGroup className="formGrp" row>
										<TextField
											label="Name"
											variant="filled"
											size="small"
											margin="dense"
											disabled={!clientInput}
											onChange={({ target: { value } }) => {
												setClientValues({ ...clientValues, name: value })
											}}
										/>
										<TextField
											label="Company"
											variant="filled"
											size="small"
											margin="dense"
											disabled={!clientInput}
											onChange={({ target: { value } }) => {
												setClientValues({ ...clientValues, company: value })
											}}
										/>

										<TextField
											label="E-mail"
											type="email"
											variant="filled"
											size="small"
											margin="dense"
											disabled={!clientInput}
											onChange={({ target: { value } }) => {
												setClientValues({ ...clientValues, email: value })
											}}
										/>
									</FormGroup>
									<FormGroup className="formGrp" row>
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="dense"
										>
											<InputLabel>Language</InputLabel>
											<Select
												labelId="Language"
												name="language"
												defaultValue={clientValues.language}
												onChange={({ target: { value } }) => {
													setClientValues({ ...clientValues, language: value })
												}}
											>
												<MenuItem key="en" value="en">
													EN
												</MenuItem>
												<MenuItem key="fr" value="fr">
													FR
												</MenuItem>
											</Select>
										</FormControl>
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="dense"
										>
											<InputLabel>Currency</InputLabel>
											<Select
												labelId="Currency"
												name="currency"
												defaultValue={clientValues.currency}
												onChange={({ target: { value } }) => {
													setClientValues({ ...clientValues, currency: value })
												}}
											>
												<MenuItem key="eur" value="eur">
													EUR
												</MenuItem>
												<MenuItem key="usd" value="usd">
													USD
												</MenuItem>
											</Select>
										</FormControl>
									</FormGroup>
									<FormGroup className="formGrp" row>
										<TextField
											fullWidth
											label="Address"
											variant="filled"
											size="small"
											margin="dense"
											disabled={!clientInput}
											onChange={({ target: { value } }) => {
												setClientValues({ ...clientValues, address: value })
											}}
										/>
									</FormGroup>
								</>
							)}
						</>
					)}
				</fieldset>
				<fieldset>
					<FormLabel fullWidth>
						{totalTeu ? (
							<span style={{ display: 'flex', alignItems: 'center' }}>
								Total TEU selected for this Booking: {totalTeu}
							</span>
						) : (
							''
						)}
					</FormLabel>
					<legend>Departure and arrival coordinates</legend>
					<FormGroup className="formGrp" row>
						{allPorts && (
							<Autocomplete
								options={
									contract
										? allPorts.filter(
												({ id }) => id === contract?.originPort.id
										  )
										: allPorts
								}
								filterOptions={filterOptions}
								onChange={(event, value) =>
									setVoyageValues({ ...voyageValues, loadingPortId: value?.id })
								}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Location of departure"
										variant="filled"
										size="small"
										required
										margin="dense"
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
						)}
						<DirectionsIcon
							style={{
								alignSelf: 'center',
								marginLeft: '0px',
								marginRight: '0px',
								paddingLeft: '0px',
							}}
						/>
						{allPorts && (
							<Autocomplete
								onChange={(event, value) =>
									setVoyageValues({
										...voyageValues,
										unLoadingPortId: value?.id,
									})
								}
								filterOptions={filterOptions}
								options={
									contract
										? allPorts.filter(
												({ id }) => id === contract?.destinationPort.id
										  )
										: allPorts
								}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="location of arrival"
										variant="filled"
										size="small"
										margin="dense"
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
						)}
						<AccessTimeFilledIcon
							style={{
								alignSelf: 'center',
								marginLeft: '0px',
								marginRight: '0px',
								paddingLeft: '0px',
							}}
						/>
						<TextField
							required
							label="Date"
							variant="filled"
							type="date"
							onChange={({ target: { value } }) => {
								setVoyageValues({ ...voyageValues, date: value })
							}}
							InputProps={{
								inputProps: { min: new Date().toISOString().slice(0, 10) },
							}}
							size="small"
							margin="dense"
							InputLabelProps={{ shrink: true }}
						/>

						<Button
							disabled={
								typeof voyageValues.loadingPortId === 'undefined' ||
								voyageValues.loadingPortId === '' ||
								typeof voyageValues.unLoadingPortId === 'undefined' ||
								voyageValues.unLoadingPortId === '' ||
								typeof voyageValues.date === 'undefined' ||
								voyageValues.date === ''
									? true
									: false
							}
							variant="contained"
							color="primary"
							onClick={handleFetchVoyages}
						>
							<PageviewIcon /> &nbsp;Search for a voyage
						</Button>
					</FormGroup>
					{voyageLoading && <Spinner />}

					{voyagesByDestinationAndDate && (
						<FormControl className="formGrp" row fullWidth>
							<Box id="voyageListingContainer" fullWidth>
								<FormLabel fullWidth>
									{voyagesByDestinationAndDate.length !== 0 ? (
										'Select a voyage'
									) : (
										<span style={{ display: 'flex', alignItems: 'center' }}>
											{' '}
											<ReportProblemIcon /> &nbsp;&nbsp; No result found. Please
											adjust search filters.
										</span>
									)}
								</FormLabel>
								<RadioGroup
									fullWidth
									id="voyageListing"
									onChange={({ target: { value } }) =>
										setValues({ ...values, voyageId: value })
									}
								>
									{voyagesByDestinationAndDate.map((voyage, i) => {
										const {
											number,
											teu,
											arrivalDate,
											departureDate,
											transit,
											ship,
											transitDays,
											id,
										} = voyage
										return (
											<FormControlLabel
												disabled={
													Number(ship.teu) - Number(teu) === 0 ||
													totalTeu > Number(ship.teu) - Number(teu)
												}
												key={id}
												className={
													Number(i + 1) % 2 === 1
														? 'voyageElemModulo'
														: 'voyageElem'
												}
												onClick={() => setSelectedVoyage(voyage)}
												value={id}
												color="primary"
												control={<Radio />}
												label={
													<span
														style={{
															display: 'flex',
															alignContent: 'center',
														}}
													>
														<div
															style={{
																display: 'flex',
																alignContent: 'center',
																marginRight: '50px',
																marginLeft: '20px',
															}}
														>
															<span style={{ marginRight: '30px' }}>
																<span style={{ fontWeight: 'bold' }}>No: </span>
																{number}
															</span>
															<EventIcon /> {''}&nbsp;
															{moment(new Date(Number(departureDate))).format(
																'DD-MM-YYYY'
															)}{' '}
															<ArrowForwardIosIcon />{' '}
															{moment(new Date(Number(arrivalDate))).format(
																'DD-MM-YYYY'
															)}
														</div>
														<div
															style={{
																display: 'flex',
																alignContent: 'center',
																marginRight: '50px',
															}}
														>
															<TimelapseIcon /> {''} &nbsp;{transitDays} days
														</div>
														<div
															style={{
																display: 'flex',
																alignContent: 'center',
																marginRight: '50px',
															}}
														>
															<DirectionsBoatFilledIcon /> {''} &nbsp;
															{ship.name}
														</div>
														<div
															style={{
																display: 'flex',
																alignContent: 'center',
															}}
														>
															<span style={{ marginLeft: '30px' }}>
																<span style={{ fontWeight: 'bold' }}>
																	Available Capacity:{' '}
																</span>
																{Number(ship.teu) - Number(teu)}
															</span>
														</div>
													</span>
												}
											/>
										)
									})}
								</RadioGroup>
							</Box>
						</FormControl>
					)}
				</fieldset>
				<fieldset>
					<legend>Commodity</legend>
					<FormGroup className="formGrp" row>
						<Autocomplete
							disabled={marchandiseInput}
							disablePortal
							getOptionLabel={(option) =>
								`${option?.name?.toString()} - ${option?.price}`
							}
							onChange={(event, value) => {
								setValues({ ...values, marchandiseId: value?.id })
								setCommodityPrice(value.price)
							}}
							options={
								allMarchandises
									? allMarchandises.filter(({ active }) => active)
									: []
							}
							sx={{ width: 700 }}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Commodity"
									variant="filled"
									size="small"
									margin="dense"
									required
								/>
							)}
						/>
						<FormControlLabel
							control={
								<Switch
									name="checkedA"
									defaultChecked={false}
									onChange={handleMarchendiseChange}
								/>
							}
							label="New commodity type ?"
						/>
					</FormGroup>

					{marchandiseInput && (
						<FormGroup className="formGrp" row>
							<TextField
								required
								style={{ width: '300px' }}
								disabled={!marchandiseInput}
								label="Nom"
								variant="filled"
								size="small"
								margin="dense"
								onChange={({ target: { value } }) => {
									setMarchandiseName(value)
								}}
							/>
							<TextField
								style={{ width: '300px' }}
								disabled={!marchandiseInput}
								required
								label="Price"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{ inputProps: { min: 0 } }}
								onWheel={(e) => e.target.blur()}
								onChange={({ target: { value } }) => {
									setMarchandisePrice(value)
								}}
							/>
						</FormGroup>
					)}
				</fieldset>
				{
					<fieldset>
						<legend>Container</legend>
						{pickupDate && <span>Pickup Date is: {pickupDate}</span>}
						<Box component={Paper} elevation={2}>
							<FormGroup className="formGrp" row>
								{depotsByPort && (
									<Autocomplete
										style={{ fontSize: '12px' }}
										filterOptions={filterOptions}
										getOptionLabel={(option) =>
											option?.name?.toUpperCase() +
											' - ' +
											option?.country?.toUpperCase()
										}
										onChange={(event, value) => setSelectedDepot(value)}
										options={depotsByPort}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Depot"
												variant="filled"
												size="small"
												margin="dense"
												required
											/>
										)}
									/>
								)}
								{selectedDepot && contract ? (
									<Autocomplete
										onChange={(event, value) => setSelectedContainerType(value)}
										getOptionLabel={(option) => option?.containerType?.type}
										renderOption={(props, option) => (
											<Box component="li" {...props}>
												<Badge
													badgeContent={option?.available}
													color="primary"
													sx={{
														'& .MuiBadge-badge': {
															fontSize: 9,
															marginRight: -2,
															marginTop: 1,
														},
													}}
												>
													{option?.containerType?.type?.toString() + '    '}
												</Badge>
											</Box>
										)}
										getOptionDisabled={(option) => {
											//Find if depot was added
											const findDepoIfItWasSelected =
												selectedContainersArray.find(
													({ depot }) => depot.id === selectedDepot.id
												)
											//If depot was added
											if (
												findDepoIfItWasSelected &&
												findDepoIfItWasSelected.container.containerType.id ===
													option.containerType.id &&
												option.available
											) {
												return (
													option?.available -
														findDepoIfItWasSelected.quantity ===
													0
												)
											} else {
												return option?.available === 0
											}
										}}
										options={selectedDepot.containersTypeAvailability.filter(
											({ containerType }) =>
												contract.containers.find(
													({ containerType: contractContainerType }) =>
														contractContainerType.id === containerType.id
												)
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Container type"
												name="containerType"
												variant="filled"
												size="small"
												margin="dense"
												required
											/>
										)}
									/>
								) : (
									selectedDepot &&
									!contract && (
										<Autocomplete
											onChange={(event, value) =>
												setSelectedContainerType(value)
											}
											getOptionLabel={(option) => option?.containerType?.type}
											renderOption={(props, option) => (
												<Box component="li" {...props}>
													<Badge
														badgeContent={option?.available}
														color="primary"
														sx={{
															'& .MuiBadge-badge': {
																fontSize: 9,
																marginRight: -2,
																marginTop: 1,
															},
														}}
													>
														{option?.containerType?.type?.toString() + '    '}
													</Badge>
												</Box>
											)}
											getOptionDisabled={(option) => {
												//Find if depot was added
												const findDepoIfItWasSelected =
													selectedContainersArray.find(
														({ depot }) => depot.id === selectedDepot.id
													)
												//If depot was added
												if (
													findDepoIfItWasSelected &&
													findDepoIfItWasSelected.container.containerType.id ===
														option.containerType.id &&
													option.available
												) {
													return (
														option?.available -
															findDepoIfItWasSelected.quantity ===
														0
													)
												} else {
													return option?.available === 0
												}
											}}
											options={selectedDepot.containersTypeAvailability}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Container type"
													name="containerType"
													variant="filled"
													size="small"
													margin="dense"
													required
												/>
											)}
										/>
									)
								)}
								{selectedContainerType && (
									<>
										<FormControl
											required
											size="small"
											margin="dense"
											style={{ width: '150px' }}
										>
											<InputLabel>Quantity</InputLabel>
											<Select
												value={quantity}
												label="Quantity"
												variant="filled"
												onChange={({ target: { value } }) => {
													setQuantity(value)
												}}
											>
												{Array.from(
													{
														length: contract
															? selectedContainerType?.available <
															  containerTypeInContractQuantity
																? selectedContainerType?.available
																: containerTypeInContractQuantity
															: selectedContainerType?.available -
															  (selectedContainersArray.find(
																	({ depot }) => depot.id === selectedDepot.id
															  )?.quantity || 0),
													},
													(_, i) => i + 1
												).map((x, i) => (
													<MenuItem key={i} value={x}>
														{x}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<TextField
											label="Price"
											name="price"
											variant="filled"
											size="small"
											margin="dense"
											style={{ width: '150px' }}
											disabled
											value={selectedContainerPrice}
										/>
										<TextField
											label="Estimated weight"
											name="weight"
											type="number"
											variant="filled"
											size="small"
											margin="dense"
											style={{ width: '150px' }}
											onChange={({ target }) =>
												setEstimateWeight(Number(target.value))
											}
										/>
										<Button
											variant="contained"
											color="primary"
											size="small"
											margin="dense"
											disabled={quantity < 1}
											onClick={handleAddingContainerRow}
										>
											{' '}
											<AddCircleIcon />
											&nbsp;Add{' '}
										</Button>
									</>
								)}
							</FormGroup>
						</Box>
						<TableContainer component={Paper} elevation={2}>
							<Table sx={{ minWidth: 650 }}>
								<TableHead>
									<TableRow>
										<TableCell>Depot</TableCell>
										<TableCell>Container Type</TableCell>
										<TableCell>Quantity</TableCell>
										<TableCell>Price</TableCell>
										<TableCell>Weight</TableCell>
										<TableCell>Remove</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{console.log(
										'selectedContainersArray',
										selectedContainersArray
									)}
									{selectedContainersArray.length
										? selectedContainersArray.map(
												(
													{
														depot,
														container,
														quantity,
														pricePerContainer,
														estimateWeight,
													},
													i
												) => (
													<TableRow key={i}>
														<TableCell>{depot.name}</TableCell>
														<TableCell>
															{container.containerType.type}
														</TableCell>
														<TableCell>{quantity}</TableCell>
														<TableCell>{pricePerContainer}</TableCell>
														<TableCell>{estimateWeight || ''}</TableCell>
														<TableCell>
															<Button
																onClick={() => handleRemoveContainerRow(i)}
															>
																<DeleteIcon></DeleteIcon>
															</Button>
														</TableCell>
													</TableRow>
												)
										  )
										: null}
								</TableBody>
							</Table>
						</TableContainer>
					</fieldset>
				}
				<fieldset className="containerFieldset">
					<legend>Fees</legend>
					<FormGroup className="formGrp" row>
						<TextField
							type="number"
							label="A"
							inputProps={{ min: 0 }}
							variant="filled"
							size="small"
							width="600px"
							margin="dense"
							// value={values.transport}
							onChange={({ target: { value } }) => {
								setFees({
									...fees,
									transport: Number(value),
								})
							}}
						/>
						<TextField
							type="number"
							label="B"
							inputProps={{ min: 0 }}
							variant="filled"
							size="small"
							width="600px"
							margin="dense"
							// value={values.waiver}
							onChange={({ target: { value } }) => {
								setFees({
									...fees,
									waiver: Number(value),
								})
							}}
						/>
						<TextField
							type="number"
							label="C"
							inputProps={{ min: 0 }}
							variant="filled"
							size="small"
							width="600px"
							margin="dense"
							// value={values.thco}
							onChange={({ target: { value } }) => {
								setFees({
									...fees,
									thco: Number(value),
								})
							}}
						/>
						<TextField
							type="number"
							label="D"
							inputProps={{ min: 0 }}
							variant="filled"
							size="small"
							width="600px"
							margin="dense"
							// value={values.tsco}
							onChange={({ target: { value } }) => {
								setFees({
									...fees,
									tsco: Number(value),
								})
							}}
						/>
					</FormGroup>
				</fieldset>
				<fieldset>
					<legend>Note</legend>
					<TextField
						label="Note"
						inputProps={{ min: 0 }}
						variant="filled"
						multiline
						margin="dense"
						onChange={({ target: { value } }) => {
							setValues({ ...values, note: value })
						}}
					/>
				</fieldset>
				{/* Total */}
				<fieldset>
					<legend>Total</legend>
					<FormGroup className="formGrp" row>
						{values.voyageId && (
							<TextField
								label="Total"
								variant="filled"
								size="small"
								margin="small"
								name="total"
								disabled
								value={`${totalContainersPrice} ${
									voyagesByDestinationAndDate.find(
										({ id }) => id === values.voyageId
									)?.currency?.currency
								}`}
							/>
						)}
					</FormGroup>
				</fieldset>

				<Button
					className="submitBtn"
					variant="contained"
					color="primary"
					onClick={handleCreateBooking}
					disabled={disabledSubmitButton}
				>
					<SaveIcon /> &nbsp; Submit{' '}
				</Button>
				{/* </fieldset> */}
			</Box>
		</>
	)
}

export default CreateBooking
