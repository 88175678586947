import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import SaveIcon from '@material-ui/icons/Save'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import { Button } from '@material-ui/core'

import Spinner from '../../UI/Spinner'
import UploadZone from '../OperateBooking/UploadZone'

import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Paper from '@mui/material/Paper'
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import List from '@mui/material/List'
import CompanyIcon from '@mui/icons-material/Apartment'
import EmployeeIcon from '@mui/icons-material/Badge'
import CircularProgress from '@mui/material/CircularProgress'

import '../../../asset/style.css'

const CREATE_CONTRACT = loader('./graphql/createContract.graphql')
const CREATE_CLIENT = loader('./graphql/createClient.graphql')
const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')

const CreateContact = () => {
	const currentUser = useCurrentUser()
	const [containerTypeCount, setContainerTypeCount] = useState([])
	const [totalTeu, setTotalTeu] = useState(0)
	const [totalContainerTypeCount, setTotalContainerTypeCount] = useState(0)
	const [clientInput, setClientInput] = useState(false)
	const [originPortId, setOriginPortId] = useState()
	const [destinationPortId, setDestinationPortId] = useState()
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [clientId, setClientId] = useState()
	const [clientValues, setClientValues] = useState({
		name: '',
		company: '',
		email: '',
		address: '',
		language: 'en',
		currency: 'eur',
	})
	const [open, setOpen] = useState(false)
	const [photoUrl, setPhotoUrl] = useState('')
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const [
		CreateContract,
		{ data: { createContract = null } = {}, error, loading },
	] = useMutation(CREATE_CONTRACT)

	const [
		createClient,
		{
			data: clientData,
			error: errorCreateClient,
			loading: createClientLoading,
		},
	] = useMutation(CREATE_CLIENT)

	const { data: { allClients = null } = {}, loading: clientsLoading } =
		useQuery(ALL_CLIENTS)

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const {
		data: { allContainerTypes = null } = {},
		loading: allContainerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const FireNav = styled(List)({
		'& .MuiListItemButton-root': {
			paddingLeft: 24,
			paddingRight: 24,
		},
		'& .MuiListItemIcon-root': {
			minWidth: 0,
			marginRight: 16,
		},
		'& .MuiSvgIcon-root': {
			fontSize: 20,
		},
	})

	const handleClientChange = ({ target }) => {
		if (target.checked) {
			setClientInput(true)
		} else {
			setClientInput(false)
		}
	}

	const handelContainersQuantityPlus = (e) => {
		const { teu } = allContainerTypes.find(
			({ id }) => id === e.currentTarget.dataset.type
		)
		let elem = {
			typeId: e.currentTarget.dataset.type,
			quantity: Number(1),
			teu,
		}
		const typeExist = containerTypeCount.find((element) => {
			if (element.typeId === e.currentTarget.dataset.type) {
				return true
			}
		})
		if (typeExist) {
			const newElm = containerTypeCount.map((p) =>
				p.typeId === e.currentTarget.dataset.type
					? { ...p, quantity: p.quantity + 1 }
					: p
			)
			setContainerTypeCount([...newElm])
		} else {
			setContainerTypeCount([...containerTypeCount, elem])
		}
	}

	const handelContainersQuantityMinus = (e) => {
		const { teu } = allContainerTypes.find(
			({ id }) => id === e.currentTarget.dataset.type
		)
		let elem = {
			typeId: e.currentTarget.dataset.type,
			quantity: Number(0),
			teu,
		}
		const typeExist = containerTypeCount.find((element) => {
			if (element.typeId === e.currentTarget.dataset.type) {
				return true
			}
		})
		if (typeExist) {
			const newElm = containerTypeCount.map((p) =>
				p.typeId === e.currentTarget.dataset.type && p.quantity > 0
					? { ...p, quantity: p.quantity - 1 }
					: p
			)
			setContainerTypeCount([...newElm])
		} else {
			setContainerTypeCount([...containerTypeCount, elem])
		}
	}

	const handleCreateContract = (e) => {
		e.preventDefault()
		if (clientInput) {
			return handleCreateClient()
		} else {
			return CreateContract({
				variables: {
					input: {
						startDate,
						endDate,
						originPortId,
						destinationPortId,
						clientId,
						containers: containerTypeCount,
						uploadedContract: photoUrl,
					},
				},
			})
		}
	}

	const handleCreateClient = () => {
		createClient({
			variables: {
				input: clientValues,
			},
		})
	}

	useEffect(() => {
		if (containerTypeCount.length) {
			const calculateTotalTeu = containerTypeCount
				.map(({ teu, quantity }) => Number(teu) * Number(quantity))
				.reduce((a, b) => a + b)
			setTotalTeu(calculateTotalTeu)
		}
	}, [containerTypeCount])

	useEffect(() => {
		if (clientData) {
			CreateContract({
				variables: {
					input: {
						startDate,
						endDate,
						originPortId,
						destinationPortId,
						client: clientData?.createClient?.client?.id,
						containers: containerTypeCount,
					},
				},
			})
		}
	}, [clientData])

	if (createClientLoading) return <Spinner />

	if (createContract && !error && !errorCreateClient) {
		localStorage.setItem(
			'createContractState',
			'✔️ Contract created successfully ! '
		)
		return <Redirect to="/contract-list" />
	} else if (error || errorCreateClient) {
		var errorTxt = ''
		var separetor = '\n***************************************\n'
		if (errorCreateClient) {
			errorTxt += errorCreateClient?.message + separetor
		}
		if (error) {
			errorTxt += error?.message + separetor
		}
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleCreateContract}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create contract</h1>
				</div>
				<fieldset>
					<legend>Client</legend>
					<FormGroup className="formGrp" row>
						{allClients && (
							<Autocomplete
								margin="dense"
								sx={{ width: 700 }}
								disabled={clientInput}
								options={allClients}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										component="li"
										{...props}
										className="clientElemContainer"
									>
										<span className="contactSelectItemsCompany">
											<CompanyIcon />
											<p>{option?.company}</p>
										</span>
										<span className="contactSelectItemsName">
											<EmployeeIcon />
											<p>{option?.name}</p>
										</span>
									</Box>
								)}
								getOptionSelected={(option, value) =>
									option.company === value.company
								}
								onChange={(event, value) => setClientId(value?.id)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="filled"
										size="small"
										margin="dense"
										label="Client"
									/>
								)}
							/>
						)}
						<FormControlLabel
							control={<Switch name="checkedA" onChange={handleClientChange} />}
							label="New client ?"
							size="small"
							margin="none"
						/>
					</FormGroup>
					{clientInput && (
						<>
							<FormGroup className="formGrp" row>
								<TextField
									label="Name"
									variant="filled"
									size="small"
									margin="dense"
									disabled={!clientInput}
									onChange={({ target: { value } }) => {
										setClientValues({ ...clientValues, name: value })
									}}
								/>
								<TextField
									label="Company"
									variant="filled"
									size="small"
									margin="dense"
									disabled={!clientInput}
									onChange={({ target: { value } }) => {
										setClientValues({ ...clientValues, company: value })
									}}
								/>

								<TextField
									label="E-mail"
									type="email"
									variant="filled"
									size="small"
									margin="dense"
									disabled={!clientInput}
									onChange={({ target: { value } }) => {
										setClientValues({ ...clientValues, email: value })
									}}
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<FormControl
									className="formSelect"
									variant="filled"
									size="small"
									margin="dense"
								>
									<InputLabel>Language</InputLabel>
									<Select
										labelId="Language"
										name="language"
										defaultValue={clientValues.language}
										onChange={({ target: { value } }) => {
											setClientValues({ ...clientValues, language: value })
										}}
									>
										<MenuItem key="en" value="en">
											EN
										</MenuItem>
										<MenuItem key="fr" value="fr">
											FR
										</MenuItem>
									</Select>
								</FormControl>
								<FormControl
									className="formSelect"
									variant="filled"
									size="small"
									margin="dense"
								>
									<InputLabel>Currency</InputLabel>
									<Select
										labelId="Currency"
										name="currency"
										defaultValue={clientValues.currency}
										onChange={({ target: { value } }) => {
											setClientValues({ ...clientValues, currency: value })
										}}
									>
										<MenuItem key="eur" value="eur">
											EUR
										</MenuItem>
										<MenuItem key="usd" value="usd">
											USD
										</MenuItem>
									</Select>
								</FormControl>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									fullWidth
									label="Address"
									variant="filled"
									size="small"
									margin="dense"
									disabled={!clientInput}
									onChange={({ target: { value } }) => {
										setClientValues({ ...clientValues, address: value })
									}}
								/>
							</FormGroup>
						</>
					)}
				</fieldset>
				<fieldset>
					<legend>Container</legend>
					<ThemeProvider
						theme={createTheme({
							components: {
								MuiListItemButton: {
									defaultProps: {
										disableTouchRipple: true,
									},
								},
							},
							palette: {
								mode: 'dark',
								primary: { main: 'rgb(12, 24, 54)' },
								background: { paper: 'rgb(5, 30, 52)' },
							},
						})}
					>
						<Paper
							id="cargoType"
							elevation={0}
							sx={{ maxWidth: 700, marginTop: '10px', marginBottom: '10px' }}
						>
							<FireNav component="nav" disablePadding>
								<Box
									sx={{
										bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
										pb: open ? 2 : 0,
									}}
								>
									<ListItemButton
										alignItems="flex-start"
										onClick={() => setOpen(!open)}
										sx={{
											px: 3,
											pt: 2.5,
											pb: open ? 0 : 2.5,
											'&:hover, &:focus': {
												'& svg': { opacity: open ? 1 : 0 },
											},
										}}
									>
										<ListItemText
											primary="Container type"
											primaryTypographyProps={{
												fontSize: 15,
												fontWeight: 'medium',
												lineHeight: '20px',
												mb: '2px',
											}}
											secondary={
												totalContainerTypeCount === 0
													? 'Select the cargo'
													: totalContainerTypeCount === 1
													? '1 Container selected'
													: totalContainerTypeCount + ' Containers selected'
											}
											secondaryTypographyProps={{
												noWrap: true,
												fontSize: 12,
												lineHeight: '16px',
												color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
											}}
											sx={{ my: 0 }}
										/>

										<KeyboardArrowDown
											sx={{
												mr: -1,
												opacity: 1,
												transform: open ? 'rotate(-180deg)' : 'rotate(0)',
												transition: '0.2s',
											}}
										/>
									</ListItemButton>
									{open &&
										allContainerTypes.map((item) => (
											<ListItemButton
												key={item?.type}
												sx={{
													py: 0,
													minHeight: 32,
													color: 'rgba(255,255,255,.8)',
												}}
											>
												<ListItemText
													primary={item?.type}
													primaryTypographyProps={{
														fontSize: 14,
														fontWeight: 'medium',
													}}
												/>

												<TextField
													variant="filled"
													type="number"
													className="containersQuantity"
													style={{ color: '#000 !important' }}
													disabled
													value={
														containerTypeCount.length === 0
															? 0
															: containerTypeCount.find(
																	(elm) => elm.typeId === item.id
															  ) === undefined
															? 0
															: containerTypeCount.find(
																	(elm) => elm.typeId === item.id
															  ).quantity
													}
													name={item?.id}
													InputProps={{
														endAdornment: (
															<AddCircleIcon
																className="containertypeModuleAddBtn"
																data-type={item?.id}
																onClick={handelContainersQuantityPlus}
															/>
														),
														startAdornment: (
															<RemoveCircleIcon
																className="containertypeModuleRemoveBtn"
																data-type={item?.id}
																onClick={handelContainersQuantityMinus}
															/>
														),
													}}
													inputStyle={{ textAlign: 'center', color: '#000' }}
													sx={{
														input: {
															textAlign: 'center',
															width: '50px !important',
															color: '#000',
														},
													}}
													size="small"
													inputProps={{
														min: 0,
														style: {
															backgroundColor: '#fff',
															textAlign: 'center !important',
															width: '50 !important',
															fontSize: '30',
														},
													}}
												/>
											</ListItemButton>
										))}
								</Box>
							</FireNav>
						</Paper>
					</ThemeProvider>
				</fieldset>
				<fieldset>
					<legend>Voyage</legend>
					<FormGroup className="formGrp" row>
						{allPorts && (
							<Autocomplete
								sx={{ width: 500 }}
								onChange={(event, value) => setOriginPortId(value?.id)}
								options={allPorts}
								filterOptions={filterOptions}
								loading={portsLoading}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Loading Port"
										variant="filled"
										size="small"
										name="loadingPort"
										required
										margin="dense"
										inputProps={{
											...params.inputProps,
											endAdornment: (
												<React.Fragment>
													{portsLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						)}
						{allPorts && (
							<Autocomplete
								sx={{ width: 500 }}
								onChange={(event, value) => setDestinationPortId(value?.id)}
								options={allPorts}
								filterOptions={filterOptions}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Unloading Port"
										name="unloadingPort"
										variant="filled"
										size="small"
										margin="dense"
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
						)}
					</FormGroup>
				</fieldset>
				<fieldset>
					<legend>Validity</legend>
					<FormGroup className="formGrp" row>
						<TextField
							style={{ width: '500px' }}
							label="start date"
							name="startDate"
							onChange={({ target: { value } }) => {
								setStartDate(value)
							}}
							type="date"
							InputLabelProps={{ shrink: true }}
							variant="filled"
							size="small"
							margin="dense"
						/>
						<TextField
							style={{ width: '500px' }}
							disabled={startDate && startDate ? false : true}
							helperText={startDate ? '' : 'Please select a start date first'}
							InputProps={{
								inputProps: {
									min:
										startDate && new Date(startDate).toISOString().slice(0, 10),
								},
							}}
							label="End date"
							name="endDate"
							onChange={({ target: { value } }) => {
								setEndDate(value)
							}}
							type="date"
							InputLabelProps={{ shrink: true }}
							variant="filled"
							size="small"
							margin="dense"
						/>
					</FormGroup>
				</fieldset>
				<fieldSet>
					<section className="uploadImgsContainer">
						<UploadZone photo={(url) => setPhotoUrl(url)} />
					</section>
				</fieldSet>
				<Button
					className="submitBtn"
					type="submit"
					variant="contained"
					color="primary"
				>
					{' '}
					<SaveIcon />
					&nbsp;Submit{' '}
				</Button>
			</Box>
		</>
	)
}

export default CreateContact
