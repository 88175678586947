import React, { useEffect, useRef, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'

import ReactToPrint from 'react-to-print'
import PrintIcon from '@mui/icons-material/Print'
import { Button } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

import InvoiceTemplate from './printInvoice/'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_INVOICES = loader('./graphql/allInvoices.graphql')

export default function InvoiceList() {
	const componentRef = useRef([])
	const [openPrintDialog, setOpenPrintDialog] = useState(false)
	const [invoiceToPrintId, setInvoiceToPrint] = useState(null)

	const {
		data: { allInvoices = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_INVOICES)

	const handlePrintDialogOpen = (id) => {
		setOpenPrintDialog(true)
		setInvoiceToPrint(id)
	}

	const handlePrintDialogClose = () => {
		setOpenPrintDialog(false)
	}

	const columns = [
		{ field: 'number', headerName: 'Number', flex: 1 },
		{
			field: 'booking',
			headerName: 'Booking Number',
			flex: 1,
			valueGetter: ({ row }) => row?.booking?.number,
		},
		{
			field: 'amount',
			headerName: 'Amount',
			flex: 1,
			valueGetter: ({ row }) => row?.amount,
		},
		{
			field: 'currency',
			headerName: 'Currency',
			flex: 1,
			valueGetter: ({ value }) => value?.currency,
		},

		{
			field: 'print',
			headerName: 'Print',
			minWidth: 130,
			sortable: false,
			filtrable: false,
			disableExport: true,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Button
							className="printBtn"
							variant="outlined"
							startIcon={<PrintIcon />}
							onClick={() => handlePrintDialogOpen(row.id)}
						>
							Print
						</Button>
					</>
				)
			},
		},
		{
			field: 'actions',
			headerName: 'Edit',
			sortable: false,
			filtrable: false,
			disableExport: true,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-invoice/${params.row.number}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
	]

	if (localStorage.getItem('invoiceState') !== '') {
		toast.success(localStorage.getItem('invoiceState'))
	}

	if (localStorage.getItem('createInvoiceState') !== '') {
		toast.success(localStorage.getItem('createInvoiceState'))
	}

	useEffect(() => {
		// Clear the local storage
		const timer = setTimeout(() => {
			localStorage.setItem('invoiceState', '')
			localStorage.setItem('createInvoiceState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allInvoices])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allInvoices && (
				<>
					<div style={{ display: 'none' }}>
						<InvoiceTemplate id={invoiceToPrintId} ref={componentRef} />
					</div>
					<DataGrid
						rows={allInvoices}
						autoHeight={true}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
							NoResultsOverlay: () => {
								return (
									<Stack
										style={{ marginTop: '600px !important' }}
										alignItems="center"
										justifyContent="center"
									>
										<FilterListOffIcon />
										<p>Filter returns no result</p>
									</Stack>
								)
							},
							NoRowsOverlay: () => {
								return (
									<Stack
										style={{ marginTop: '600px !important' }}
										alignItems="center"
										justifyContent="center"
									>
										<FilterListOffIcon />
										<p>No Rows</p>
									</Stack>
								)
							},
						}}
					/>
				</>
			)}

			<Dialog open={openPrintDialog} onClose={handlePrintDialogClose}>
				<DialogTitle>{'Printing'}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Your document is ready <br />
						Please Confirm
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div style={{ display: 'none' }}>
						<InvoiceTemplate id={invoiceToPrintId} ref={componentRef} />
					</div>
					<Button onClick={handlePrintDialogClose} color="secondary">
						Cancel
					</Button>
					<ReactToPrint
						trigger={() => (
							<Button>
								<PrintIcon color="primary" />
							</Button>
						)}
						content={() => componentRef.current}
					/>
				</DialogActions>
			</Dialog>
		</>
	)
}
