import React, { Component } from 'react'
import BillOfLandingTemplateEN from './BillOfLandingTemplateEN'

class Print extends Component {
	render() {
		return <BillOfLandingTemplateEN id={this.props.id} />
	}
}

export default Print
