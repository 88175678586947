import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import SaveIcon from '@material-ui/icons/Save'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'
import Moment from 'react-moment'

const VOYAGE = loader('./graphql/voyage.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')
const ALL_VESSELS = loader('./graphql/allVessels.graphql')
const UPDATE_VOYAGE = loader('./graphql/updateVoyage.graphql')
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')
const DEPOTS_BY_PORT = loader('./graphql/depotsByPort.graphql')

const EditVoyage = () => {
	const params = useParams()
	const { id } = params
	const [shipId, setShipId] = useState()
	const [loadingPort, setLoadingPort] = useState()
	const [unLoadingPort, setUnloadingPort] = useState()
	const [departureDate, setDepartureDate] = useState()
	const [arrivalDate, setArrivalDate] = useState()
	const [tabvalue, setTabValue] = useState(0)
	const [depotId, setDepotId] = useState()
	const [containerTypesPrice, setContainerTypesPrice] = useState([])
	const theme = useTheme()

	const {
		data: { voyage = null } = {},
		loading: voyageLoading,
		refetch,
	} = useQuery(VOYAGE, {
		variables: { id: params.id },
	})

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const { data: { allVessels = null } = {}, loading: vesselsLoading } =
		useQuery(ALL_VESSELS)

	const [updateVoyage, { data, error, loading }] = useMutation(UPDATE_VOYAGE)

	const {
		data: { allContainerTypes = null } = {},
		loading: allContainerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const [
		getDepotsByPort,
		{ data: { depotsByPort = null } = {}, loading: depotsLoading },
	] = useLazyQuery(DEPOTS_BY_PORT)

	function TabPanel(props) {
		const { children, value, index, ...other } = props

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<div style={{ overflowX: 'hidden' }}>{children}</div>
					</Box>
				)}
			</div>
		)
	}

	function a11yProps(index) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		}
	}

	const handleTabsChange = (event, newValue) => {
		setTabValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setTabValue(index)
	}

	useEffect(() => {
		refetch()
	}, [voyage])

	useEffect(() => {
		if (data) {
			localStorage.setItem('voyageState', '✔️ Voyage updated successfully ! ')
			window.location.reload()
		}
	}, [data])

	useEffect(() => {
		if (loadingPort) {
			getDepotsByPort({
				variables: {
					portId: loadingPort,
				},
			})
		} else if (voyage) {
			getDepotsByPort({
				variables: {
					portId: voyage.loadingPort.id,
				},
			})
		}
		refetch()
	}, [voyage, loadingPort])

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			number,
			departureDate,
			arrivalDate,
			vgmClosing,
			docClosing,
			portCutoff,
		} = e.target
		return updateVoyage({
			variables: {
				input: {
					id,
					number: number.value,
					departureDate: departureDate.value,
					arrivalDate: arrivalDate.value,
					containerTerminal: depotId,
					vgmClosing: vgmClosing.value,
					docClosing: docClosing.value,
					portCutoff: portCutoff.value,
					shipId: shipId,
					loadingPortId: loadingPort,
					unLoadingPortId: unLoadingPort,
					containerTypesPrice,
				},
			},
		})
	}

	useEffect(() => {
		let typesArray = []
		allContainerTypes &&
			allContainerTypes.map((elm) => {
				typesArray.push({
					typeId: elm.id,
					price: voyage?.containerTypesPrice?.find(
						({ containerType }) => containerType.id === elm.id
					).price,
					contractPrice: voyage?.containerTypesPrice?.find(
						({ containerType }) => containerType.id === elm.id
					).contractPrice,
				})
			})
		setContainerTypesPrice(typesArray)
	}, [allContainerTypes])

	if (voyageLoading || loading || vesselsLoading || portsLoading)
		return <Spinner style={{ marginTop: '400px' }} />

	return (
		<>
			{loading ? (
				<Spinner style={{ marginTop: '400px', color: 'secondary' }} />
			) : error ? (
				<p>{error.message}</p>
			) : (
				<>
					<Box
						className="mainZone"
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div
							style={{
								width: '100%',
								height: '50px',
								backgroundColor: '#0c1836',
								marginBottom: '10px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1 style={{ color: '#fff' }}>Edit Voyage No. {voyage.number}</h1>
						</div>
						<Box
							sx={{ borderBottom: 1, borderColor: 'divider' }}
							style={{
								width: '100%',
							}}
						>
							<AppBar
								position="static"
								variant="fullWidth"
								style={{
									background: '#2F3E63',
									borderTop: '1px solid #fff',
									height: '30px !important',
								}}
							>
								<Tabs
									value={tabvalue}
									onChange={handleTabsChange}
									indicatorColor="primary"
									textColor="inherit"
									variant="fullWidth"
									style={{ height: '30px !important' }}
								>
									<Tab label="Voyage" {...a11yProps(0)} />
									<Tab label="Prices" {...a11yProps(1)} />
									<Tab label="Details" {...a11yProps(2)} />
								</Tabs>
							</AppBar>
							<SwipeableViews
								axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tabvalue}
								onChangeIndex={handleChangeIndex}
							>
								{/* Voyage */}
								<TabPanel value={tabvalue} index={0} variant="fullWidth">
									{voyage && (
										<>
											<fieldset>
												<FormGroup className="formGrp" row>
													<TextField
														label="Voyage No."
														name="number"
														variant="filled"
														size="small"
														margin="dense"
														defaultValue={voyage.number}
													/>

													<Autocomplete
														defaultValue={voyage.ship}
														onChange={(event, value) => setShipId(value?.id)}
														options={allVessels}
														getOptionLabel={(option) => option.name}
														renderOption={(props, option) => (
															<Box
																key={option.id}
																component="li"
																sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
																{...props}
															>
																&nbsp;&nbsp;{option.name}
															</Box>
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Ship name"
																name="shipId"
																variant="filled"
																size="small"
																required
																margin="dense"
															/>
														)}
													/>
												</FormGroup>

												<FormGroup className="formGrp" row>
													{allPorts && (
														<Autocomplete
															defaultValue={
																allPorts.find(({ id }) => id === loadingPort) ||
																voyage.loadingPort
															}
															filterOptions={filterOptions}
															onChange={(event, value) =>
																setLoadingPort(value?.id)
															}
															options={allPorts}
															loading={portsLoading}
															getOptionLabel={(option) => option.name}
															renderOption={(props, option) => (
																<Box
																	key={option.id}
																	component="li"
																	sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
																	{...props}
																>
																	&nbsp;&nbsp;{option.name}
																</Box>
															)}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Loading Port"
																	variant="filled"
																	size="small"
																	name="loadingPortId"
																	required
																	margin="dense"
																/>
															)}
														/>
													)}
													{allPorts && (
														<Autocomplete
															defaultValue={
																allPorts.find(
																	({ id }) => id === unLoadingPort
																) || voyage.unLoadingPort
															}
															filterOptions={filterOptions}
															onChange={(event, value) =>
																setUnloadingPort(value?.id)
															}
															options={allPorts}
															getOptionLabel={(option) => option.name}
															renderOption={(props, option) => (
																<Box
																	key={option.id}
																	component="li"
																	sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
																	{...props}
																>
																	&nbsp;&nbsp;{option.name}
																</Box>
															)}
															renderInput={(params) => (
																<TextField
																	{...params}
																	required
																	label="Unloading Port"
																	name="unLoadingPortId"
																	variant="filled"
																	size="small"
																	margin="dense"
																	inputProps={{
																		...params.inputProps,
																		autoComplete: 'new-password', // disable autocomplete and autofill
																	}}
																/>
															)}
														/>
													)}
												</FormGroup>
												<FormGroup className="formGrp" row>
													<TextField
														required
														label="Departure date"
														name="departureDate"
														type="datetime-local"
														InputLabelProps={{ shrink: true }}
														variant="filled"
														size="small"
														margin="dense"
														defaultValue={
															departureDate
																? departureDate
																: new Date(Number(voyage.departureDate))
																		.toISOString()
																		.slice(0, 16)
																		.replace('T', ' ')
														}
														onChange={({ target: { value } }) => {
															setDepartureDate(value)
														}}
														InputProps={{
															inputProps: {
																max:
																	arrivalDate ||
																	(voyage.arrivalDate &&
																		new Date(
																			Number(arrivalDate) ||
																				Number(voyage.arrivalDate)
																		)),
															},
														}}
													/>
													<TextField
														required
														label="Arrival date"
														name="arrivalDate"
														disabled={
															departureDate && departureDate ? false : true
														}
														type="datetime-local"
														InputLabelProps={{ shrink: true }}
														variant="filled"
														size="small"
														margin="dense"
														helperText={
															departureDate
																? ''
																: 'Please select a departure date first'
														}
														InputProps={{
															inputProps: {
																min: departureDate && departureDate,
															},
														}}
														defaultValue={
															arrivalDate ||
															new Date(Number(voyage.arrivalDate))
																.toISOString()
																.slice(0, 16)
																.replace('T', ' ')
														}
														onChange={({ target: { value } }) => {
															setArrivalDate(value)
														}}
													/>
												</FormGroup>
											</fieldset>
											<fieldset>
												<legend>Pick up</legend>
												<FormGroup className="formGrp" row>
													{depotsByPort && (
														<Autocomplete
															defaultValue={
																depotsByPort.find(({ id }) => id === depotId) ||
																voyage.containerTerminal
															}
															filterOptions={filterOptions}
															onChange={(event, value) => setDepotId(value?.id)}
															options={depotsByPort}
															getOptionLabel={(option) => option.name}
															renderOption={(props, option) => (
																<Box
																	key={option.id}
																	component="li"
																	sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
																	{...props}
																>
																	&nbsp;&nbsp;{option.name}
																</Box>
															)}
															renderInput={(params) => (
																<TextField
																	{...params}
																	required
																	label="Container Terminal"
																	name="containerTerminal"
																	variant="filled"
																	size="small"
																	margin="dense"
																	inputProps={{
																		...params.inputProps,
																		autoComplete: 'new-password', // disable autocomplete and autofill
																	}}
																/>
															)}
														/>
													)}
												</FormGroup>
											</fieldset>

											<fieldset>
												<legend>VGM | DOC | Port Cut-Off</legend>
												<FormGroup className="formGrp" row>
													<TextField
														label="VGM CLOSING"
														name="vgmClosing"
														variant="filled"
														type="datetime-local"
														InputLabelProps={{ shrink: true }}
														size="small"
														margin="dense"
														defaultValue={voyage.vgmClosing}
													/>
													<TextField
														label="DOC CLOSING"
														name="docClosing"
														variant="filled"
														type="datetime-local"
														InputLabelProps={{ shrink: true }}
														size="small"
														margin="dense"
														defaultValue={voyage.docClosing}
													/>
													<TextField
														label="Port Cut-Off"
														name="portCutoff"
														variant="filled"
														type="datetime-local"
														InputLabelProps={{ shrink: true }}
														size="small"
														margin="dense"
														defaultValue={voyage.portCutoff}
													/>
												</FormGroup>
											</fieldset>

											<Button
												disabled={departureDate && arrivalDate && unLoadingPort}
												className="submitBtn"
												type="submit"
												variant="contained"
												color="primary"
											>
												{' '}
												<SaveIcon />
												&nbsp;UPDATE{' '}
											</Button>
										</>
									)}
								</TabPanel>
								{/* Prices */}
								<TabPanel value={tabvalue} index={1} variant="fullWidth">
									<fieldset>
										<legend>Price by Container type </legend>
										<FormGroup className="formGrp" row>
											<TableContainer
												component={Paper}
												style={{ marginTop: '20px' }}
											>
												<Table size="small" dense table stickyHeader>
													<TableHead>
														<TableRow>
															<TableCell style={{ fontWeight: 'bold' }}>
																Container Type
															</TableCell>
															<TableCell style={{ fontWeight: 'bold' }}>
																Price
															</TableCell>
															<TableCell style={{ fontWeight: 'bold' }}>
																Contract Price
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{allContainerTypes &&
															allContainerTypes.map((elm, i) => {
																return (
																	<TableRow key={i}>
																		<TableCell>{elm?.type}</TableCell>
																		<TableCell>
																			<TextField
																				variant="outlined"
																				size="small"
																				margin="dense"
																				type="number"
																				data-type={elm?.id}
																				InputProps={{
																					inputProps: { min: 0 },
																				}}
																				onWheel={(e) => e.target.blur()}
																				defaultValue={
																					containerTypesPrice &&
																					containerTypesPrice.find(
																						({ typeId }) => typeId === elm.id
																					)?.price
																				}
																				onChange={(e) => {
																					let newState =
																						containerTypesPrice.map((obj) => {
																							if (obj.typeId === elm.id) {
																								return {
																									...obj,
																									price: Number(e.target.value),
																								}
																							}
																							return obj
																						})
																					setContainerTypesPrice(newState)
																				}}
																			></TextField>
																		</TableCell>
																		<TableCell>
																			<TextField
																				variant="outlined"
																				size="small"
																				margin="dense"
																				type="number"
																				data-type={elm?.id}
																				InputProps={{
																					inputProps: { min: 0 },
																				}}
																				onWheel={(e) => e.target.blur()}
																				defaultValue={
																					containerTypesPrice &&
																					containerTypesPrice.find(
																						({ typeId }) => typeId === elm.id
																					)?.contractPrice
																				}
																				onChange={(e) => {
																					let newState =
																						containerTypesPrice.map((obj) => {
																							if (obj.typeId === elm.id) {
																								return {
																									...obj,
																									contractPrice: Number(
																										e.target.value
																									),
																								}
																							}
																							return obj
																						})

																					setContainerTypesPrice(newState)
																				}}
																			/>
																		</TableCell>
																	</TableRow>
																)
															})}
													</TableBody>
												</Table>
											</TableContainer>
										</FormGroup>
									</fieldset>
								</TabPanel>
								{/* Detailes */}
								<TabPanel value={tabvalue} index={2} variant="fullWidth">
									<section className="voyageDetailsContainer">
										<section className="shipDetailsContainer">
											<h2 className="detailsTitle">Ship Details</h2>
											<div>
												<Table>
													<TableRow>
														<th>Ship</th>
														<th>Flag</th>
													</TableRow>
													<TableRow
														align="left"
														hover
														selected
														style={{ height: '50px' }}
													>
														<td>{voyage.ship.name}</td>
														<td>{voyage.ship.flag}</td>
													</TableRow>
												</Table>
											</div>
											<br />
											<div>
												<Table>
													<TableRow>
														<th>NET Weight (Kg)</th>
														<th title="Twenty-foot equivalent unit">TEU</th>
													</TableRow>
													<TableRow
														align="left"
														hover
														selected
														style={{ height: '50px' }}
													>
														<td>{voyage.ship.netTonage}</td>
														<td>{voyage.ship.teu}</td>
													</TableRow>
												</Table>
											</div>
										</section>
										<section className="currentLoadingDetailsContainer">
											<h2 className="detailsTitle">TEU details</h2>
											<Table>
												<TableRow>
													<th>TEU availble</th>
													<th>TEU reserved</th>
													<th>TEU reserved %</th>
												</TableRow>
												<TableRow align="left" hover selected>
													<td style={{ paddingLeft: '5px' }}>
														{Number(voyage.ship.teu) - Number(voyage.teu)}
													</td>
													<td style={{ paddingLeft: '5px' }}>
														{Number(voyage.teu)}
													</td>
													<td style={{ width: '150px' }}>
														<div className="pourcentageContainer">
															<div
																className="reservedTeuP"
																style={{
																	width: `
																		${(100 * Number(voyage.teu)) / Number(voyage.ship.teu)}%`,
																}}
															>
																<p style={{ marginLeft: '20px' }}>
																	{(
																		(100 * Number(voyage.teu)) /
																		Number(voyage.ship.teu)
																	).toFixed(2)}
																	%
																</p>
															</div>
														</div>
													</td>
												</TableRow>
											</Table>
											<br />
											<h2 className="detailsTitle">Weight details</h2>
											<Table>
												<TableRow>
													<th>NET Weight availble (Kg)</th>
													<th>Weight reserved</th>
													<th>Weight reserved %</th>
												</TableRow>
												<TableRow align="left" hover selected>
													<td style={{ paddingLeft: '5px' }}>
														{Number(voyage.ship.netTonage) -
															Number(voyage.weight)}
													</td>
													<td style={{ paddingLeft: '5px' }}>
														{Number(voyage.weight)}
													</td>
													<td style={{ width: '150px' }}>
														<div className="pourcentageContainer">
															<div
																className="reservedTeuP"
																style={{
																	width: `
																		${(100 * Number(voyage.weight)) / Number(voyage.ship.netTonage)}%`,
																}}
															>
																<p style={{ marginLeft: '20px' }}>
																	{(
																		(100 * Number(voyage.weight)) /
																		Number(voyage.ship.netTonage)
																	).toFixed(2)}
																	%
																</p>
															</div>
														</div>
													</td>
												</TableRow>
											</Table>
										</section>
									</section>
								</TabPanel>
							</SwipeableViews>
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

export default EditVoyage
