import React, { useEffect } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { useCurrentUser } from '../../currentUserContext'
import { useQuery } from '@apollo/client'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { loader } from 'graphql.macro'
import * as moment from 'moment'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import Spinner from '../../UI/Spinner'

import Stack from '@mui/material/Stack'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

import EditIcon from '@mui/icons-material/BorderColor'

const ALL_MARCHENDISES = loader('./graphql/allMarchandises.graphql')

export default function CommodityList() {
	const currentUser = useCurrentUser()

	const [sortModel, setSortModel] = React.useState([
		{
			field: 'updatedAt',
			sort: 'desc',
		},
	])

	const {
		data: { allMarchandises = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_MARCHENDISES)

	const columns = [
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}
				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-commodity/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			filterable: false,
			minWidth: 100,
			sortable: false,
			filtrable: false,
			hide: true,
			flex: 1,
		},
		{ field: 'name', headerName: 'Commodity', minWidth: 100, flex: 1 },
		{ field: 'price', headerName: 'Price', minWidth: 100, flex: 1 },
		{ field: 'active', headerName: 'Active', minWidth: 50, flex: 1 },
		{
			field: 'updatedAt',
			// hide: true,
			flex: 1,
			headerName: 'Updated',
			minWidth: 120,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
	]

	if (localStorage.getItem('createCommodityState') !== '') {
		toast.success(localStorage.getItem('createCommodityState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('createCommodityState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allMarchandises])

	if (!currentUser) return <Redirect to="/" />
	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allMarchandises && (
				<>
					<DataGrid
						rows={allMarchandises}
						autoHeight={true}
						columns={columns}
						sortModel={sortModel}
						onSortModelChange={(model) => setSortModel(model)}
						components={{
							Toolbar: GridToolbar,
							NoResultsOverlay: () => {
								return (
									<Stack
										style={{ marginTop: '600px !important' }}
										alignItems="center"
										justifyContent="center"
									>
										<FilterListOffIcon />
										<p>Filter returns no result</p>
									</Stack>
								)
							},
							NoRowsOverlay: () => {
								return (
									<Stack
										style={{ marginTop: '600px !important' }}
										alignItems="center"
										justifyContent="center"
									>
										<FilterListOffIcon />
										<p>No Rows</p>
									</Stack>
								)
							},
						}}
					/>
				</>
			)}
		</>
	)
}
