import React, { useState, useEffect } from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import TextField from '@material-ui/core/TextField'
import * as moment from 'moment'
import Button from '@material-ui/core/Button'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import InputAdornment from '@mui/material/InputAdornment'
import Alert from '@mui/material/Alert'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import UploadZone from './UploadZone'
import ContainerPage from './ContainerPage'
import Paper from '@mui/material/Paper'
import Box from '@material-ui/core/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SwipeableViews from 'react-swipeable-views'
import BuildIcon from '@mui/icons-material/Build'
import ShareLocationIcon from '@mui/icons-material/ShareLocation'
import { useTheme } from '@mui/material/styles'

const BOOKING = loader('./graphql/bookingByNumber.graphql')
const ALL_STATUS = loader('./graphql/operatorStatusList.graphql')
const CONTAINERS_LIST = loader(
	'./graphql/allContainersByPortAndTypeAndBooking.graphql'
)
const OPERATE_CONTAINER = loader('./graphql/operateBookingContainer.graphql')
const DEPOTS_BY_PORT = loader('./graphql/depotsByPort.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<section>{children}</section>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const OperateBooking = () => {
	const currentUser = useCurrentUser()
	const [bookingNumber, setBookingNumber] = useState('')
	const [sealNumber, setSealNumber] = useState()
	const [vgm, setVgm] = useState()
	const [weight, setWeight] = useState()
	const [containerTypeId, setContainerTypeId] = useState()
	const [statusId, setStatusId] = useState()
	const [containerNumber, setContainerNumber] = useState()
	const [bookingId, setBookingId] = useState()
	const [depotId, setDepotId] = useState()
	const [portId, setPortId] = useState()

	const [remark, setRemark] = useState('')
	const [date, setDate] = useState(
		moment().format('YYYY-MM-DD[T]HH:mm').toString()
	)
	const [photos, setPhotos] = useState({
		firstPhoto: '',
	})

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => `${option.name}`,
	})

	const [tabvalue, setTabValue] = useState(0)
	const theme = useTheme()

	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const handleChange = (event, newValue) => {
		setTabValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setTabValue(index)
	}

	const [
		getBooking,
		{
			data: { bookingByNumber = null } = {},
			loading: bookingLoading,
			error: bookingError,
		},
	] = useLazyQuery(BOOKING)

	const [
		getContainers,
		{
			data: { allContainersByPortAndTypeAndBooking = null } = {},
			loading: containersLoading,
			error: containersError,
		},
	] = useLazyQuery(CONTAINERS_LIST)

	const [
		getDepots,
		{
			data: { depotsByPort = null } = {},
			loading: depotsLoading,
			error: depotsError,
		},
	] = useLazyQuery(DEPOTS_BY_PORT)

	const { data: { operatorStatusList = null } = {}, loading: statusLoading } =
		useQuery(ALL_STATUS)

	const { data: { allPorts = null } = {}, loading: allPortsLoading } =
		useQuery(ALL_PORTS)

	const [
		operateBookingContainer,
		{
			data = null,
			error: operateContainerError,
			loading: operateContainerLoading,
		},
	] = useMutation(OPERATE_CONTAINER)

	const handleFetchBooking = () => {
		return getBooking({
			variables: {
				number: bookingNumber,
			},
		})
	}

	useEffect(() => {
		if (containerTypeId) {
			getContainers({
				variables: {
					typeId: containerTypeId,
					portId: isAdmin ? portId : currentUser.port.id,
					bookingId,
				},
			})
		}
	}, [containerTypeId])

	const handleOperateContainer = () => {
		return operateBookingContainer({
			variables: {
				input: {
					bookingId,
					typeId: containerTypeId,
					remark,
					statusId: statusId ? statusId : operatorStatusList[0]?.id,
					containerNumber,
					date,
					weight: Number(weight),
					sealNumber,
					vgm: Number(vgm),
					portId: currentUser ? currentUser?.port?.id : null,
					depotId,
					...photos,
				},
			},
		})
	}

	useEffect(() => {
		if (bookingByNumber) {
			setBookingId(bookingByNumber.id)
		}
	}, [bookingByNumber])

	useEffect(() => {
		if (currentUser && currentUser.port) {
			getDepots({
				variables: {
					portId: currentUser.port.id,
				},
			})
		}
		if (isAdmin && portId) {
			getDepots({
				variables: {
					portId,
				},
			})
		}
	}, [currentUser, portId])

	if (!currentUser) return <Redirect to="/" />

	if (data && data.operateBookingContainer)
		return (
			<Alert onClose={() => window.location.reload()} severity="success">
				Your request has been submitted successfully!
			</Alert>
		)
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={tabvalue} onChange={handleChange} variant="fullWidth">
						<Tab
							icon={<ShareLocationIcon />}
							label="Tracking"
							{...a11yProps(0)}
						/>
						<Tab icon={<BuildIcon />} label="Maintenance" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tabvalue}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel
						value={tabvalue}
						index={0}
						variant="fullWidth"
						style={{ padding: '0px !important' }}
					>
						<Box className="operatorContainer" variant="fullWidth">
							<section className="operatorContainerHeader"></section>
							<section className="bookingSearchSection">
								<TextField
									fullWidth
									label="Booking"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={bookingNumber}
									onChange={({ target: { value } }) => {
										setBookingNumber(value)
									}}
								/>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									onClick={handleFetchBooking}
									style={{ marginLeft: '20px' }}
								>
									Search
								</Button>
							</section>
							{bookingError && (
								<Paper
									variant="outlined"
									style={{ width: '100%', padding: '10px' }}
								>
									{bookingError.graphQLErrors[0].message}
								</Paper>
							)}

							{bookingByNumber && isAdmin && allPorts && (
								<Autocomplete
									fullWidth
									options={allPorts}
									getOptionLabel={(option) => option.name}
									filterOptions={filterOptions}
									onChange={(event, value) => setPortId(value?.id)}
									renderOption={(props, option) => (
										<Box
											key={option.id}
											component="li"
											sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
											{...props}
										>
											&nbsp;&nbsp;{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											required
											{...params}
											variant="filled"
											size="small"
											margin="dense"
											label="Port"
										/>
									)}
								/>
							)}

							{bookingByNumber && depotsByPort && (
								<>
									<Autocomplete
										fullWidth
										getOptionLabel={(option) => option?.name}
										options={depotsByPort}
										onChange={(event, value) => {
											setDepotId(value?.id)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Depot"
												variant="filled"
												size="small"
												margin="normal"
											/>
										)}
									/>
									{depotId && (
										<Autocomplete
											fullWidth
											getOptionLabel={(option) => option?.containerType.type}
											options={bookingByNumber.containerTypeQuantity}
											onChange={(event, value) => {
												setContainerTypeId(value.containerType?.id)
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Container Type"
													variant="filled"
													size="small"
													margin="normal"
												/>
											)}
										/>
									)}

									{depotId && allContainersByPortAndTypeAndBooking && (
										<Autocomplete
											fullWidth
											getOptionLabel={(option) => option?.number}
											// options={depotsByPort
											// 	.find(({ id }) => id === depotId)
											// 	?.containersToBeOperated.filter(
											// 		({ type: containerType }) =>
											// 			containerType.id === containerTypeId
											// 	)}
											options={allContainersByPortAndTypeAndBooking}
											onChange={(event, value) => {
												setStatusId(value.status?.id)
												setContainerNumber(value.number)
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Container Number List"
													variant="filled"
													size="small"
													margin="normal"
												/>
											)}
										/>
									)}

									{operatorStatusList && containerNumber && (
										<>
											<Autocomplete
												fullWidth
												value={
													operatorStatusList.find(
														({ id }) => id === statusId
													) || operatorStatusList[0]
												}
												getOptionLabel={(option) => option?.status}
												options={operatorStatusList}
												onChange={(event, value) => {
													setStatusId(value?.id)
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Status"
														variant="filled"
														size="small"
														margin="normal"
													/>
												)}
											/>
											<TextField
												fullWidth
												label="seal number"
												name="name"
												variant="filled"
												size="small"
												margin="normal"
												InputLabelProps={{ shrink: true }}
												onChange={({ target: { value } }) => {
													setSealNumber(value)
												}}
											/>
											<TextField
												fullWidth
												label="VGM"
												name="name"
												variant="filled"
												size="small"
												margin="normal"
												InputLabelProps={{ shrink: true }}
												onChange={({ target: { value } }) => {
													setVgm(Number(value))
												}}
											/>
											<TextField
												fullWidth
												label="Weight"
												name="weight"
												type="number"
												variant="filled"
												size="small"
												margin="normal"
												onChange={({ target: { value } }) => {
													setWeight(Number(value))
												}}
												InputProps={{
													shrink: true,
													endAdornment: (
														<InputAdornment position="end">Kg</InputAdornment>
													),
												}}
											/>
											<TextField
												fullWidth
												defaultValue={date}
												label="Date"
												type="datetime-local"
												name="name"
												variant="filled"
												size="small"
												margin="normal"
												InputLabelProps={{ shrink: true }}
												onChange={({ target: { value } }) => {
													setDate(value)
												}}
											/>
											<section className="uploadImgsContainer">
												{Object.keys(photos).map((el, i) => {
													return (
														<UploadZone
															key={i}
															photo={(url) =>
																setPhotos({ ...photos, [el]: url })
															}
														/>
													)
												})}
											</section>

											<TextareaAutosize
												aria-label="minimum height"
												minRows={4}
												placeholder="Minimum 3 rows"
												style={{ width: '100%', marginTop: '10px' }}
												onChange={({ target: { value } }) => {
													setRemark(value)
												}}
											/>

											<Button
												fullWidth
												style={{ marginTop: '20px' }}
												type="submit"
												variant="contained"
												color="primary"
												onClick={handleOperateContainer}
											>
												Submit
											</Button>
										</>
									)}
								</>
							)}
						</Box>
					</TabPanel>
					<TabPanel value={tabvalue} index={1} variant="fullWidth">
						<ContainerPage />
					</TabPanel>
				</SwipeableViews>
			</Box>
		</>
	)
}

export default OperateBooking
