import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import SaveIcon from '@material-ui/icons/Save'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Visibility from '@material-ui/icons/Visibility'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

import IconButton from '@material-ui/core/IconButton'
import FilledInput from '@material-ui/core/FilledInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const USER = loader('./graphql/user.graphql')
const UPDATE_USER = loader('./graphql/updateUser.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')
const ALL_ROLES = loader('./graphql/allRoles.graphql')

const EditUser = () => {
	const params = useParams()
	const [selectedPort, setSelectedPort] = useState()
	const [values, setValues] = useState({
		showPassword: false,
	})
	const [archived, setArchived] = useState()
	const [roleId, setRoleId] = useState()
	const [showPortList, setShowPortList] = useState()
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const { data: { user = {} } = {}, loading: userLoading } = useQuery(USER, {
		variables: { id: params.id },
	})

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const { data: { allRoles = null } = {}, loading: allRolesLoading } =
		useQuery(ALL_ROLES)

	const [updateUser, { data, error, loading }] = useMutation(UPDATE_USER)

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, username, password } = e.target
		return updateUser({
			variables: {
				input: {
					id: params.id,
					name: name.value,
					userName: username.value,
					password: password ? password.value : null,
					portId: selectedPort !== '' ? selectedPort : user.port?.id,
					roleId,
					archived,
				},
			},
		})
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	useEffect(() => {
		if (user && user.roles) {
			setRoleId(user.roles[0].id)
			setArchived(user.archived)
		}
	}, [user])

	useEffect(() => {
		if (roleId && allRoles) {
			const isOperatorRole =
				allRoles.find(({ id }) => id === roleId).role === 'operator'
			const isEmployee =
				allRoles.find(({ id }) => id === roleId).role === 'employee'
			setShowPortList(isOperatorRole || isEmployee)
		}
	}, [roleId])

	useEffect(() => {
		if (data) {
			localStorage.setItem('userState', '✔️ User updated successfully ! ')
			window.location.reload()
		}
	}, [data])

	if (userLoading || loading) return <Spinner style={{ marginTop: '400px' }} />

	return (
		<>
			{user && (
				<Box
					className="mainZone"
					component="form"
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<div
						style={{
							width: '100%',
							height: '50px',
							backgroundColor: '#0c1836',
							marginBottom: '10px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<h1 style={{ color: '#fff' }}>Edit User - {user.userName}</h1>
					</div>
					<fieldset>
						<FormGroup className="formGrp" row>
							<TextField
								defaultValue={user.name}
								label="Name"
								name="name"
								variant="filled"
								size="small"
								margin="normal"
							/>
							<TextField
								defaultValue={user.userName}
								label="Username"
								name="username"
								variant="filled"
								size="small"
								margin="normal"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<AccountCircle />
										</InputAdornment>
									),
								}}
							/>
						</FormGroup>
						<FormGroup className="formGrp" row>
							{user && (
								<FormControl variant="filled">
									{allRoles && (
										<Autocomplete
											defaultValue={user.roles[0]}
											options={allRoles}
											onChange={(event, value) => setRoleId(value?.id)}
											getOptionLabel={(option) => option.role}
											renderOption={(props, option) => (
												<Box
													key={option.id}
													component="li"
													sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
													{...props}
												>
													&nbsp;&nbsp;{option.role}
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Role"
													variant="filled"
													size="small"
													required
													margin="dense"
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
									)}
								</FormControl>
							)}
							{showPortList && (
								<FormControl variant="filled">
									{allPorts && (
										<Autocomplete
											defaultValue={user?.port}
											options={allPorts}
											filterOptions={filterOptions}
											onChange={(event, value) => setSelectedPort(value?.id)}
											getOptionLabel={(option) => option.name}
											renderOption={(props, option) => (
												<Box
													key={option.id}
													component="li"
													sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
													{...props}
												>
													&nbsp;&nbsp;{option.name}
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Port"
													variant="filled"
													size="small"
													required
													margin="dense"
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
									)}
								</FormControl>
							)}

							<FormControl variant="filled">
								<InputLabel htmlFor="filled-adornment-password">
									Password
								</InputLabel>
								<FilledInput
									id="filled-adornment-password"
									name="password"
									label="password"
									type={values.showPassword ? 'text' : 'password'}
									value={values.password}
									onChange={handleChange('password')}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{values.showPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
							<FormControl variant="filled"></FormControl>
							<FormControlLabel
								control={
									<Checkbox
										defaultChecked={user.archived}
										onChange={({ target: { checked } }) => setArchived(checked)}
									/>
								}
								label="Archive"
							/>
						</FormGroup>
						<Button
							className="submitBtn"
							type="submit"
							variant="contained"
							color="primary"
						>
							{' '}
							<SaveIcon />
							&nbsp;UPDATE{' '}
						</Button>
					</fieldset>
				</Box>
			)}
		</>
	)
}

export default EditUser
