import React from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { Redirect } from 'react-router-dom'
import useStyles from './useStyles'
import Logo from '../../../asset/logoBg.png'

const Home = () => {
	const currentUser = useCurrentUser()

	const { toolbar, content } = useStyles()

	if (!currentUser) return <Redirect to="/" />
	if (currentUser.roles.find(({ role }) => role === 'operator'))
		return <Redirect to="/operate-booking" />

	return (
		// <div className={root}>
		<main className={content}>
			<div className={toolbar}></div>
			<img
				src={Logo}
				alt="Asasline"
				style={{ width: '200px', marginTop: '80px' }}
			/>
		</main>
		// </div>
	)
}

export default Home
