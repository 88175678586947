import React, { Component } from 'react'
import InvoiceTemplateEN from './InvoiceTemplateEN'

class Print extends Component {
	render() {
		return <InvoiceTemplateEN id={this.props.id} />
	}
}

export default Print
