import * as React from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Moment from 'react-moment'
import Spinner from '../../../UI/Spinner'

import './style.css'

// import Logo from './asset/img/logo.png'
// import Qr from './asset/img/asaslineQR.png'

const INVOICE = loader('./graphql/invoice.graphql')

const BookingTemplateEN = ({ id }) => {
	const { data: { invoice = null } = {}, loading } = useQuery(INVOICE, {
		variables: {
			id,
		},
	})

	if (loading) return <Spinner />

	return (
		<>
			{invoice && (
				<>
					<div class="invoice-box">
						<table cellpadding="0" cellspacing="0">
							<tr class="top">
								<td colspan="2">
									<table>
										<tr>
											<td class="title">
												<h1>AsasLine SA</h1>
												{/* <img
												src="https://www.sparksuite.com/images/logo.png"
												style={{ width: '100%', maxWidth: '300px' }}
											/> */}
											</td>

											<td>
												Invoice #: {invoice && invoice.number}
												<br />
												Created:{' '}
												<Moment
													timestamp={invoice.updatedAt}
													format="DD/MM/yyyy"
												/>
												<br />
											</td>
										</tr>
									</table>
								</td>
							</tr>

							<tr class="information">
								<td colspan="2">
									<table>
										<tr>
											<td>
												AsasLine, SA.
												<br />
												Brussels
												<br />
												Belgium
											</td>

											<td>
												Client name.
												<br />
												Contact personne
												<br />
												contact
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr class="heading">
								<td>Payment Method</td>
							</tr>
							{invoice.cash ? (
								<tr class="details">
									<td>Cash</td>
								</tr>
							) : (
								<tr class="details">
									<td>Bank transfer</td>
								</tr>
							)}

							<tr class="heading">
								<td>Item</td>

								<td>Price</td>
							</tr>
							{invoice.credits.map((elm, index) => {
								if (index === invoice.length - 1) {
									return (
										<tr class="item last">
											<td>{elm.creditItem?.item}</td>

											<td>
												{elm.billed} {elm.currency.currency}
											</td>
										</tr>
									)
								} else {
									return (
										<tr class="item">
											<td>{elm.creditItem?.item}</td>

											<td>
												{elm.billed} {elm.currency.currency}
											</td>
										</tr>
									)
								}
							})}

							<tr class="total">
								<td></td>

								<td>
									Total: {invoice.amount} {invoice.currency.currency}
								</td>
							</tr>
						</table>
					</div>
				</>
			)}
		</>
	)
}

export default BookingTemplateEN
