import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import TabPanel from './TabPanel'
import Dialog from '@material-ui/core/Dialog'
import Badge from '@material-ui/core/Badge'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@mui/material/IconButton'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Button } from '@material-ui/core'
import ImageViewer from 'react-simple-image-viewer'
import CompanyIcon from '@mui/icons-material/Apartment'
import EmployeeIcon from '@mui/icons-material/Badge'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormLabel from '@mui/material/FormLabel'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import PageviewIcon from '@mui/icons-material/Pageview'
import EventIcon from '@mui/icons-material/Event'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import DirectionsIcon from '@mui/icons-material/Directions'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import OperatorIcons from '@mui/icons-material/CoPresent'
import UpdateDateIcon from '@mui/icons-material/Event'
import CancelIcon from '@mui/icons-material/CancelPresentation'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import Spinner from '../../UI/Spinner'

import ReactToPrint from 'react-to-print'

import ButtonGroup from '@material-ui/core/ButtonGroup'

import BookingTemplateFR from './printBooking/indexFR'
import BookingTemplateEN from './printBooking/indexEN'

import '../../../asset/style.css'

const ALL_PORTS = loader('../../pages/CreateBooking/graphql/allPorts.graphql')
const BOOKING = loader('./graphql/booking.graphql')
const ALL_CLIENTS = loader(
	'../../pages/CreateBooking/graphql/allClients.graphql'
)
const ALL_MARCHANDISES = loader(
	'../../pages/CreateBooking/graphql/allMarchandises.graphql'
)
const UPDATE_BOOKING = loader('./graphql/updateBooking.graphql')
const REMOVE_ASSIGNED_CONTAINER = loader(
	'./graphql/removeAssignedContainer.graphql'
)
const ALL_REMOVED_CONTAINER = loader(
	'./graphql/removedAssignedContainer.graphql'
)
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')
const VOYAGE = loader(
	'../../pages/CreateBooking/graphql/voyagesByDestinationAndDate.graphql'
)
const ALL_DEPOTS_BY_PORT = loader('./graphql/depotsByPort.graphql')

const EditBooking = () => {
	const componentRef = useRef()
	let location = useLocation()

	// const textInput = useRef(null)
	const { id } = useParams()
	const currentUser = useCurrentUser()
	const [tabvalue, setTabValue] = useState(0)
	const theme = useTheme()
	const [values, setValues] = useState({})
	const [quantity, setQuantity] = useState(0)
	const [estimateWeight, setEstimateWeight] = useState(0)
	const [selectedContainerPrice, setSelectedContainerPrice] = useState(0)
	const [showPriceFields, setShowPriceFields] = useState(true)
	const [selectedDepot, setSelectedDepot] = useState()
	const [selectedContainerType, setSelectedContainerType] = useState()
	const [currentImage, setCurrentImage] = useState(0)
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const [loadImgs, setLoadImgs] = useState([])
	const [voyageValues, setVoyageValues] = useState({})
	const [selectedContainersArray, setSelectedContainersArray] = useState([])
	const [removedContainer, setRemovedContainer] = useState()
	const [voyageId, setVoyageId] = useState()
	const [totalContainersPrice, setTotalContainersPrice] = useState(0)
	const [containerTypeInContractQuantity, setContainerTypeInContractQuantity] =
		useState()

	const [openRemoveContainerDialog, setOpenRemoveContainerDialog] =
		useState(false)
	const [removedContainerId, setRemovedContainerId] = useState(null)
	const [removedContainerBookingRowId, setRemovedContainerBookingRowId] =
		useState(null)

	const [containerTypeCount, setContainerTypeCount] = useState([])
	const [totalContainerTypeCount, setTotalContainerTypeCount] = useState(0)

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const [
		getVoyages,
		{
			data: { voyagesByDestinationAndDate = null } = {},
			voyageLoading,
			refetch: refetchVoyage,
		},
	] = useLazyQuery(VOYAGE)

	const {
		data: { booking = null } = {},
		loading,
		refetch,
	} = useQuery(BOOKING, {
		variables: {
			id,
		},
	})

	const {
		data: { removedAssignedContainersByBooking = null } = {},
		loading: allRemovedContainerLoading,
		refetch: removedAssignedContainersRefetch,
	} = useQuery(ALL_REMOVED_CONTAINER, {
		variables: {
			bookingId: id,
		},
	})

	const {
		data: { allClients = null } = {},
		loading: allClientsLoading,
		refetch: refetchClient,
	} = useQuery(ALL_CLIENTS)

	const {
		data: { allMarchandises = null } = {},
		loading: marchandiseLoading,
		refetch: refetchMarchandise,
	} = useQuery(ALL_MARCHANDISES)

	const {
		data: { allContainerTypes = null } = {},
		loading: allContainerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const [
		updateBooking,
		{
			data: bookingData = null,
			error: updateBookingError,
			loading: updateBookingLoading,
		},
	] = useMutation(UPDATE_BOOKING)

	const [
		getDepotsByPort,
		{ data: { depotsByPort = null } = {}, loading: depotsLoading },
	] = useLazyQuery(ALL_DEPOTS_BY_PORT)

	const [
		removeAssignedContainer,
		{
			data: removeAssignedContainerData = null,
			error: removeAssignedContainerError,
			loading: removeAssignedContainerLoading,
		},
	] = useMutation(REMOVE_ASSIGNED_CONTAINER)

	//Remove extra keys from booking obj
	const removeKeysFromObject = (obj) => {
		let bookingObj = { ...obj }
		delete bookingObj.number
		delete bookingObj.contract
		delete bookingObj.client
		delete bookingObj.marchandise
		delete bookingObj.__typename
		delete bookingObj.user
		delete bookingObj.createdAt
		delete bookingObj.billOfLanding
		delete bookingObj.voyage
		delete bookingObj.containerTypeQuantity
		delete bookingObj.assignedContainers
		delete bookingObj.marchandisePrice
		return setValues({
			...bookingObj,
			userId: currentUser.id,
			marchandiseId: obj.marchandise.id,
			voyageId: obj.voyage.id,
			clientId: obj.client.id,
			contractId: obj?.contract?.id,
		})
	}

	const openImageViewer = useCallback((index, imgs) => {
		setLoadImgs(imgs)
		setCurrentImage(index)
		setIsViewerOpen(true)
	}, [])

	const closeImageViewer = () => {
		setCurrentImage(0)
		setIsViewerOpen(false)
	}

	const handleRemoveContainerDialogOpen = (id, ContainerId) => {
		setOpenRemoveContainerDialog(true)
		setRemovedContainerBookingRowId(id)
		setRemovedContainerId(ContainerId)
	}

	const handleRemoveContainerRow = (i) => {
		const findRemovedContainer = selectedContainersArray.find(
			(e, index) => index === i
		)
		setSelectedContainersArray([
			...selectedContainersArray.filter((e, index) => index !== i),
			{ ...findRemovedContainer, quantity: 0, estimateWeight: 0 },
		])

		setSelectedDepot(null)
		setSelectedContainerType(null)
		setQuantity(0)
		setSelectedContainerPrice()
		setEstimateWeight()
	}

	const handleAddingContainerRow = () => {
		const findSameContainerTypeInSameDepot = selectedContainersArray.find(
			({ depot, container }) =>
				depot.id === selectedDepot.id &&
				container?.id === selectedContainerType.containerType?.id
		)
		if (findSameContainerTypeInSameDepot) {
			const clonedSelectedContainersArrayWithNewQuantity = [
				...selectedContainersArray,
			].map((el) => {
				const { depot, container } = el
				if (
					depot.id === selectedDepot.id &&
					container?.id === selectedContainerType.containerType?.id
				) {
					return {
						...el,
						quantity: el.quantity + quantity,
						estimateWeight: el.estimateWeight
							? Number(el.estimateWeight) + Number(estimateWeight)
							: Number(estimateWeight),
					}
				} else {
					return el
				}
			})
			setSelectedContainersArray([
				...clonedSelectedContainersArrayWithNewQuantity,
			])
		} else {
			setSelectedContainersArray([
				...selectedContainersArray,
				{
					depot: selectedDepot,
					container: {
						id: selectedContainerType.containerType?.id,
						type: selectedContainerType.containerType?.type,
					},
					quantity,
					pricePerContainer: selectedContainerPrice,
					estimateWeight: Number(estimateWeight),
				},
			])
		}

		setSelectedDepot(null)
		setSelectedContainerType(null)
		setQuantity(0)
		setSelectedContainerPrice(0)
		setEstimateWeight(0)
	}

	const handleRemoveContainerDialogClose = () => {
		setOpenRemoveContainerDialog(false)
	}

	const handleRemoveAssignedContainer = () => {
		setRemovedContainer(removedContainerId)
		return removeAssignedContainer({
			variables: {
				input: {
					bookingContainerId: removedContainerBookingRowId,
				},
			},
		})
	}

	function a11yProps(index) {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-TabPanel-${index}`,
		}
	}

	const handleTabsChange = (event, newValue) => {
		setTabValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setTabValue(index)
	}

	const handleFetchVoyages = () => {
		return getVoyages({
			variables: voyageValues,
		})
	}

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => `${option.name} ${option.company}`,
	})

	useEffect(() => {
		if (bookingData && !updateBookingError) {
			//window.location.reload()
			localStorage.setItem('bookingState', '✔️ Booking updated successfully ! ')
		}
	}, [bookingData])

	useEffect(() => {
		if (booking) {
			removeKeysFromObject(booking)
			setShowPriceFields(booking.priceDetails)
			setSelectedContainersArray(
				[...booking.containerTypeQuantity].map(
					({
						quantity,
						containerType,
						pricePerContainer,
						depot,
						estimateWeight,
					}) => ({
						container: containerType,
						quantity,
						pricePerContainer,
						estimateWeight,
						depot,
					})
				)
			)
		}
	}, [booking])

	useEffect(() => {
		handleTotalCtrSelected(containerTypeCount)
	}, [containerTypeCount])

	useEffect(() => {
		if (voyagesByDestinationAndDate) {
			getDepotsByPort({
				variables: {
					portId: voyageValues.loadingPortId,
				},
			})
		} else if (booking && !voyagesByDestinationAndDate) {
			getDepotsByPort({
				variables: {
					portId: booking.voyage?.loadingPort.id,
				},
			})
		}
	}, [voyagesByDestinationAndDate, booking])
	const handleSubmit = () => {
		return updateBooking({
			variables: {
				input: {
					...values,
					containers: selectedContainersArray.map(
						({
							depot,
							container,
							pricePerContainer,
							quantity,
							estimateWeight,
						}) => ({
							typeId: container.id,
							quantity,
							estimateWeight,
							pricePerContainer,
							depotId: depot.id,
						})
					),
					total: Number(totalContainersPrice),
				},
			},
		})
	}

	const handleTotalCtrSelected = (obj) => {
		let tot = 0
		if (obj.length === 0) setTotalContainerTypeCount(0)
		else {
			obj.map((elm) => {
				tot += elm.quantity
			})

			setTotalContainerTypeCount(tot)
		}
	}

	useEffect(() => {
		removedAssignedContainersRefetch()
	}, [removeAssignedContainerData])

	useEffect(() => {
		setVoyageId(booking?.voyage?.id)
	}, [booking, voyageId])

	useEffect(() => {
		if (allMarchandises && booking) {
			const { transport, waiver, thco, tsco } = values
			const totalFees = [transport, waiver, thco, tsco].reduce((a, b) => a + b)

			//If marchandise is changed than the marchandise has been assigned to the booking
			//when it was created we change the price
			const addedMarchandisePrice =
				values.marchandiseId === booking.marchandiseId
					? booking.marchandisePrice
					: allMarchandises.find(({ id }) => id === values.marchandiseId)?.price

			setTotalContainersPrice(addedMarchandisePrice || 0)
			if (containerTypeCount.length) {
				setTotalContainersPrice(
					containerTypeCount
						.map(({ pricePerContainer, quantity }) =>
							Number(pricePerContainer * quantity)
						)
						.reduce((a, b) => a + b) +
						(addedMarchandisePrice || 0) +
						totalFees
				)
			}
		}
	}, [
		allMarchandises,
		booking,
		containerTypeCount,
		values.marchandiseId,
		values.transport,
		values.waiver,
		values.thco,
		values.tsco,
	])

	if (!currentUser) return <Redirect to="/" />

	if (
		loading ||
		allClientsLoading ||
		marchandiseLoading ||
		updateBookingLoading ||
		allContainerTypeLoading ||
		allRemovedContainerLoading
	)
		return <Spinner />

	if (removeAssignedContainerData)
		return <Redirect to={`/edit-container/${removedContainerId}`} />

	if (location.state) {
		location.state.success
			? toast.success(location.state.msg)
			: toast.error(location.state.msg)
		location.state = '' //clear location
		toast.dismiss()
	}

	if (localStorage.getItem('bookingState') !== '') {
		toast.success(localStorage.getItem('bookingState'))
		localStorage.setItem('bookingState', '')
	}
	if (updateBookingError && updateBookingError.graphQLErrors)
		return alert(updateBookingError.graphQLErrors[0]?.message)

	return (
		<>
			{booking && (
				<>
					{isViewerOpen && (
						<ImageViewer
							src={loadImgs}
							disableScroll={true}
							currentIndex={currentImage}
							closeOnClickOutside={true}
							onClose={closeImageViewer}
							style={{ zIndex: -1 }}
						/>
					)}

					<Box
						className="mainZone"
						component="form"
						noValidate
						autoComplete="off"
					>
						<ToastContainer position="bottom-right" transition={Slide} />

						<div
							style={{
								width: '100%',
								height: '50px',
								backgroundColor: '#0c1836',
								marginBottom: '0px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1 style={{ color: '#fff' }}>Edit Booking {booking.number}</h1>
						</div>

						<Box
							sx={{ borderBottom: 1, borderColor: 'divider' }}
							style={{
								width: '100%',
							}}
						>
							<AppBar
								position="static"
								variant="fullWidth"
								style={{
									background: '#2F3E63',
									borderTop: '1px solid #fff',
									height: '30px !important',
								}}
							>
								<Tabs
									value={tabvalue}
									onChange={handleTabsChange}
									indicatorColor="primary"
									textColor="inherit"
									variant="fullWidth"
									style={{ height: '30px !important' }}
								>
									<Tab label="Information" {...a11yProps(0)} />
									<Tab label="Assigned containers" {...a11yProps(1)} />
									<Tab label="Communication notes" {...a11yProps(2)} disabled />
								</Tabs>
							</AppBar>
							<SwipeableViews
								axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
								index={tabvalue}
								onChangeIndex={handleChangeIndex}
							>
								{/* Booking Form */}
								<TabPanel value={tabvalue} index={0} variant="fullWidth">
									<nav className="actionBtnGroup">
										{booking.client.language === 'fr' ? (
											<div style={{ display: 'none' }}>
												<BookingTemplateFR id={id} ref={componentRef} />
											</div>
										) : (
											<div style={{ display: 'none' }}>
												<BookingTemplateEN id={id} ref={componentRef} />
											</div>
										)}

										<ButtonGroup variant="contained" color="primary">
											<Button
												type="submit"
												onClick={handleSubmit}
												style={{
													borderRadius: '0',
													borderRight: '1px solid #fff',
												}}
											>
												{' '}
												<SaveIcon /> &nbsp;Save
											</Button>
											<ReactToPrint
												documentTitle={'Booking No.' + booking.number}
												trigger={() => (
													<Button
														variant="contained"
														color="primary"
														style={{
															borderRadius: '0',
															borderRight: '1px solid #fff',
														}}
													>
														<PdfIcon /> &nbsp;Download
													</Button>
												)}
												content={() => componentRef.current}
											/>
											<Button
												component={Link}
												to={`/create-bill-of-lading/${id}`}
												style={{
													borderRadius: '0',
													borderRight: '1px solid #fff',
												}}
											>
												<NoteAddIcon /> Create Bill of lading
											</Button>
											<Tooltip
												title="Bill of lading's related list"
												placement="right"
												arrow
											>
												<Button
													component={Link}
													to={`/bill-of-lading-list?__booking=${booking.number}`}
												>
													<InsertLinkIcon />
												</Button>
											</Tooltip>
										</ButtonGroup>
									</nav>
									<FormGroup className="formGrp" row>
										<TextField
											label="Booking N°"
											variant="filled"
											size="small"
											margin="small"
											defaultValue={booking.number}
											disabled
										/>
										<TextField
											label="Created by"
											variant="filled"
											size="small"
											margin="small"
											defaultValue={booking.user.name}
											disabled
										/>
										<TextField
											type="date"
											label="Created at"
											disabled
											variant="filled"
											size="small"
											margin="small"
											InputLabelProps={{ shrink: true }}
											defaultValue={new Date(Number(booking.createdAt))
												.toISOString()
												.substring(0, 10)}
										/>
									</FormGroup>
									<FormGroup className="formGrp" row>
										{booking && booking.contract && (
											<div className="InputWithLink">
												<TextField
													label="Contract"
													name="contractId"
													defaultValue={booking.contract?.number}
													variant="filled"
													size="small"
													margin="small"
													disabled
												/>
												<Tooltip
													title={
														<table style={{ textAlign: 'left' }}>
															<tr>
																<th>Contract: </th>
																<td style={{ textTransform: 'capitalize' }}>
																	{booking.contract?.number}
																</td>
															</tr>
															<tr>
																<th>Start date: </th>
																<td>
																	{moment(
																		booking.contract?.startDate,
																		'x'
																	).format('DD-MM-YYYY')}
																</td>
															</tr>
															<tr>
																<th>End date: </th>
																<td>
																	{moment(
																		booking.contract?.endDate,
																		'x'
																	).format('DD-MM-YYYY')}
																</td>
															</tr>
														</table>
													}
												>
													<IconButton
														className="bookingLink"
														color="primary"
														component={Link}
														to={'/edit-contract/' + booking.contract?.id}
													>
														<InfoRoundedIcon />
													</IconButton>
												</Tooltip>
											</div>
										)}
										<TextField
											label="DOC Ref"
											variant="filled"
											size="small"
											margin="small"
											defaultValue={booking.documentRef}
											onChange={({ target: { value } }) => {
												setValues({ ...values, documentRef: value })
											}}
										/>
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="small"
										>
											<InputLabel>Status</InputLabel>
											<Select
												labelId="State"
												defaultValue={values.status || booking.status}
												//onChange={handleTestChange}
												onChange={({ target: { value } }) => {
													setValues({ ...values, status: value })
												}}
												disabled={booking && booking.status === 'Canceled'}
											>
												<MenuItem value="Active">Active</MenuItem>
												<MenuItem value="Completed">Completed</MenuItem>
												{!booking?.assignedContainers?.length && (
													<MenuItem value="Canceled">Canceled</MenuItem>
												)}
											</Select>
										</FormControl>
									</FormGroup>
									{/*Client section*/}
									<fieldset>
										<legend>Client</legend>
										<FormGroup className="formGrpClient" row>
											{allClients && (
												<Autocomplete
													disabled={booking.contract ? true : false}
													id="allClients"
													margin="dense"
													sx={{ width: 700 }}
													style={{ width: 700 }}
													defaultValue={
														allClients.find(
															({ id }) => id === values.clientId
														) || booking.client
													}
													options={
														allClients
															? allClients.filter(({ active }) => active)
															: []
													}
													getOptionLabel={(option) => option.company}
													filterOptions={filterOptions}
													renderOption={(props, option) => (
														<Box
															component="li"
															{...props}
															className="clientElemContainer"
														>
															<span className="contactSelectItemsCompany">
																<CompanyIcon />
																<p>{props.name}</p>
															</span>
															<span className="contactSelectItemsName">
																<EmployeeIcon />
																<p>{props.company}</p>
															</span>
														</Box>
													)}
													getOptionSelected={(option, value) =>
														option.company === value.company
													}
													onChange={(evesnt, value) => {
														setValues({ ...values, clientId: value.id })
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Client"
															variant="filled"
															size="small"
															margin="dense"
															helperText={
																booking.contract
																	? 'You cannot change client as the booking is linked to a contract'
																	: ''
															}
														/>
													)}
												/>
											)}
											<Tooltip
												title={
													<table style={{ textAlign: 'left' }}>
														<tr>
															<th>Name: </th>
															<td style={{ textTransform: 'capitalize' }}>
																{booking.client.name}
															</td>
														</tr>
														<tr>
															<th>Company: </th>
															<td style={{ textTransform: 'capitalize' }}>
																{booking.client.name}
															</td>
														</tr>
														<tr>
															<th>E-mail: </th>
															<td>{booking.client.email}</td>
														</tr>
														<tr>
															<th>Default language: </th>
															<td style={{ textTransform: 'uppercase' }}>
																{booking.client.language}
															</td>
														</tr>
														<tr>
															<th>Default currency: </th>
															<td style={{ textTransform: 'uppercase' }}>
																{booking.client.currency}
															</td>
														</tr>
													</table>
												}
											>
												<IconButton
													className="clientInfoIcon"
													color="primary"
													component={Link}
													to={'/edit-client/' + booking.client.id}
												>
													<InfoRoundedIcon />
												</IconButton>
											</Tooltip>
										</FormGroup>
									</fieldset>
									{/* Commodity section*/}
									<fieldset>
										<legend>Commodity</legend>
										<FormGroup className="formGrp" row>
											<Autocomplete
												disablePortal
												sx={{ width: 700 }}
												style={{ width: 700 }}
												margin="dense"
												defaultValue={
													allMarchandises.find(
														({ id }) => id === values.marchandiseId
													) || booking.marchandise
												}
												getOptionLabel={(option) =>
													`${option?.name?.toString()} - ${option?.price}`
												}
												options={
													allMarchandises
														? allMarchandises.filter(({ active }) => active)
														: []
												}
												onChange={(event, value) => {
													setValues({ ...values, marchandiseId: value.id })
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Commodity"
														variant="filled"
														size="small"
														margin="dense"
														helperText={`Price of Commodity when booking was created is ${booking?.marchandisePrice}`}
													/>
												)}
											/>
										</FormGroup>
									</fieldset>
									{/* Departure and arrival coordinates */}
									<fieldset>
										<legend>Departure and arrival coordinates</legend>
										{booking.voyage && (
											<>
												<span style={{ fontWeight: 'bold' }}>
													Current Selected Trip:
												</span>

												<section
													style={{
														width: '100%',
														display: 'flex',
														alignItems: 'center',
														padding: '20px',
														background: 'rgba(203, 203, 203, 0.8) ',
													}}
												>
													<div
														style={{
															display: 'flex',
															alignContent: 'center',
															marginRight: '50px',
															marginLeft: '20px',
														}}
													>
														No.
														<p
															style={{
																fontWeight: 'bold',
																textTransform: 'uppercase',
															}}
														>
															{booking.voyage?.number}
														</p>
													</div>
													<div
														style={{
															display: 'flex',
															alignContent: 'center',
															marginRight: '50px',
															marginLeft: '20px',
														}}
													>
														{'From '}&nbsp;
														<p
															style={{
																fontWeight: 'bold',
																textTransform: 'uppercase',
															}}
														>
															{booking.voyage?.loadingPort?.name}
														</p>
														&nbsp;
														{' To '}
														&nbsp;
														<p
															style={{
																fontWeight: 'bold',
																textTransform: 'uppercase',
															}}
														>
															{booking.voyage?.unLoadingPort?.name}
														</p>
													</div>
													<div
														style={{
															display: 'flex',
															alignContent: 'center',
															marginRight: '50px',
															marginLeft: '20px',
														}}
													>
														<EventIcon /> {''}&nbsp;
														{moment(
															new Date(Number(booking.voyage?.departureDate))
														).format('DD-MM-YYYY')}{' '}
														<ArrowForwardIosIcon />{' '}
														{moment(
															new Date(Number(booking.voyage?.arrivalDate))
														).format('DD-MM-YYYY')}
													</div>
													<div
														style={{
															display: 'flex',
															alignContent: 'center',
															marginRight: '50px',
														}}
													>
														<TimelapseIcon /> {''} &nbsp;
														{booking.voyage?.transitDays} days
													</div>
													<div
														style={{
															display: 'flex',
															alignContent: 'center',
															marginRight: '50px',
														}}
													>
														<DirectionsBoatFilledIcon /> {''} &nbsp;
														{booking.voyage?.ship?.name}
													</div>
												</section>
											</>
										)}
										<br />
										<section
											style={{ display: 'flex', flexDirection: 'column' }}
										>
											<span style={{ fontWeight: 'bold' }}>
												Select a new Trip:
											</span>
											<FormGroup className="formGrp" row>
												{allPorts && booking.voyage && (
													<Autocomplete
														options={
															booking?.contract
																? allPorts.filter(
																		({ id }) =>
																			id === booking.contract?.originPort.id
																  )
																: allPorts
														}
														filterOptions={filterOptions}
														onChange={(event, value) =>
															setVoyageValues({
																...voyageValues,
																loadingPortId: value?.id,
															})
														}
														defaultValue={
															allPorts.find(
																({ id }) => id === voyageValues.loadingPortId
															) || ''
														}
														getOptionLabel={(option) => option.name}
														renderOption={(props, option) => (
															<Box
																key={option.id}
																component="li"
																sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
																{...props}
															>
																&nbsp;&nbsp;{props.name}
															</Box>
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Location of departure"
																variant="filled"
																size="small"
																required
																margin="dense"
																inputProps={{
																	...params.inputProps,
																}}
															/>
														)}
													/>
												)}
												<DirectionsIcon
													style={{
														alignSelf: 'center',
														marginLeft: '0px',
														marginRight: '0px',
														paddingLeft: '0px',
													}}
												/>
												{allPorts && booking.voyage && (
													<Autocomplete
														onChange={(event, value) =>
															setVoyageValues({
																...voyageValues,
																unLoadingPortId: value?.id,
															})
														}
														filterOptions={filterOptions}
														defaultValue={
															allPorts.find(
																({ id }) => id === voyageValues.unLoadingPortId
															) || ''
														}
														options={
															booking?.contract
																? allPorts.filter(
																		({ id }) =>
																			id ===
																			booking.contract?.destinationPort.id
																  )
																: allPorts
														}
														getOptionLabel={(option) => option.name}
														renderOption={(props, option) => (
															<Box
																key={option.id}
																component="li"
																sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
																{...props}
															>
																&nbsp;&nbsp;{props.name}
															</Box>
														)}
														renderInput={(params) => {
															return (
																<TextField
																	{...params}
																	required
																	label="location of arrival"
																	variant="filled"
																	size="small"
																	margin="dense"
																	inputProps={{
																		...params.inputProps,
																	}}
																/>
															)
														}}
													/>
												)}
												<AccessTimeFilledIcon
													style={{
														alignSelf: 'center',
														marginLeft: '0px',
														marginRight: '0px',
														paddingLeft: '0px',
													}}
												/>
												<TextField
													label="Date"
													variant="filled"
													id="voyageDate"
													type="date"
													defaultValue={voyageValues.date}
													onChange={({ target: { value } }) => {
														setVoyageValues({ ...voyageValues, date: value })
													}}
													InputProps={{
														inputProps: {
															min: new Date().toISOString().slice(0, 10),
														},
													}}
													size="small"
													margin="dense"
													InputLabelProps={{ shrink: true }}
												/>
												<Button
													disabled={
														typeof voyageValues.loadingPortId === 'undefined' ||
														voyageValues.loadingPortId === '' ||
														typeof voyageValues.unLoadingPortId ===
															'undefined' ||
														voyageValues.unLoadingPortId === '' ||
														typeof voyageValues.date === 'undefined' ||
														voyageValues.date === ''
															? true
															: false
													}
													variant="contained"
													color="primary"
													onClick={handleFetchVoyages}
												>
													<PageviewIcon /> &nbsp;Search for a voyage
												</Button>
											</FormGroup>
											{voyageLoading && <Spinner />}
											{voyagesByDestinationAndDate && (
												<FormControl className="formGrp" row>
													<Box className="voyageListingContainer">
														<FormLabel fullWidth>
															{voyagesByDestinationAndDate.length !== 0 ? (
																'Select a voyage'
															) : (
																<span
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	{' '}
																	<ReportProblemIcon /> &nbsp;&nbsp; No result
																	found. Please adjust search filters.
																</span>
															)}
														</FormLabel>
														<RadioGroup
															fullWidth
															id="voyageListing"
															defaultValue={values.voyageId}
															onChange={({ target: { value } }) =>
																setValues({ ...values, voyageId: value })
															}
														>
															{voyagesByDestinationAndDate.map(
																(
																	{
																		number,
																		teu,
																		arrivalDate,
																		departureDate,
																		transit,
																		ship,
																		transitDays,
																		id,
																	},
																	i
																) => {
																	return (
																		<FormControlLabel
																			key={id}
																			className={
																				Number(i + 1) % 2 === 1
																					? 'voyageElemModulo'
																					: 'voyageElem'
																			}
																			value={id}
																			color="primary"
																			control={<Radio />}
																			label={
																				<span
																					style={{
																						display: 'flex',
																						alignContent: 'center',
																					}}
																				>
																					<div
																						style={{
																							display: 'flex',
																							alignContent: 'center',
																							marginRight: '50px',
																							marginLeft: '20px',
																						}}
																					>
																						<span
																							style={{ marginRight: '30px' }}
																						>
																							<span
																								style={{ fontWeight: 'bold' }}
																							>
																								No:{' '}
																							</span>
																							{number}
																						</span>
																						<EventIcon /> {''}&nbsp;
																						{moment(
																							new Date(Number(departureDate))
																						).format('DD-MM-YYYY')}{' '}
																						<ArrowForwardIosIcon />{' '}
																						{moment(
																							new Date(Number(arrivalDate))
																						).format('DD-MM-YYYY')}
																					</div>
																					<div
																						style={{
																							display: 'flex',
																							alignContent: 'center',
																							marginRight: '50px',
																						}}
																					>
																						<TimelapseIcon /> {''} &nbsp;
																						{transitDays} days
																					</div>
																					<div
																						style={{
																							display: 'flex',
																							alignContent: 'center',
																							marginRight: '50px',
																						}}
																					>
																						<DirectionsBoatFilledIcon /> {''}{' '}
																						&nbsp;
																						{ship.name}
																					</div>
																					{transit ? (
																						<div
																							style={{
																								display: 'flex',
																								alignContent: 'center',
																							}}
																						>
																							<AirlineStopsIcon /> {''} &nbsp;{' '}
																							{transit.name}
																							<span
																								style={{ marginLeft: '30px' }}
																							>
																								<span
																									style={{ fontWeight: 'bold' }}
																								>
																									Available Capacity:{' '}
																								</span>
																								{Number(ship.teu) - Number(teu)}
																							</span>
																						</div>
																					) : (
																						''
																					)}
																				</span>
																			}
																		/>
																	)
																}
															)}
														</RadioGroup>
													</Box>
												</FormControl>
											)}
										</section>
									</fieldset>
									{/* Container section */}
									<fieldset>
										<legend>Container</legend>
										<Box component={Paper} elevation={2}>
											<FormGroup className="formGrp" row>
												{depotsByPort && (
													<Autocomplete
														style={{ fontSize: '12px' }}
														filterOptions={filterOptions}
														getOptionLabel={(option) =>
															option?.name?.toUpperCase() +
															' - ' +
															option?.country?.toUpperCase()
														}
														onChange={(event, value) => setSelectedDepot(value)}
														options={depotsByPort}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Depot"
																variant="filled"
																size="small"
																margin="dense"
																required
															/>
														)}
													/>
												)}
												{selectedDepot && booking.contract ? (
													<Autocomplete
														onChange={(event, value) =>
															setSelectedContainerType(value)
														}
														getOptionLabel={(option) =>
															option?.containerType?.type
														}
														renderOption={(props, option) => (
															<Box component="li" {...props}>
																<Badge
																	badgeContent={option?.available}
																	color="primary"
																	sx={{
																		'& .MuiBadge-badge': {
																			fontSize: 9,
																			marginRight: -2,
																			marginTop: 1,
																		},
																	}}
																>
																	{option?.containerType?.type?.toString() +
																		'    '}
																</Badge>
															</Box>
														)}
														getOptionDisabled={(option) => {
															//Find if depot was added
															const findDepoIfItWasSelected =
																selectedContainersArray.find(
																	({ depot }) => depot.id === selectedDepot.id
																)
															//If depot was added
															if (
																findDepoIfItWasSelected &&
																findDepoIfItWasSelected.container.containerType
																	.id === option.containerType?.id &&
																option.available
															) {
																return (
																	option?.available -
																		findDepoIfItWasSelected.quantity ===
																	0
																)
															} else {
																return option?.available === 0
															}
														}}
														options={selectedDepot?.containersTypeAvailability?.filter(
															({ containerType }) =>
																booking.contract.containers.find(
																	({ containerType: contractContainerType }) =>
																		contractContainerType.id ===
																		containerType.id
																)
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Container type"
																name="containerType"
																variant="filled"
																size="small"
																margin="dense"
																required
															/>
														)}
													/>
												) : (
													selectedDepot &&
													!booking.contract && (
														<Autocomplete
															onChange={(event, value) =>
																setSelectedContainerType(value)
															}
															getOptionLabel={(option) =>
																option?.containerType?.type
															}
															renderOption={(props, option) => (
																<Box component="li" {...props}>
																	<Badge
																		badgeContent={props?.available}
																		color="primary"
																		sx={{
																			'& .MuiBadge-badge': {
																				fontSize: 9,
																				marginRight: -2,
																				marginTop: 1,
																			},
																		}}
																	>
																		{props?.containerType?.type?.toString() +
																			'    '}
																	</Badge>
																</Box>
															)}
															getOptionDisabled={(option) => {
																//Find if depot was added
																const findDepoIfItWasSelected =
																	selectedContainersArray.find(
																		({ depot }) => depot.id === selectedDepot.id
																	)
																//If depot was added
																if (
																	findDepoIfItWasSelected &&
																	findDepoIfItWasSelected.container
																		.containerType?.id ===
																		option.containerType?.id &&
																	option.available
																) {
																	return (
																		option?.available -
																			findDepoIfItWasSelected.quantity ===
																		0
																	)
																} else {
																	return option?.available === 0
																}
															}}
															options={selectedDepot.containersTypeAvailability}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Container type"
																	name="containerType"
																	variant="filled"
																	size="small"
																	margin="dense"
																	required
																/>
															)}
														/>
													)
												)}
												{selectedContainerType && (
													<>
														<FormControl
															size="small"
															margin="dense"
															style={{ width: '150px' }}
														>
															<InputLabel>Quantity</InputLabel>
															<Select
																value={quantity}
																label="Quantity"
																variant="filled"
																onChange={({ target: { value } }) => {
																	setQuantity(value)
																}}
															>
																{Array.from(
																	{
																		length: booking.contract
																			? selectedContainerType?.available <
																			  containerTypeInContractQuantity
																				? selectedContainerType?.available
																				: containerTypeInContractQuantity
																			: selectedContainerType?.available -
																			  (selectedContainersArray.find(
																					({ depot }) =>
																						depot.id === selectedDepot.id
																			  )?.quantity || 0),
																	},
																	(_, i) => i + 1
																).map((x) => (
																	<MenuItem key={x} value={x}>
																		{x}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
														<TextField
															label="Price"
															name="price"
															variant="filled"
															size="small"
															margin="dense"
															style={{ width: '150px' }}
															disabled
															value={selectedContainerPrice}
														/>
														<TextField
															label="Estimated weight"
															name="weight"
															type="number"
															variant="filled"
															size="small"
															margin="dense"
															style={{ width: '150px' }}
															onChange={({ target }) =>
																setEstimateWeight(Number(target.value))
															}
														/>
														<Button
															variant="contained"
															color="primary"
															size="small"
															margin="dense"
															disabled={quantity < 1}
															onClick={handleAddingContainerRow}
														>
															{' '}
															<AddCircleIcon />
															&nbsp;Add{' '}
														</Button>
													</>
												)}
											</FormGroup>
										</Box>
										<TableContainer component={Paper} elevation={2}>
											<Table sx={{ minWidth: 650 }}>
												<TableHead>
													<TableRow>
														<TableCell>Depot</TableCell>
														<TableCell>Container Type</TableCell>
														<TableCell>Quantity</TableCell>
														<TableCell>Price</TableCell>
														<TableCell>Weight</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{selectedContainersArray.length
														? selectedContainersArray.map(
																(
																	{
																		depot,
																		container,
																		quantity,
																		pricePerContainer,
																		estimateWeight,
																	},
																	i
																) => (
																	<TableRow
																		key={i}
																		style={{
																			display:
																				quantity === 0 ? 'none' : 'table-row',
																		}}
																	>
																		<TableCell>{depot?.name}</TableCell>
																		<TableCell>{container?.type}</TableCell>
																		<TableCell>{quantity}</TableCell>
																		<TableCell>{pricePerContainer}</TableCell>
																		<TableCell>{estimateWeight || 0}</TableCell>
																		<TableCell>
																			<Button
																				onClick={() =>
																					handleRemoveContainerRow(i)
																				}
																			>
																				<DeleteIcon></DeleteIcon>
																			</Button>
																		</TableCell>
																	</TableRow>
																)
														  )
														: null}
												</TableBody>
											</Table>
										</TableContainer>
									</fieldset>

									<fieldset className="containerFieldset">
										<legend>Fees</legend>
										<FormGroup className="formGrp" row>
											<TextField
												type="number"
												label="A"
												// inputProps={{ min: 0 }}
												variant="filled"
												size="small"
												width="600px"
												margin="dense"
												value={values.transport}
												onChange={({ target: { value } }) =>
													setValues({
														...values,
														transport: Number(value),
													})
												}
											/>
											<TextField
												type="number"
												label="B"
												inputProps={{ min: 0 }}
												variant="filled"
												size="small"
												width="600px"
												margin="dense"
												value={values.waiver}
												onChange={({ target: { value } }) => {
													setValues({
														...values,
														waiver: Number(value),
													})
												}}
											/>
											<TextField
												type="number"
												label="C"
												inputProps={{ min: 0 }}
												variant="filled"
												size="small"
												width="600px"
												margin="dense"
												value={values.thco}
												onChange={({ target: { value } }) => {
													setValues({
														...values,
														thco: Number(value),
													})
												}}
											/>
											<TextField
												type="number"
												label="D"
												inputProps={{ min: 0 }}
												variant="filled"
												size="small"
												width="600px"
												margin="dense"
												value={values.tsco}
												onChange={({ target: { value } }) => {
													setValues({
														...values,
														tsco: Number(value),
													})
												}}
											/>
										</FormGroup>
									</fieldset>
									<fieldset>
										<legend>Note</legend>
										<TextField
											label="Note"
											variant="filled"
											size="small"
											margin="small"
											defaultValue={values.note || booking.note}
											onChange={({ target: { value } }) => {
												setValues({ ...values, note: value })
											}}
										/>
									</fieldset>
									{/* Total */}
									<fieldset>
										<legend>Total</legend>
										<FormGroup className="formGrp" row>
											<TextField
												label="Total"
												variant="filled"
												size="small"
												margin="small"
												name="total"
												disabled
												defaultValue={totalContainersPrice}
											/>
										</FormGroup>
									</fieldset>

									{booking && booking.status === 'Canceled' ? (
										<span>
											This booking has been canceled, you can not edit it
											anymore
										</span>
									) : (
										<Button
											className="submitBtn"
											variant="contained"
											color="primary"
											onClick={handleSubmit}
										>
											{' '}
											<SaveIcon /> &nbsp;UPDATE{' '}
										</Button>
									)}
								</TabPanel>
								{/* AssignedContainers */}
								<TabPanel value={tabvalue} index={1} variant="fullWidth">
									<div style={{ width: '100% !important' }}>
										{booking.assignedContainers.map((elm, i) => {
											return (
												<Accordion key={i}>
													<AccordionSummary
														style={{ textAlign: 'left' }}
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<span
															style={{
																width: '80%',
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<p style={{ marginRight: '50px' }}>
																Container No.
															</p>
															<p
																style={{
																	fontWeight: 'bold',
																	marginRight: '50px',
																	width: '100px',
																}}
															>
																{elm?.container?.number}
															</p>

															<p
																style={{
																	marginRight: '50px',
																	fontWeight: 'bold',
																}}
															>
																{elm.container?.status?.status}
															</p>
															<p
																style={{
																	marginRight: '50px',
																	fontWeight: 'bold',
																}}
															>
																VGM: {elm.vgm} KG
															</p>
														</span>
													</AccordionSummary>
													<AccordionDetails style={{ textAlign: 'left' }}>
														<div className="containerDetailsContainer">
															<section className="containerTimeLine">
																<table className="assignedContainerList">
																	<tr>
																		<th colSpan={2}>
																			<h3>Details:</h3>
																		</th>
																	</tr>
																	<tr>
																		<td>{''}</td>
																		<td>{''}</td>
																	</tr>
																	<tr>
																		<td>Number:</td>
																		<th>
																			{elm?.container?.number}
																			<ContentCopyIcon
																				sx={{
																					'& :hover': { color: '#f7af3d' },
																				}}
																				style={{
																					fontSize: '16px',
																					marginLeft: '10px',
																					cursor: 'pointer',
																					transition: 'all .2s ease-in-out',
																				}}
																				onClick={(event) =>
																					navigator.clipboard
																						.writeText(elm?.container?.number)
																						.then(() => {
																							let popup =
																								document.createElement('div')
																							popup.style.position = 'fixed'
																							popup.style.top = `${
																								event.clientY - 10
																							}px`
																							popup.style.left = `${
																								event.clientX + 10
																							}px`
																							popup.innerHTML = 'Copied!'
																							document.body.appendChild(popup)
																							setTimeout(() => {
																								popup.parentNode.removeChild(
																									popup
																								)
																							}, 2000)
																						})
																				}
																			/>
																		</th>
																	</tr>
																	<tr>
																		<td>Type:</td>
																		<th>{elm.container?.type.type}</th>
																	</tr>

																	<tr>
																		<td>Mark:</td>
																		<th>{elm.container.mark}</th>
																	</tr>
																	<tr>
																		<td>ISO No:</td>
																		<th>{elm.container.isoNumber}</th>
																	</tr>
																	<tr>
																		<td>State:</td>
																		<th>{elm.container.status.status}</th>
																	</tr>
																	<tr>
																		<td>Voyage:</td>
																		<th>{elm.voyage.number}</th>
																	</tr>
																</table>
															</section>
															<section className="containerTimeLine">
																<table className="assignedContainerList">
																	<tr>
																		<th colSpan={2}>
																			<h3>Operated by:</h3>
																		</th>
																	</tr>
																	<tr>
																		<td>{''}</td>
																		<td>{''}</td>
																	</tr>
																	<tr>
																		<td>
																			<Tooltip title="Operator">
																				<OperatorIcons />
																			</Tooltip>
																		</td>
																		<td>
																			{elm?.operator?.name.replace(/^\w/, (c) =>
																				c.toUpperCase()
																			)}
																		</td>
																	</tr>

																	{elm?.container?.updatedBy && (
																		<tr>
																			<td>
																				<Tooltip title="Modified By">
																					<OperatorIcons />
																				</Tooltip>
																			</td>
																			<td>
																				{elm?.container?.updatedBy?.userName.replace(
																					/^\w/,
																					(c) => c.toUpperCase()
																				)}
																			</td>
																		</tr>
																	)}

																	<tr>
																		<td>
																			<Tooltip title="Last update">
																				<UpdateDateIcon />
																			</Tooltip>
																		</td>
																		<td>
																			{moment(elm.date).format(
																				'DD/MM/YYYY HH:MM'
																			)}
																		</td>
																	</tr>
																</table>
															</section>
															<section className="containerTimeLine">
																<div>
																	<h3
																		style={{
																			marginLeft: '30px',
																			marginBottom: '20px',
																		}}
																	>
																		Attachments:
																	</h3>
																	<table
																		style={{
																			marginLeft: '30px',
																			width: '100%',
																		}}
																	>
																		<tr>
																			{elm.firstPhoto !== '' ? (
																				<td
																					style={{
																						textAlign: 'center',
																					}}
																				>
																					<BurstModeIcon
																						className="AccordionContainerImgAction"
																						onClick={() =>
																							openImageViewer(0, [
																								elm.firstPhoto !== ''
																									? elm.firstPhoto
																									: null,
																							])
																						}
																					/>
																				</td>
																			) : (
																				''
																			)}
																		</tr>
																	</table>
																</div>
															</section>
															<section
																style={{
																	width: '300px',
																	display: 'flex',
																	flexDirection: 'row',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																<Button
																	variant="outlined"
																	startIcon={<CancelIcon />}
																	onClick={
																		() =>
																			handleRemoveContainerDialogOpen(
																				elm.id,
																				elm.container.id
																			)
																		// handleRemoveAssignedContainer(
																		// 	elm.id,
																		// 	elm.container.id
																		// )
																	}
																	style={{
																		color: 'red',
																	}}
																>
																	<Typography
																		variant="caption"
																		style={{ marginLeft: '10px' }}
																	>
																		Remove
																	</Typography>
																</Button>
															</section>
														</div>
													</AccordionDetails>
												</Accordion>
											)
										})}
										<Dialog
											open={openRemoveContainerDialog}
											onClose={handleRemoveContainerDialogClose}
										>
											<DialogTitle>{'Container removing'}</DialogTitle>
											<DialogContent>
												<DialogContentText>
													Are you sure to delete this container ?
												</DialogContentText>
											</DialogContent>
											<DialogActions>
												<Button
													onClick={handleRemoveContainerDialogClose}
													color="secondary"
												>
													Cancel
												</Button>
												<Button onClick={handleRemoveAssignedContainer}>
													Remove
												</Button>
											</DialogActions>
										</Dialog>
									</div>
									{removedAssignedContainersByBooking &&
										removedAssignedContainersByBooking.length > 0 && (
											<TableContainer
												component={Paper}
												style={{ marginTop: '50px' }}
											>
												<Paper
													style={{
														padding: '10px 0 10px 0',
														fontSize: '18px',
														fontWeight: 'bold',
														color: 'red',
													}}
												>
													Removed containers
												</Paper>
												<Table sx={{ minWidth: 650 }} aria-label="simple table">
													<TableHead>
														<TableRow>
															<TableCell>Container No.</TableCell>
															<TableCell>Type</TableCell>
															<TableCell align="right">Removed by</TableCell>
															<TableCell align="right">Removed At</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{removedAssignedContainersByBooking &&
															removedAssignedContainersByBooking.map((elm) => (
																<TableRow
																	key={elm.id}
																	sx={{
																		'&:last-child td, &:last-child th': {
																			border: 0,
																		},
																	}}
																>
																	<TableCell component="th" scope="row">
																		{elm.container.number}
																	</TableCell>
																	<TableCell component="th" scope="row">
																		{elm.container.type.type}
																	</TableCell>
																	<TableCell align="right">
																		{elm.createdBy.userName.replace(
																			/^\w/,
																			(c) => c.toUpperCase()
																		)}
																	</TableCell>
																	<TableCell align="right">
																		{moment(elm.createdAt, 'x').format(
																			'DD/MM/YY HH:MM'
																		)}
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										)}
								</TabPanel>
								{/* Communication notes */}
								<TabPanel value={tabvalue} index={2}>
									Item Three
								</TabPanel>
							</SwipeableViews>
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

export default EditBooking
