import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Spinner from '../../UI/Spinner'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import * as moment from 'moment'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_BOOKINGS = loader('./graphql/allBookings.graphql')

export default function BookingList() {
	const [sortModel, setSortModel] = React.useState([
		{
			field: 'updatedAt',
			sort: 'desc',
		},
	])

	const {
		data: { allBookings = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_BOOKINGS)

	useEffect(() => {
		refetch()
	}, [allBookings])

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			filterable: false,
			minWidth: 100,
			hide: true,
			flex: 1,
		},
		{
			field: 'actions',
			filterable: false,
			flex: 1,
			disableExport: true,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-booking/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},

		{
			field: 'createdAt',
			headerName: 'Created',
			minWidth: 120,
			hide: true,
			flex: 1,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
		{
			field: 'updatedAt',
			headerName: 'Updated',
			minWidth: 120,
			flex: 1,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},

		{
			field: 'number',
			headerName: 'Number',
			minWidth: 120,
			flex: 1,
			renderCell: (elm) => {
				return (
					<>
						<p>
							<strong>{elm.row.number}</strong>
						</p>
						<ContentCopyIcon
							sx={{
								'& :hover': { color: '#f7af3d' },
							}}
							style={{
								fontSize: '16px',
								marginLeft: '10px',
								cursor: 'pointer',
								transition: 'all .2s ease-in-out',
							}}
							onClick={(event) =>
								navigator.clipboard.writeText(elm.row?.number).then(() => {
									let popup = document.createElement('div')
									popup.style.position = 'fixed'
									popup.style.top = `${event.clientY - 10}px`
									popup.style.left = `${event.clientX + 10}px`
									popup.innerHTML = 'Copied!'
									document.body.appendChild(popup)
									setTimeout(() => {
										popup.parentNode.removeChild(popup)
									}, 2000)
								})
							}
						/>
					</>
				)
			},
		},
		{
			field: 'client',
			flex: 1,
			headerName: 'Company',
			minWidth: 130,
			valueGetter: ({ value }) => value?.company,
		},
		{
			field: 'voyage',
			headerName: 'Voyage',
			flex: 1,
			id: '13',
			minWidth: 120,
			valueGetter: ({ value }) => value?.number,
		},
		{
			field: 'voyage.loadingPort',
			flex: 1,
			headerName: 'From',
			minWidth: 130,
			valueGetter: (params) => params.row?.voyage?.loadingPort.name,
		},
		{
			field: 'voyage.unLoadingPort',
			flex: 1,
			headerName: 'To',
			minWidth: 130,
			valueGetter: (params) => params.row?.voyage?.unLoadingPort.name,
		},

		{
			field: 'transport',
			headerName: 'Transporter',
			minWidth: 100,
			hide: true,
			flex: 1,
		},

		// {
		// 	field: 'portOfLoading',
		// 	headerName: 'Port of loading',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'portOfUnLoading',
		// 	headerName: 'Port of unloading',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'dateOfLoading',
		// 	headerName: 'Date of loading',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'containerTerminal',
		// 	headerName: 'Container terminal',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'vgmClosing',
		// 	headerName: 'VGM Closing',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'docClosing',
		// 	headerName: 'DOC Closing',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'portCutOfDate',
		// 	headerName: 'Port Cut-Of Date',
		// 	minWidth: 100,
		// 	hide: true,
		// },
		// {
		// 	field: 'status',
		// 	headerName: 'State',
		// 	minWidth: 50,
		// },
	]

	if (loading) return <Spinner />

	return (
		<>
			{allBookings && (
				<DataGrid
					aria-label="Booking List"
					rows={allBookings}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
					autoHeight={true}
					disableSelectionOnClick
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
