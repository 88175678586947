import React, { useEffect } from 'react'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import * as moment from 'moment'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import ExcelIcon from './ExcelDownloader'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_VOYAGES = loader('./graphql/allVoyages.graphql')

export default function VoyagelList() {
	const {
		data: { allVoyages = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_VOYAGES)

	const [sortModel, setSortModel] = React.useState([
		{
			field: 'departureDate',
			sort: 'desc',
		},
	])

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			minWidth: 200,
			sortable: false,
			filtrable: false,
			disableExport: true,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<>
						<Link
							to={`/edit-voyage/${thisRow.id}`}
							style={{ margin: '0 auto' }}
							className="listEditBtn"
						>
							<EditIcon className="editBtnIcon" /> Edit
						</Link>
						<ExcelIcon id={thisRow.id} />
					</>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
		},
		{
			field: 'number',
			headerName: 'Voyage No.',
			minWidth: 120,
			flex: 1,
			renderCell: (elm) => {
				return (
					<>
						<p>
							<strong>{elm.row.number}</strong>
						</p>
						<ContentCopyIcon
							sx={{
								'& :hover': { color: '#f7af3d' },
							}}
							style={{
								fontSize: '16px',
								marginLeft: '10px',
								cursor: 'pointer',
								transition: 'all .2s ease-in-out',
							}}
							onClick={(event) =>
								navigator.clipboard.writeText(elm.row?.number).then(() => {
									let popup = document.createElement('div')
									popup.style.position = 'fixed'
									popup.style.top = `${event.clientY - 10}px`
									popup.style.left = `${event.clientX + 10}px`
									popup.innerHTML = 'Copied!'
									document.body.appendChild(popup)
									setTimeout(() => {
										popup.parentNode.removeChild(popup)
									}, 2000)
								})
							}
						/>
					</>
				)
			},
		},
		{
			field: 'ship',
			flex: 1,
			headerName: 'Ship name',
			minWidth: 200,
			valueGetter: ({ value }) => value?.name,
		},
		{
			field: 'loadingPort',
			flex: 1,
			headerName: 'Departure port',
			minWidth: 200,
			valueGetter: ({ value }) => value?.name,
		},
		{
			field: 'unLoadingPort',
			flex: 1,
			headerName: 'Arrival port',
			minWidth: 200,
			valueGetter: ({ value }) => value?.name,
		},
		{
			field: 'departureDate',
			headerName: 'Departure date',
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return moment(Number(row?.departureDate)).format('DD/MM/YYYY')
			},
		},
		{
			field: 'arrivalDate',
			headerName: 'Arrival date',
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return moment(Number(row?.arrivalDate)).format('DD/MM/YYYY')
			},
		},
		{
			field: 'teu',
			headerName: 'TEU',
			minWidth: 80,
			flex: 1,
		},
		{
			field: 'teu%',
			headerName: 'TEU %',
			minWidth: 90,
			align: 'center',
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Tooltip
							placement="left-start"
							style={{ cursor: 'help' }}
							title={'Ship max TEU : ' + Number(row.ship?.teu)}
						>
							<Box sx={{ position: 'relative', display: 'inline-flex' }}>
								<CircularProgress
									variant="determinate"
									value={Math.round(
										(100 * Number(row.teu)) / Number(row.ship?.teu)
									)}
								/>
								<Box
									sx={{
										top: 0,
										left: 0,
										bottom: 0,
										right: 0,
										position: 'absolute',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography variant="caption" component="div">
										{Math.round(
											(100 * Number(row.teu)) / Number(row.ship?.teu)
										)}
										{''}%
									</Typography>
								</Box>
							</Box>
						</Tooltip>
					</>
				)
			},
		},
		{
			field: 'weight',
			headerName: 'Weight (Kg)',
			minWidth: 110,
			flex: 1,
		},
		{
			field: 'weight%',
			headerName: 'Weight %',
			minWidth: 90,
			align: 'center',
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Tooltip
							placement="left-start"
							style={{ cursor: 'help' }}
							title={'Ship max weight : ' + Number(row.ship?.netTonage)}
						>
							<Box sx={{ position: 'relative', display: 'inline-flex' }}>
								<CircularProgress
									variant="determinate"
									value={Math.round(
										(100 * Number(row.weight)) / Number(row.ship?.netTonage)
									)}
								/>
								<Box
									sx={{
										top: 0,
										left: 0,
										bottom: 0,
										right: 0,
										position: 'absolute',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography variant="caption" component="div">
										{Math.round(
											(100 * Number(row.weight)) / Number(row.ship?.netTonage)
										)}
										{''}%
									</Typography>
								</Box>
							</Box>
						</Tooltip>
					</>
				)
			},
		},
	]

	if (localStorage.getItem('voyagetState') !== '') {
		toast.success(localStorage.getItem('voyagetState'))
	}

	if (localStorage.getItem('createVoyageState') !== '') {
		toast.success(localStorage.getItem('createVoyageState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('voyageState', '')
			localStorage.setItem('createVoyageState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allVoyages])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allVoyages && (
				<DataGrid
					rows={allVoyages}
					autoHeight={true}
					columns={columns}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
