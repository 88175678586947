import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import Modal from '@mui/material/Modal'
import { Link, Redirect } from 'react-router-dom'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import SaveIcon from '@material-ui/icons/Save'
import Ship from '@mui/icons-material/DirectionsBoatFilled'
import * as moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel'
import EventIcon from '@mui/icons-material/Event'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import PageviewIcon from '@mui/icons-material/Pageview'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import DirectionsIcon from '@mui/icons-material/Directions'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

// import EditIcon from '@material-ui/icons/BorderColor'
import '../../../asset/style.css'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
}

const ALL_VOYAGES = loader('./graphql/allVoyages.graphql')
const ALL_CONTAINERS_BY_VOYAGE = loader(
	'./graphql/allContainersByVoyage.graphql'
)
const ALL_STATUS = loader('./graphql/allStatus.graphql')
const UPDATE_BULK_CONTAINERS_STATUS = loader(
	'./graphql/updateBulkContainerStatus.graphql'
)

export default function ContainerBulkStatusUpdate() {
	const [selectionModel, setSelectionModel] = useState([])
	const [open, setOpen] = useState(false)
	const [voyageId, setVoyageId] = useState()
	const [statusId, setStatusId] = useState()

	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const [
		updateBulkContainerStatus,
		{
			data: updateBulkContainerStatusData = null,
			error: updateBulkContainerStatusError,
		},
	] = useMutation(UPDATE_BULK_CONTAINERS_STATUS)

	const [
		getVoyageContainers,
		{
			data: { allContainersByVoyage = null } = {},
			loading: containersByVoyageLoading,
		},
	] = useLazyQuery(ALL_CONTAINERS_BY_VOYAGE)

	const { data: { allStatus = null } = {}, loading } = useQuery(ALL_STATUS)

	const { data: { allVoyages = null } = {}, loading: voyageLoading } =
		useQuery(ALL_VOYAGES)

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					onClick={handleOpen}
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<ModeOfTravelIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Bulk update status
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			disableFilter: true,
		},
		{
			field: 'container.number',
			flex: 1,
			headerName: 'Container No.',
			minWidth: 130,
			valueGetter: (params) => params.row.number,
		},
		{
			field: 'container.type',
			flex: 1,
			headerName: 'Container type',
			minWidth: 130,
			valueGetter: (params) => params.row.type.type,
		},
		{
			field: 'container.status',
			flex: 1,
			headerName: 'Container No.',
			minWidth: 130,
			valueGetter: (params) => params.row.status.status.toUpperCase(),
		},
	]

	if (localStorage.getItem('containerState') !== '') {
		toast.success(localStorage.getItem('containerState'))
	}

	if (localStorage.getItem('CreateContainerState') !== '') {
		toast.success(localStorage.getItem('CreateContainerState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('containerState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('CreateContainerState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	const handleUpdateContainersStatus = () => {
		return updateBulkContainerStatus({
			variables: {
				input: {
					containersIds: selectionModel,
					statusId,
				},
			},
		})
	}

	useEffect(() => {
		getVoyageContainers({
			variables: {
				voyageId,
			},
		})
	}, [voyageId])

	if (loading) return <Spinner />
	if (updateBulkContainerStatusData) return <Redirect to="/container-list/" />

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: 'inherit' }}>
			{allVoyages && (
				<Autocomplete
					options={allVoyages}
					filterOptions={filterOptions}
					onChange={(event, value) => setVoyageId(value.id)}
					getOptionLabel={(option) => option.number}
					renderOption={(props, option) => (
						<Box
							key={option.id}
							component="li"
							sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
							{...props}
						>
							&nbsp;&nbsp;{option.number}
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Voyages"
							variant="filled"
							size="small"
							required
							margin="dense"
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password', // disable autocomplete and autofill
							}}
						/>
					)}
				/>
			)}
			<ToastContainer position="bottom-right" transition={Slide} />
			{containersByVoyageLoading ? (
				<Spinner />
			) : (
				<DataGrid
					checkboxSelection
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel)
					}}
					selectionModel={selectionModel}
					rows={allContainersByVoyage}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}

			<Modal open={open} onClose={handleClose}>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Select a status
					</Typography>
					{selectionModel.length !== 0 ? (
						<>
							<FormGroup className="formGrp" row>
								{allStatus && (
									<Autocomplete
										onChange={(event, value) => setStatusId(value.id)}
										filterOptions={filterOptions}
										options={allStatus}
										getOptionLabel={(option) => option.status}
										renderOption={(props, option) => (
											<Box
												key={option.id}
												component="li"
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}
											>
												&nbsp;&nbsp;{option.status}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												required
												label="Status"
												variant="filled"
												size="small"
												margin="dense"
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
									/>
								)}
							</FormGroup>
							<Button
								style={{ display: 'block' }}
								disabled={!statusId}
								className="submitBtn"
								variant="contained"
								color="primary"
								onClick={handleUpdateContainersStatus}
							>
								<SaveIcon /> UPDATE
							</Button>
						</>
					) : (
						<section
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<WarningAmberIcon style={{ fontSize: '40' }} />
							<p style={{ fontSize: '30' }}>
								Please select at least one container
							</p>
						</section>
					)}
				</Box>
			</Modal>
		</div>
	)
}
