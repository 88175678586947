import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import { ToastContainer, toast, Slide } from 'react-toastify'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormGroup from '@material-ui/core/FormGroup'

import SaveIcon from '@material-ui/icons/Save'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

// import '../../../asset/style.css'
const CREATE_CONTAINER = loader('./graphql/createContainer.graphql')
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')
const ALL_DEPOTS = loader('./graphql/allDepots.graphql')

const CreateContainer = () => {
	const [typeId, setTypeId] = useState()
	const [depotId, setDepotId] = useState()

	const [createContainer, { data, error, loading }] =
		useMutation(CREATE_CONTAINER)

	const { data: { allDepots = null } = {}, loading: depotsLoading } =
		useQuery(ALL_DEPOTS)

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const {
		data: { allContainerTypes = null } = {},
		loading: containerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			mark,
			containerNumber,
			iso,
			tar,
			maxWeight,
			purchasePrice,
			manufactureDate,
			datePurchase,
			note,
		} = e.target
		if (!typeId)
			return toast.error('Please choose Container Type first then Submit !')
		return createContainer({
			variables: {
				input: {
					number: containerNumber.value,
					mark: mark.value,
					tar: tar.value,
					maxWeight: maxWeight.value,
					isoNumber: iso.value,
					saleDate: datePurchase.value,
					salePrice: purchasePrice.value,
					madeDate: manufactureDate.value,
					typeId,
					depotId,
					note: note.value,
				},
			},
		})
	}

	if (data && data.createContainer && !error) {
		localStorage.setItem(
			'createContainerState',
			'✔️ Container created successfully ! '
		)
		return <Redirect to="/container-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>{error.message}</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	if (loading || containerTypeLoading || depotsLoading) return <Spinner />

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Container</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Mark"
							name="mark"
							variant="filled"
							size="small"
							margin="normal"
						/>
						<TextField
							label="Number No."
							name="containerNumber"
							variant="filled"
							size="small"
							margin="normal"
						/>

						<TextField
							label="ISO No."
							name="iso"
							variant="filled"
							size="small"
							margin="normal"
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Empty weight (TAR)"
							name="tar"
							variant="filled"
							size="small"
							margin="normal"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">Kg</InputAdornment>
								),
							}}
						/>
						<TextField
							label="Max Weight"
							name="maxWeight"
							variant="filled"
							size="small"
							margin="normal"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">Kg</InputAdornment>
								),
							}}
						/>
						<Autocomplete
							fullWidth
							onChange={(event, value) => setTypeId(value.id)}
							getOptionLabel={(option) => option?.type?.toString()}
							options={allContainerTypes}
							sx={{ width: 230 }}
							style={{ alignSelf: 'center' }}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Container type"
									name="containerType"
									variant="filled"
									size="small"
									margin="small"
									required
								/>
							)}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Purchase price"
							name="purchasePrice"
							variant="filled"
							size="small"
							margin="normal"
						/>
						<TextField
							label="Date of manufacture"
							name="manufactureDate"
							InputLabelProps={{ shrink: true }}
							type="date"
							variant="filled"
							size="small"
							margin="normal"
						/>
						<TextField
							label="date of purchase"
							name="datePurchase"
							InputLabelProps={{ shrink: true }}
							type="date"
							variant="filled"
							size="small"
							margin="normal"
						/>{' '}
						<TextField
							label="Note"
							name="note"
							InputLabelProps={{ shrink: true }}
							variant="filled"
							size="small"
							margin="normal"
						/>
					</FormGroup>
					{allDepots && (
						<Autocomplete
							filterOptions={filterOptions}
							style={{ width: '40%' }}
							sx={{ width: '40% !important' }}
							getOptionLabel={(option) =>
								option?.name?.toUpperCase() +
								' - ' +
								option?.city?.toUpperCase() +
								'/' +
								option?.country?.toUpperCase()
							}
							onChange={(event, value) => setDepotId(value.id)}
							options={allDepots}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Depot"
									variant="filled"
									size="small"
									margin="normal"
								/>
							)}
						/>
					)}
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateContainer
