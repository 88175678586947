import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_BANKS = loader('./graphql/allBanks.graphql')

export default function ContactList() {
	const {
		data: { allBanks = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_BANKS)

	const columns = [
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-bank/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			filterable: false,
			hide: true,
			flex: 1,
		},
		{ field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
		{ field: 'IBAN', headerName: 'IBAN', minWidth: 150, flex: 1 },
		{ field: 'BIC', headerName: 'BIC', minWidth: 200, flex: 1 },
		{
			field: 'cardHolderName',
			headerName: 'Card Holder',
			minWidth: 200,
			flex: 1,
		},
		{ field: 'location', headerName: 'Location', minWidth: 100, flex: 1 },
		{
			field: 'currency',
			headerName: 'Currency',
			minWidth: 100,
			flex: 1,
			valueGetter: ({ value }) => value.currency,
		},
	]

	if (localStorage.getItem('bankState') !== '') {
		toast.success(localStorage.getItem('bankState'))
	}

	if (localStorage.getItem('createBankState') !== '') {
		toast.success(localStorage.getItem('createBankState'))
	}

	useEffect(() => {
		// Clear the local storage
		const timer = setTimeout(() => {
			localStorage.setItem('bankState', '')
			localStorage.setItem('createBankState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allBanks])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allBanks && (
				<DataGrid
					rows={allBanks}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
