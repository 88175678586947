import React, { Component } from 'react'
import BookingTemplateEN from './BookingTemplateEN'

class Print extends Component {
	render() {
		return (
			<>
				<BookingTemplateEN id={this.props.id} />
			</>
		)
	}
}

export default Print
