import React, { useEffect } from 'react'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import CheckIcon from '@mui/icons-material/Check'

import Stack from '@mui/material/Stack'
import { Button } from '@material-ui/core'

import { toast } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import EditIcon from '@material-ui/icons/BorderColor'

import '../../../asset/style.css'

const USERS = loader('./graphql/users.graphql')

export default function UsersList() {
	const { data: { users = null } = {}, loading, refetch } = useQuery(USERS)

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					component={Link}
					to="/create-user/"
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<AddBoxIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Add new user
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-user/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			filterable: false,
			minWidth: 100,
			hide: true,
			flex: 1,
		},
		{ field: 'userName', headerName: 'Username', minWidth: 100, flex: 1 },
		{ field: 'name', headerName: 'Name', minWidth: 100, flex: 1 },

		{
			field: 'roles',
			flex: 1,
			headerName: 'Role',
			minWidth: 100,
			valueGetter: ({ value }) => value[0]?.role.toUpperCase(),
		},
		{
			field: 'port',
			flex: 1,
			headerName: 'Port',
			minWidth: 100,
			valueGetter: ({ value }) => value?.name,
		},
		{
			field: 'archived',
			flex: 1,
			headerName: 'Archived',
			minWidth: 100,
			valueGetter: ({ value }) => value,
			renderCell: ({ value }) => (value ? <CheckIcon /> : <span>-</span>),
		},
	]

	if (localStorage.getItem('userState') !== '') {
		toast.success(localStorage.getItem('userState'))
	}

	if (localStorage.getItem('createUserState') !== '') {
		toast.success(localStorage.getItem('createUserState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('userState', '')
			localStorage.setItem('createUserState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [users])

	if (loading) return <Spinner />

	return (
		<>
			{users && (
				<>
					<DataGrid
						rows={users.filter(({ userName }) => userName !== 'htag')}
						autoHeight={true}
						columns={columns}
						components={{
							Toolbar: CustomToolbar,
							NoResultsOverlay: () => {
								return (
									<Stack
										style={{ marginTop: '600px !important' }}
										alignItems="center"
										justifyContent="center"
									>
										<FilterListOffIcon />
										<p>Filter returns no result</p>
									</Stack>
								)
							},
							NoRowsOverlay: () => {
								return (
									<Stack
										style={{ marginTop: '600px !important' }}
										alignItems="center"
										justifyContent="center"
									>
										<FilterListOffIcon />
										<p>No Rows</p>
									</Stack>
								)
							},
						}}
					/>
					{/* <Tooltip title="Create new user" placement="left-center" arrow>
						<Fab
							color="primary"
							aria-label="add"
							style={{
								zIndex: '99',
								position: 'absolute',
								top: '90px',
								right: '10px',
							}}
							component={Link}
							to="/create-user/"
						>
							<AddIcon />
						</Fab>
					</Tooltip> */}
				</>
			)}
		</>
	)
}
