import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import IconButton from '@material-ui/core/IconButton'
import FilledInput from '@material-ui/core/FilledInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import '../../../asset/style.css'

const CREATE_USER = loader('./graphql/createUser.graphql')
const ALL_ROLES = loader('./graphql/allRoles.graphql')
const ALL_PORTS = loader('../CreateBooking/graphql/allPorts.graphql')

const CreateUser = () => {
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})
	const [values, setValues] = useState({
		showPassword: false,
	})

	const [selectedRole, setSelectedRole] = useState()
	const [selectedPort, setSelectedPort] = useState()

	const [createUser, { data, error, loading }] = useMutation(CREATE_USER)
	const { data: { allRoles = null } = {}, loading: rolesLoading } =
		useQuery(ALL_ROLES)
	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, username, password, role } = e.target
		return createUser({
			variables: {
				input: {
					name: name.value,
					userName: username.value,
					password: password.value,
					roleId: role.value,
					portId: selectedPort,
				},
			},
		})
	}

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value })
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	if (loading || rolesLoading || portsLoading) return <Spinner />

	if (data && !error) {
		localStorage.setItem('createUserState', '✔️ User created successfully ! ')
		return <Redirect to="/users" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create User</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Name"
							name="name"
							variant="filled"
							size="small"
							margin="normal"
						/>
						<TextField
							label="Username"
							name="username"
							variant="filled"
							size="small"
							margin="normal"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="normal"
						>
							<InputLabel>Roles</InputLabel>

							{allRoles && (
								<Select
									labelId="Roles"
									name="role"
									defaultValue={allRoles[0].role}
									onChange={({ target: { value } }) => {
										setSelectedRole(allRoles.find(({ id }) => id === value))
									}}
								>
									{allRoles.map(({ role, id }) => {
										return (
											<MenuItem key={id} value={id}>
												{role.toUpperCase()}
											</MenuItem>
										)
									})}
								</Select>
							)}
						</FormControl>
						{selectedRole &&
							(selectedRole.role === 'operator' ||
								selectedRole.role === 'employee') && (
								<FormControl variant="filled">
									{allPorts && (
										<Autocomplete
											options={allPorts}
											filterOptions={filterOptions}
											onChange={(event, value) => setSelectedPort(value?.id)}
											getOptionLabel={(option) => option.name}
											renderOption={(props, option) => (
												<Box
													key={option.id}
													component="li"
													sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
													{...props}
												>
													&nbsp;&nbsp;{option.name}
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Port"
													variant="filled"
													size="small"
													required
													margin="dense"
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
									)}
								</FormControl>
							)}
						<FormControl variant="filled">
							<InputLabel htmlFor="filled-adornment-password">
								Password
							</InputLabel>
							<FilledInput
								name="password"
								id="filled-adornment-password"
								type={values.showPassword ? 'text' : 'password'}
								value={values.password}
								onChange={handleChange('password')}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</FormGroup>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateUser
