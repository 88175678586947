import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const MARCHANDISE = loader('./graphql/marchandise.graphql')
const UPDATE_MARCHANDISE = loader('./graphql/updateMarchandise.graphql')

const EditCommodity = () => {
	const params = useParams() //get the Booking ID

	const { data: { marchandise = {} } = {}, loading: marchandiseLoading } =
		useQuery(MARCHANDISE, {
			variables: { id: params.id },
		})

	const [updateMarchandise, { data, error, loading }] =
		useMutation(UPDATE_MARCHANDISE)

	const { id } = params

	useEffect(() => {
		if (data) {
			localStorage.setItem(
				'commodityState',
				'✔️ Commodity updated successfully ! '
			)
			window.location.reload()
		}
	}, [data])

	if (marchandiseLoading || loading)
		return <Spinner style={{ marginTop: '400px' }} />

	if (!useCurrentUser) return <Redirect to="/" />

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, active, price } = e.target
		return updateMarchandise({
			variables: {
				input: {
					id,
					name: name.value,
					price: Number(price.value),
					active: active.value === 'true' ? true : false,
				},
			},
		})
	}

	return (
		<>
			{error ? (
				<p>{error.message}</p>
			) : (
				marchandise && (
					<>
						<Box
							className="mainZone"
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div
								style={{
									width: '100%',
									height: '50px',
									backgroundColor: '#0c1836',
									marginBottom: '10px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h1 style={{ color: '#fff' }}>Edit Commodity</h1>
							</div>

							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										defaultValue={marchandise.name}
										label="Name"
										name="name"
										variant="filled"
										size="small"
										margin="normal"
									/>
									<TextField
										defaultValue={marchandise.price}
										label="Price"
										name="price"
										variant="filled"
										size="small"
										margin="normal"
										type="number"
										InputProps={{
											inputProps: { min: 0 },
										}}
										onWheel={(e) => e.target.blur()}
									/>
									<FormControl
										className="formSelect"
										variant="filled"
										size="small"
										margin="normal"
									>
										<InputLabel>Visibility</InputLabel>
										<Select
											labelId="active"
											name="active"
											defaultValue={marchandise.active}
										>
											<MenuItem value={true}>Active</MenuItem>
											<MenuItem value={false}>Not active</MenuItem>
										</Select>
									</FormControl>
								</FormGroup>

								<Button
									className="submitBtn"
									type="submit"
									variant="contained"
									color="primary"
								>
									{' '}
									<SaveIcon />
									&nbsp;UPDATE{' '}
								</Button>
							</fieldset>
						</Box>
					</>
				)
			)}
		</>
	)
}

export default EditCommodity
