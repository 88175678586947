import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useParams } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import Box from '@material-ui/core/Box'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import AddCardIcon from '@mui/icons-material/AddCard'

import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'

import '../../../asset/style.css'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
}

const ALL_BANKS = loader('./graphql/allBanks.graphql')
const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')
const INVOICE_BY_NUMBER = loader('./graphql/invoiceByNumber.graphql')
const EDIT_INVOICE = loader('./graphql/updateInvoiceStatusBankCash.graphql')

const EditInvoice = () => {
	const { number: invoiceNumber } = useParams()
	const [invoiceStatus, setInvoiceStatus] = useState('')
	const [bankId, setBankId] = useState('')
	const [currencyId, setCurrencyId] = useState('')

	const [cash, setCash] = useState()
	const [open, setOpen] = useState(false)

	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const { data: { allCurrencies = null } = {}, loading: allCurrenciesLoading } =
		useQuery(ALL_CURRENCIES)

	const { data: { invoiceByNumber = null } = {}, loading } = useQuery(
		INVOICE_BY_NUMBER,
		{
			variables: { invoiceNumber },
		}
	)
	const { data: { allBanks = null } = {}, loading: banksLoading } =
		useQuery(ALL_BANKS)

	const [
		updateInvoiceStatusBankCash,
		{
			data: invoiceData = null,
			error: updateBookingError,
			loading: invoiceLoading,
		},
	] = useMutation(EDIT_INVOICE)

	const handleSubmit = () => {
		updateInvoiceStatusBankCash({
			variables: {
				input: {
					id: invoiceByNumber.id,
					cash,
					bankId: cash ? null : bankId,
					status: invoiceStatus,
				},
			},
		})
	}

	useEffect(() => {
		if (invoiceData?.updateInvoiceStatusBankCash) {
			window.location.reload()
		}
	}, [invoiceData])

	return (
		<>
			{invoiceByNumber && (
				<>
					<Box
						className="mainZone"
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div
							style={{
								width: '100%',
								height: '50px',
								backgroundColor: '#0c1836',
								marginBottom: '10px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1 style={{ color: '#fff' }}>
								Edit Invoice - {invoiceByNumber.booking.number}
							</h1>
						</div>
						<ButtonGroup
							className="actionBtnGroup"
							variant="contained"
							color="primary"
						>
							<Button
								onClick={() => handleOpen()}
								style={{
									borderRadius: '0 !important',
									borderRight: '1px solid #fff',
								}}
							>
								{' '}
								<AddCardIcon /> &nbsp;Register payement
							</Button>
						</ButtonGroup>
						<fieldset>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<b>Invoice Number</b>
										</TableCell>
										<TableCell>
											<b>Credit Item</b>
										</TableCell>
										<TableCell>
											<b>Billed</b>
										</TableCell>
										<TableCell>
											<b>currency</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody hover>
									{invoiceByNumber.credits
										.filter(({ billed }) => billed > 0)
										.map(({ creditItem, billed, currency }) => (
											<TableRow>
												<TableCell>{invoiceNumber}</TableCell>
												<TableCell>{creditItem.item}</TableCell>
												<TableCell>{billed}</TableCell>
												<TableCell>{currency.currency}</TableCell>
											</TableRow>
										))}
									<TableRow>
										<TableCell align="right" colSpan={3}>
											<b>Total</b>
										</TableCell>
										<TableCell
											align="right"
											colSpan={3}
											style={{ fontSize: '20px' }}
										>
											<b>{`${invoiceByNumber.amount.toLocaleString('fr-FR', {
												style: 'decimal',
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})} ${invoiceByNumber.currency.currency}`}</b>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="right" colSpan={3}>
											Paid on 01/01/2023
										</TableCell>
										<TableCell
											align="right"
											colSpan={3}
											style={{ fontSize: '18px' }}
										>
											{`${invoiceByNumber.amount} ${invoiceByNumber.currency.currency}`}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</fieldset>
					</Box>
					<Modal open={open} onClose={handleClose}>
						<Box sx={style}>
							<Typography id="modal-modal-title" variant="h6" component="h2">
								Register a payement
							</Typography>
							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										label="Amount"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										InputProps={{
											inputProps: { min: 0 },
										}}
									/>
									{allCurrencies && (
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="dense"
										>
											<InputLabel>Currency</InputLabel>
											<Select
												labelId="Bank"
												onChange={({ target: { value } }) => {
													setCurrencyId(value)
												}}
											>
												{allCurrencies.map(({ id, symbol, currency }) => (
													<MenuItem
														value={id}
													>{`${currency} ${symbol} `}</MenuItem>
												))}
											</Select>
										</FormControl>
									)}

									<FormControlLabel
										control={
											<Checkbox
												name="cash"
												color="primary"
												defaultChecked={invoiceByNumber.cash}
												onClick={({ target: { checked } }) => {
													if (checked) {
														setCash(true)
													} else {
														setCash(false)
													}
												}}
											/>
										}
										label="Cash"
									/>

									{allBanks && (
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="dense"
										>
											<InputLabel>Bank</InputLabel>
											<Select
												disabled={cash}
												defaultValue={invoiceByNumber?.bank?.id}
												labelId="Bank"
												onChange={({ target: { value } }) => {
													setBankId(value)
												}}
											>
												{allBanks.map(({ id, name, IBAN }) => (
													<MenuItem value={id}>{`${name} ${IBAN}`}</MenuItem>
												))}
											</Select>
										</FormControl>
									)}

									<TextField
										label="Payement date"
										type="date"
										InputLabelProps={{ shrink: true }}
										variant="filled"
										size="small"
										margin="dense"
									/>
								</FormGroup>
								<FormGroup className="formGrp" row>
									<Button
										style={{ display: 'block' }}
										className="submitBtn"
										variant="contained"
										color="primary"
									>
										<SaveIcon /> Submit
									</Button>
								</FormGroup>
							</fieldset>
						</Box>
					</Modal>
				</>
			)}
		</>
	)
}

export default EditInvoice
