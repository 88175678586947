import * as React from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Moment from 'react-moment'

import ConditionsPages from './ConditionPages'

import * as moment from 'moment'

import './style.css'

import Logo from './asset/img/logo.png'
import Qr from './asset/img/asaslineQR.png'

const BOOKING = loader('./graphql/booking.graphql')

const BookingTemplateEN = ({ id }) => {
	let cpt = 0
	let containerTypeCpt = 0

	const pricesArr = [
		{ name: 'priceTransportMaritime', label: 'Ocean Freight' },
		{ name: 'transport', label: 'Tranport' },
		{ name: 'waiver', label: 'Waiver' },
		{ name: 'thco', label: 'Terminal Handling Charge Orig.' },
		{ name: 'tsco', label: 'Terminal Security Charge Orig.' },
		{ name: 'carrierSecurityFee', label: 'Marine Fuel Recovery' },
		{ name: 'marineFuelRecovery', label: 'Carrier Security Fee' },
		{ name: 'thcd', label: 'Terminal Handling Charge Dest.' },
		{ name: 'ose', label: 'Outward Service Expenses' },
		{ name: 'surveyExpenses', label: 'Survey Expenses' },
		{ name: 'document', label: 'Documentation' },
		{
			name: 'terminalSecurityDestination',
			label: 'Terminal Security Destination',
		},
		{ name: 'clearanceExportCustom', label: 'Clearance export costume' },
	]

	const {
		data: { booking = null } = {},
		loading,
		refetch,
	} = useQuery(BOOKING, {
		variables: {
			id,
		},
	})

	return (
		<>
			{booking && (
				<>
					<table className="bookingPage">
						<tr>
							<table className="bookingHeader">
								<tr>
									<th style={{ width: '30%' }}>
										<img src={Logo} className="logo" />
									</th>
									<th style={{ width: '40%' }}>
										<h1>Booking Confirmation</h1>
									</th>
									<th style={{ width: '30%' }}>
										<img src={Qr} className="qrCode" />
									</th>
								</tr>
							</table>
							<table className="bookingInfo">
								<tr>
									<td className="bookingInfoLeft">
										<tr>
											<td>
												<h3>ASASLINE S.A.</h3>
											</td>
										</tr>
										<tr>
											<td>Rue de Douvres 115</td>
										</tr>
										<tr>
											<td>1070 Bruxelles - Belgiqie</td>
										</tr>
										<tr>
											<td>(+32) 02 3 15 14 15 </td>
										</tr>
										<tr>
											<td>contact@asasline.com</td>
										</tr>
										<tr>
											<td>www.asasline.com</td>
										</tr>
										<tr>
											<td>TVA:BE0772 649 540</td>
										</tr>
										<tr>
											<td></td>
										</tr>
									</td>
									<td className="bookingInfoRight">
										<tr>
											<td>
												<b>BOOING N°:</b> {booking.number}
											</td>
										</tr>
										<tr>
											<td>
												<b>
													DATE:{' '}
													<Moment format="DD/MM/YYYY">
														{Date(booking.createdAt)}
													</Moment>
												</b>
											</td>
										</tr>
										<tr>
											<td>
												<b>DOC REF: {booking.documentRef}</b>
											</td>
										</tr>
										<tr>
											<td></td>
										</tr>
										<tr>
											<td></td>
										</tr>
										<tr>
											<td></td>
										</tr>
										<tr>
											<td>Company: {booking.client.company}</td>
										</tr>
										<tr>
											<td>Address: {booking.client.address || ''}</td>
										</tr>
										<tr>
											<td>City: {booking.client?.city || ''}</td>
										</tr>
										<tr>
											<td>E-mail: {booking.client.email}</td>
										</tr>
									</td>
								</tr>
							</table>
						</tr>
						<tr>
							<h6>Transport details</h6>
							{booking.voyage && (
								<table className="bookingDetails">
									<tr>
										<th>Commodity:</th>
										<td>{booking.marchandise?.name}</td>
									</tr>
									<tr className="darkLine">
										<th>Loading port:</th>
										<td>{booking.voyage.loadingPort?.name}</td>
									</tr>
									<tr>
										<th>Unloading Port:</th>
										<td>{booking.voyage.unLoadingPort?.name}</td>
									</tr>
									<tr className="darkLine">
										<th>Departure country:</th>
										<td>{booking.voyage.loadingPort.country}</td>
									</tr>
									<tr>
										<th>Arrival country:</th>
										<td>{booking.voyage.unLoadingPort.country}</td>
									</tr>
									<tr className="darkLine">
										<th>Shipowner:</th>
										<td>{booking.voyage?.ship?.owner}</td>
									</tr>
									<tr>
										<th>Ship / Voyage:</th>
										<td>
											{booking?.voyage?.ship?.name} / {booking?.voyage?.number}
										</td>
									</tr>
									<tr className="darkLine">
										<th>Container Terminal:</th>
										<td>{booking.voyage?.containerTerminal?.name}</td>
									</tr>
									<tr>
										<th>VGM CLOSING:</th>
										<td>
											{booking.voyage?.vgmClosing !== '' ? (
												<Moment format="DD/MM/YYYY hh:mm:ss">
													{booking.voyage?.vgmClosing}
												</Moment>
											) : (
												' - '
											)}
										</td>
									</tr>
									<tr className="darkLine">
										<th>DOC CLOSING:</th>
										<td>
											{booking?.voyage?.docClosing !== '' ? (
												<Moment format="DD/MM/YYYY hh:mm:ss">
													{booking?.voyage?.docClosing}
												</Moment>
											) : (
												' - '
											)}
										</td>
									</tr>
									<tr>
										<th>Port Cut-Off Date/Time:</th>
										<td>
											{booking?.voyage?.portCutoff !== '' ? (
												<Moment format="DD/MM/YYYY hh:mm:ss">
													{booking?.voyage?.portCutoff}
												</Moment>
											) : (
												' - '
											)}
										</td>
									</tr>
									<tr className="darkLine">
										<th>ETD:</th>
										<td>
											{booking?.voyage?.departureDate !== '' ? (
												<Moment format="DD/MM/YYYY">
													{new Date(Number(booking.voyage?.departureDate))}
												</Moment>
											) : (
												' - '
											)}
										</td>
									</tr>
									<tr>
										<th>ETA:</th>
										<td>
											{booking?.voyage?.arrivalDate !== '' ? (
												<Moment format="DD/MM/YYYY">
													{new Date(Number(booking.voyage?.arrivalDate))}
												</Moment>
											) : (
												' - '
											)}
										</td>
									</tr>
								</table>
							)}
						</tr>

						{booking.contract && (
							<tr>
								<h6>Contract:</h6>
								<table className="bookingDetails">
									<tr>
										<th>{booking.contract?.number}</th>
										<td>
											Start date :{' '}
											{moment(booking.contract?.startDate, 'x').format(
												'DD/MM/YYYY'
											)}
										</td>
										<td>
											End date :{' '}
											{moment(booking.contract?.endDate, 'x').format(
												'DD/MM/YYYY'
											)}
										</td>
									</tr>
								</table>
							</tr>
						)}

						<tr>
							<h6>Containers</h6>
							<table className="bookingDetails">
								<tr>
									<th>Container type</th>
									<th>Quantity</th>
									<th>Pick up</th>
								</tr>
								{booking.containerTypeQuantity.map((i) => {
									if (i.quantity > 0) {
										containerTypeCpt++
										return (
											<tr
												key={i.containerType.id}
												className={containerTypeCpt % 2 == 1 ? 'darkLine' : ''}
											>
												<th>{i.containerType.type}</th>
												<td>{i.quantity}</td>
												<td>{i.depot?.name + ' - ' + i.depot?.city}</td>
												{/* <td>{i.pricePerContainer}</td>
											<td>
												Total: {i.pricePerContainer * i.quantity}{' '}
												{booking.voyage.currency.symbol}
											</td> */}
											</tr>
										)
									}
								})}
							</table>
						</tr>
						<tr>
							<h6>Freight details:</h6>
							<table className="bookingPrices">
								{/* {booking.credits.map((obj) => {
								return (
									<tr>
										<th>{obj.creditItem.item}</th>
										<td>
											{obj.price}
											<span className="clientCurrency">
												{obj.currency.symbol}
											</span>
										</td>
									</tr>
								)
							})} */}
								{/* Commidity Price */}
								<tr>
									<th>Commidity: {booking.marchandise.name}</th>
									{/* <td>
									{booking.marchandisePrice}
									<span className="clientCurrency">
										{booking.voyage.currency.symbol}
									</span>
								</td> */}
								</tr>

								{booking.transport !== 0 && (
									<tr>
										<th>A</th>
										{/* <td>
										{booking.transport}
										<span className="clientCurrency">
											{booking.voyage.currency.symbol}
										</span>
									</td> */}
									</tr>
								)}
								{booking.waiver !== 0 && (
									<tr>
										<th>B</th>
										{/* <td>
										{booking.waiver}
										<span className="clientCurrency">
											{booking.voyage.currency.symbol}
										</span>
									</td> */}
									</tr>
								)}
								{booking.thco !== 0 && (
									<tr>
										<th>C</th>
										{/* <td>
										{booking.thco}
										<span className="clientCurrency">
											{booking.voyage.currency.symbol}
										</span>
									</td> */}
									</tr>
								)}
								{booking.tsco !== 0 && (
									<tr>
										<th>D</th>
										{/* <td>
										{booking.tsco}
										<span className="clientCurrency">
											{booking.voyage.currency.symbol}
										</span>
									</td> */}
									</tr>
								)}
								{/* <tr className="darkLine">
								<th>Total price </th>
								<td>
									{booking.total}{' '}
									<span className="clientCurrency">
										{booking.voyage.currency.symbol}
									</span>
								</td>
							</tr> */}
								{/* {booking.credits.map((obj, i) => {
								if (booking[obj.name] !== 0) {
									if ((cpt + 1) % 2 === 0) {
										cpt++
										return (
											<tr key={i}>
												<th>{obj.item}</th>
												<td>
													{booking[obj.name]}
													<span className="clientCurrency">
														{obj.currency.symbol}
													</span>
												</td>
											</tr>
										)
									} else {
										cpt++
										return (
											<tr className="darkLine">
												<th>{obj.creditItem.item}</th>
												<td>
													{booking[obj.price]}
													<span className="clientCurrency">
														{obj.currency.symbol}
													</span>
												</td>
											</tr>
										)
									}
								}
							})} */}
							</table>
						</tr>
						<tr className="footerInfo">
							<td>
								(€) Belfius Bank | IBAN: BE41 0689 4162 5810 | BIC/SWIFT:
								GKCCBEBB
							</td>
						</tr>
						<tr className="footerMsg">
							<td>WE THANK YOU FOR YOUR CONFIDENCE.</td>
						</tr>
						{/* <div id="pageFooter">Page </div> */}

						<tr>
							<td>
								<ConditionsPages />
							</td>
						</tr>
					</table>
				</>
			)}
		</>
	)
}

export default BookingTemplateEN
