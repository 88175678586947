import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Spinner from '../../UI/Spinner'
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import { useCurrentUser } from '../../currentUserContext'

import Stack from '@mui/material/Stack'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@material-ui/icons/BorderColor'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const ALL_BILL_OF_LANDING = loader('./graphql/allBillOfLandings.graphql')

export default function BillOfLandingList() {
	const currentUser = useCurrentUser()
	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')
	const queryParams = new URLSearchParams(window.location.search)
	const __booking = queryParams.get('__booking')

	const [sortModel, setSortModel] = React.useState([
		{
			field: 'updatedAt',
			sort: 'desc',
		},
	])

	const {
		data: { allBillOfLandings = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_BILL_OF_LANDING)

	useEffect(() => {
		refetch()
	}, [allBillOfLandings])

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 100,
			hide: true,
			flex: 1,
			filterable: false,
		},
		{
			field: 'actions',
			filterable: false,
			disableExport: true,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				if (isAdmin) {
					return (
						<Link
							to={`/edit-bill-of-lading/${thisRow.id}`}
							style={{ margin: '0 auto' }}
							className="listEditBtn"
						>
							<EditIcon className="editBtnIcon" /> Edit
						</Link>
					)
				} else if (
					(thisRow && thisRow.oiRouting === 'Original') ||
					thisRow.oiRouting === 'Sea way bill' ||
					thisRow.oiRouting === 'Telex release'
				) {
					return (
						<Link
							to={`/edit-bill-of-lading/${thisRow.id}`}
							style={{ margin: '0 auto' }}
						>
							<VisibilityIcon />
						</Link>
					)
				} else if (thisRow && thisRow.oiRouting === 'Draft') {
					return (
						<Link
							to={`/edit-bill-of-lading/${thisRow.id}`}
							style={{ margin: '0 auto' }}
						>
							<EditIcon className="editBtnIcon" /> Edit
						</Link>
					)
				}
			},
		},

		{
			field: 'createdAt',
			flex: 1,
			headerName: 'Created',
			minWidth: 120,
			hide: true,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
		{
			field: 'updatedAt',
			flex: 1,
			headerName: 'Updated',
			minWidth: 120,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},

		{
			field: 'number',
			headerName: 'Bill of lading N°',
			minWidth: 150,
			flex: 1,
			renderCell: (elm) => {
				return (
					<>
						<p>
							<strong>{elm.row.number}</strong>
						</p>
						<ContentCopyIcon
							sx={{
								'& :hover': { color: '#f7af3d' },
							}}
							style={{
								fontSize: '16px',
								marginLeft: '10px',
								cursor: 'pointer',
								transition: 'all .2s ease-in-out',
							}}
							onClick={(event) =>
								navigator.clipboard.writeText(elm.row?.number).then(() => {
									let popup = document.createElement('div')
									popup.style.position = 'fixed'
									popup.style.top = `${event.clientY - 10}px`
									popup.style.left = `${event.clientX + 10}px`
									popup.innerHTML = 'Copied!'
									document.body.appendChild(popup)
									setTimeout(() => {
										popup.parentNode.removeChild(popup)
									}, 2000)
								})
							}
						/>
					</>
				)
			},
		},
		{
			field: 'booking',
			flex: 1,
			headerName: 'Booking N°',
			minWidth: 150,
			valueGetter: (params) => params.row.booking?.number,
			renderCell: (elm) => {
				return (
					<>
						<p>{elm.row?.booking?.number}</p>
						<ContentCopyIcon
							sx={{
								'& :hover': { color: '#f7af3d' },
							}}
							style={{
								fontSize: '16px',
								marginLeft: '10px',
								cursor: 'pointer',
								transition: 'all .2s ease-in-out',
							}}
							onClick={(event) =>
								navigator.clipboard
									.writeText(elm.row?.booking?.number)
									.then(() => {
										let popup = document.createElement('div')
										popup.style.position = 'fixed'
										popup.style.top = `${event.clientY - 10}px`
										popup.style.left = `${event.clientX + 10}px`
										popup.innerHTML = 'Copied!'
										document.body.appendChild(popup)
										setTimeout(() => {
											popup.parentNode.removeChild(popup)
										}, 2000)
									})
							}
						/>
					</>
				)
			},
		},
		// { field: 'csac', headerName: 'CSAC', minWidth: 140 },
		// {
		// 	field: 'svcContract',
		// 	headerName: 'SVC Contract',
		// 	minWidth: 50,
		// 	hide: true,
		// },
		// { field: 'exportReference', headerName: 'Export Ref', minWidth: 150 },
		{ field: 'oiRouting', headerName: 'Owward', minWidth: 70, flex: 1 },
		{
			field: 'containerNumber',
			headerName: 'Container N°',
			minWidth: 120,
			flex: 1,
		},
		{ field: 'sealNumber', headerName: 'Seal N°', minWidth: 120, flex: 1 },
		// { field: 'freightPrepaid', headerName: 'Freight prepaid', minWidth: 150 },
		{
			field: 'freightPayableAt',
			flex: 1,
			headerName: 'Freight payable at',
			minWidth: 150,
		},
		// { field: 'dateOfIssue', headerName: 'Date of issue', minWidth: 100 },
		{
			field: 'shipper',
			flex: 1,
			headerName: 'Shipper',
			minWidth: 120,
			valueGetter: ({ value }) => value?.company,
		},
		{
			field: 'consignees',
			flex: 1,
			headerName: 'Consignees',
			minWidth: 120,
			valueGetter: ({ value }) => value?.company,
		},
		{
			field: 'notifyParty',
			flex: 1,
			headerName: 'Notify party',
			minWidth: 120,
			valueGetter: ({ value }) => value?.company,
		},
	]

	if (loading) return <Spinner />

	return (
		<>
			{allBillOfLandings && (
				<DataGrid
					rows={allBillOfLandings}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
					autoHeight={true}
					columns={columns}
					components={{
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									Filter returns no result
								</Stack>
							)
						},
						Toolbar: GridToolbar,
					}}
					initialState={{
						filter: {
							filterModel: {
								items: [
									{
										columnField: 'booking',
										operatorValue: 'contains',
										value: __booking,
									},
								],
							},
						},
					}}
				/>
			)}
		</>
	)
}
