import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import '../../../asset/style.css'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

const CREATE_BANK = loader('./graphql/createBank.graphql')
const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')

const CreateBank = () => {
	const [currencyId, setCurrencyId] = useState()

	const [CreateBank, { data, error, loading }] = useMutation(CREATE_BANK)

	const { data: { allCurrencies = null } = {}, loading: currenciesLoading } =
		useQuery(ALL_CURRENCIES)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, location, iban, bic, holder } = e.target
		return CreateBank({
			variables: {
				input: {
					name: name.value,
					location: location.value,
					IBAN: iban.value,
					BIC: bic.value,
					cardHolderName: holder.value,
					currencyId: currencyId,
				},
			},
		})
	}

	if (loading || currenciesLoading) return <Spinner />

	if (data && !error) {
		localStorage.setItem('createBankState', '✔️ Bank created successfully ! ')
		return <Redirect to="/bank-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Bank Account</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Bank name"
							name="name"
							variant="filled"
							size="small"
							margin="normal"
						/>
						<TextField
							label="Card holder’s name"
							name="holder"
							variant="filled"
							size="small"
							margin="normal"
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Location"
							name="location"
							variant="filled"
							size="small"
							margin="normal"
						/>

						{allCurrencies && (
							<Autocomplete
								getOptionLabel={(option) => option?.currency?.toString()}
								onChange={(event, value) => setCurrencyId(value?.id)}
								options={allCurrencies}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Currency"
										name="currency"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="IBAN"
							name="iban"
							variant="filled"
							size="small"
							margin="normal"
						/>
						<TextField
							label="BIC"
							name="bic"
							variant="filled"
							size="small"
							margin="normal"
						/>
					</FormGroup>

					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateBank
