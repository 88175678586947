import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { _ } from 'lodash'

import TextField from '@material-ui/core/TextField'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Autocomplete from '@mui/material/Autocomplete'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIcon from '@material-ui/icons/Save'

import { Button } from '@material-ui/core'

import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import * as moment from 'moment'

import '../../../asset/style.css'

const ALL_BOOKINGS = loader('./graphql/allBookings.graphql')
const BOOKING = loader('./graphql/booking.graphql')
const CREATE_INVOICE = loader('./graphql/createBookingInvoice.graphql')

const CreateInvoice = () => {
	const [bookingId, setBookingId] = useState('')
	const [expanded, setExpanded] = useState(false)
	const [mainCurrency, setMainCurrency] = useState('')
	const [itemsToBeBilled, setItemsToBeBilled] = useState([])
	const [total, setTotal] = useState(0)

	const [createInvoice, { data, error, loading: loadingCreateInvoice }] =
		useMutation(CREATE_INVOICE)

	const { data: { allBookings = null } = {}, loading } = useQuery(ALL_BOOKINGS)

	const [
		getBooking,
		{ data: { booking = null } = {}, loading: loadingBooiking, refetch },
	] = useLazyQuery(BOOKING)

	useEffect(() => {
		refetch()
	}, [booking])

	const handleSubmit = (e) => {
		e.preventDefault()
		return createInvoice({
			variables: {
				input: {
					currencyId: mainCurrency.id,
					amount: Number(total),
					credits: itemsToBeBilled.map(({ amount, bookingCreditId }) => ({
						amount,
						bookingCreditId,
					})),
				},
			},
		})
	}

	const handleFetchBookingDetails = (e) => {
		e.preventDefault()
		getBooking({
			variables: {
				id: bookingId,
			},
		})
	}

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	const handleSelectedAndRemoveItem = (e, bookingCreditId) => {
		if (e.target.checked) {
			setItemsToBeBilled([...itemsToBeBilled, { bookingCreditId }])
		} else {
			setItemsToBeBilled(
				[...itemsToBeBilled].filter(
					({ bookingCreditId: id }) => id !== bookingCreditId
				)
			)
		}
	}

	const handeleAddingItemPrice = (id, amountToBill, amountInMainCurrency) => {
		const arrayWithPrice = [...itemsToBeBilled].map((element) =>
			element.bookingCreditId === id
				? {
						...element,
						amount: Number(amountToBill),
						amountInMainCurrency: Number(amountInMainCurrency).toFixed(2),
				  }
				: element
		)
		setItemsToBeBilled(arrayWithPrice)
	}

	useEffect(() => {
		if (booking && booking.credits) {
			const findBookingCreditItem = booking.credits.find(
				({ creditItem }) => creditItem.item === 'Booking'
			)
			setMainCurrency({
				currency: findBookingCreditItem?.currency?.currency,
				id: findBookingCreditItem?.currency?.id,
			})
			setTotal(0)
			setItemsToBeBilled([])
		}
	}, [booking])

	useEffect(() => {
		if (itemsToBeBilled.length) {
			const calculatedTotal = itemsToBeBilled.length
				? itemsToBeBilled
						.map(({ amountInMainCurrency }) =>
							amountInMainCurrency ? Number(amountInMainCurrency) : 0
						)
						.reduce((a, b) => a + b)
				: 0
			setTotal(calculatedTotal)
		}
	}, [itemsToBeBilled])

	if (loadingCreateInvoice || loadingBooiking || loading) return <Spinner />

	if (data?.createBookingInvoice && !error) {
		localStorage.setItem(
			'createInvoiceState',
			'✔️ Invoice created successfully ! '
		)
		return <Redirect to="/invoice-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<section className="bookingSearchSection">
					{allBookings && (
						<>
							<Autocomplete
								fullWidth
								onChange={(event, value) => setBookingId(value?.id)}
								options={allBookings}
								getOptionLabel={(option) => option.number}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.number}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Booking number"
										name="bookingNbr"
										variant="filled"
										size="large"
										margin="normal"
									/>
								)}
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								onClick={handleFetchBookingDetails}
								style={{ marginLeft: '20px' }}
							>
								Search
							</Button>
						</>
					)}
				</section>
				{booking && (
					<fieldset>
						<legend>Booking NO. {booking?.number}</legend>
						{booking.credits?.map((elm) => {
							return (
								<FormGroup
									key={elm.id}
									style={{
										display: 'flex',
										flexDirection: 'row',
										paddingTop: '20px',
										paddingBottom: '20px',
									}}
								>
									<Checkbox
										id={elm?.id}
										value={elm?.id}
										onChange={(e) => handleSelectedAndRemoveItem(e, elm.id)}
									/>
									<Accordion
										fullWidth
										style={{ width: '60%' }}
										expanded={expanded === 'panel1'}
										onChange={handleChange('panel1')}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<label> Item: </label>{' '}
											<strong>{elm.creditItem?.item}</strong>
										</AccordionSummary>
										<AccordionDetails>
											{elm.creditItem.item === 'Booking' ? (
												<>
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>Container type</TableCell>
																<TableCell>Price</TableCell>
																<TableCell>Quantity</TableCell>
																<TableCell>Total</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{booking.containerTypeQuantity?.map((ctr) => {
																return (
																	<TableRow>
																		<TableCell>
																			{ctr.containerType.type}
																		</TableCell>
																		<TableCell>
																			{ctr.pricePerContainer}
																		</TableCell>
																		<TableCell>{ctr.quantity}</TableCell>
																		<TableCell>
																			{ctr.quantity * ctr.pricePerContainer}
																		</TableCell>
																	</TableRow>
																)
															})}
														</TableBody>
													</Table>
													<br />
													<br />
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>Commodity</TableCell>
																<TableCell>Price</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															<TableCell>{booking.marchandise.name}</TableCell>
															<TableCell>{booking?.marchandisePrice}</TableCell>
														</TableBody>
													</Table>
													<br />
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>A</TableCell>
																<TableCell>B</TableCell>
																<TableCell>C</TableCell>
																<TableCell>D</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															<TableCell>{booking.transport}</TableCell>
															<TableCell>{booking?.waiver}</TableCell>
															<TableCell>{booking?.thco}</TableCell>
															<TableCell>{booking?.tsco}</TableCell>
														</TableBody>
													</Table>
												</>
											) : (
												<p></p>
											)}
										</AccordionDetails>
									</Accordion>

									<TextField
										label={`Total ${elm?.currency?.symbol}`}
										variant="outlined"
										style={{ width: '80px' }}
										defaultValue={elm.price}
										disabled
										helperText={
											elm?.currency?.id === mainCurrency?.id
												? ''
												: mainCurrency?.currency === 'EUR'
												? `Conversion rate to ${mainCurrency?.currency} = ${elm?.currency?.inEuro}`
												: `Conversion rate to USD = ${elm?.currency?.inUsd}`
										}
									/>
									<TextField
										label="Invoiced"
										variant="outlined"
										style={{ width: '80px' }}
										value={elm.billed}
										disabled
									/>
									{elm.price - elm.billed > 0 && (
										<TextField
											disabled={
												!Boolean(
													itemsToBeBilled.find(
														({ bookingCreditId }) => bookingCreditId === elm?.id
													)
												)
											}
											value={
												itemsToBeBilled.find(
													({ bookingCreditId }) => bookingCreditId === elm?.id
												)
													? itemsToBeBilled.find(
															({ bookingCreditId }) =>
																bookingCreditId === elm?.id
													  )?.amount
													: ''
											}
											label={`To bill - ${elm?.currency?.currency}`}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											type="number"
											style={{ width: '100px' }}
											inputProps={{ max: elm.price - elm.billed }}
											onWheel={(e) => e.target.blur()}
											placeholder={0}
											onChange={(e) => {
												let { value } = e.target
												Number(value) > Number(elm.price) - Number(elm.billed)
													? (value = Number(elm.price) - Number(elm.billed))
													: Number(value)
												let amountInMainCurrency =
													elm.currency.id === mainCurrency.id
														? value
														: mainCurrency.currency === 'EUR'
														? Number(value) * Number(elm.currency.inEuro)
														: Number(value) * Number(elm.currency.inUsd)

												handeleAddingItemPrice(
													elm.id,
													value,
													amountInMainCurrency
												)
											}}
											helperText={`Max: ${elm.price - elm.billed}`}
										/>
									)}

									{itemsToBeBilled.find(
										({ bookingCreditId }) => bookingCreditId === elm?.id
									) &&
										elm.currency.id !== mainCurrency.id && (
											<span>{`Amount in ${mainCurrency?.currency}: ${
												itemsToBeBilled.find(
													({ bookingCreditId }) => bookingCreditId === elm?.id
												)?.amountInMainCurrency || 0
											}`}</span>
										)}
								</FormGroup>
							)
						})}

						<FormGroup
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<TextField
								label={`Total In ${mainCurrency.currency}`}
								variant="filled"
								disabled
								value={total}
								style={{ width: '250px', marginTop: '20px' }}
							/>
						</FormGroup>
					</fieldset>
				)}
				<Button
					className="submitBtn"
					type="submit"
					variant="contained"
					color="primary"
					disabled={(!booking && !itemsToBeBilled.length) || total === 0}
				>
					{' '}
					<SaveIcon />
					&nbsp;Submit{' '}
				</Button>
			</Box>
		</>
	)
}

export default CreateInvoice
