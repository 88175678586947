import React, { Component } from 'react'
import BookingTemplateFR from './BookingTemplateFR'

class Print extends Component {
	render() {
		return (
			<>
				<BookingTemplateFR id={this.props.id} />
			</>
		)
	}
}

export default Print
