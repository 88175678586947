import React, { useState, useEffect } from 'react'
import { loader } from 'graphql.macro'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import FormGroup from '@material-ui/core/FormGroup'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'

import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@mui/material/CircularProgress'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import '../../../asset/style.css'

const CREATE_VOYAGE = loader('./graphql/createVoyage.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')
const ALL_VESSELS = loader('./graphql/allVessels.graphql')
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')
const EURO_USD_CURRENCY = loader('./graphql/euroAndUsdDollar.graphql')
const DEPOTS_BY_PORT = loader('./graphql/depotsByPort.graphql')

const CreateVoyage = () => {
	const [shipId, setShipId] = useState()
	const [departurePortId, setDeparturePortId] = useState()
	const [arrivalPortId, setArrivalPortId] = useState()
	const [transitDays, setTransitDays] = useState(0)
	const [departureDate, setDepartureDate] = useState()
	const [arrivalDate, setArrivalDate] = useState()
	const [containerTypesPrice, setContainerTypesPrice] = useState([])
	const [currencyId, setCurrencyId] = useState()
	const [depotId, setDepotId] = useState()

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const [createVoyage, { data, error, loading }] = useMutation(CREATE_VOYAGE)

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const { data: { allVessels = null } = {}, loading: vesselsLoading } =
		useQuery(ALL_VESSELS)

	const {
		data: { allContainerTypes = null } = {},
		loading: allContainerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const {
		data: { euroAndUsdDollar = null } = {},
		loading: euroAndUsdDollarLoading,
	} = useQuery(EURO_USD_CURRENCY)

	const [
		getDepotsByPort,
		{ data: { depotsByPort = null } = {}, loading: depotsLoading },
	] = useLazyQuery(DEPOTS_BY_PORT)

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			voyageNumber, // ref AAMM+2 numbers
			departureDate,
			arrivalDate,
			vgmClosing,
			docClosing,
			portCutoff,
		} = e.target
		return createVoyage({
			variables: {
				input: {
					number: voyageNumber.value.replace(/[^\w\s]/gi, '-'),
					shipId: shipId,
					loadingPortId: departurePortId,
					unLoadingPortId: arrivalPortId,
					departureDate: departureDate.value,
					arrivalDate: arrivalDate.value,
					containerTerminal: depotId,
					vgmClosing: vgmClosing.value,
					docClosing: docClosing.value,
					portCutoff: portCutoff.value,
					containerTypesPrice,
					currencyId,
				},
			},
		})
	}

	useEffect(() => {
		var date1 = new Date(Number(departureDate))
		var date2 = new Date(Number(arrivalDate))
		var Difference_In_Time = date2.getTime() - date1.getTime()
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
		setTransitDays(Difference_In_Days)
	}, [departureDate, arrivalDate, transitDays])

	useEffect(() => {
		let typesArray = []
		allContainerTypes &&
			allContainerTypes.map((elm) => {
				typesArray.push({
					typeId: elm.id,
					price: 0,
					contractPrice: 0,
				})
			})
		setContainerTypesPrice(typesArray)
	}, [allContainerTypes])

	useEffect(() => {
		if (departurePortId) {
			getDepotsByPort({
				variables: {
					portId: departurePortId,
				},
			})
		}
	}, [departurePortId])

	if (loading || portsLoading || allContainerTypeLoading || vesselsLoading)
		return <Spinner />

	if (data?.createVoyage && !error) {
		localStorage.setItem(
			'createVoyageState',
			'✔️ Voyage created successfully ! '
		)
		return <Redirect to="/voyage-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Voyage</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Voyage No."
							name="voyageNumber"
							variant="filled"
							size="small"
							margin="dense"
						/>
						{allVessels && (
							<Autocomplete
								onChange={(event, value) => setShipId(value?.id)}
								options={allVessels}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Ship name"
										name="shipName"
										variant="filled"
										size="small"
										required
										margin="dense"
									/>
								)}
							/>
						)}
					</FormGroup>

					<FormGroup className="formGrp" row>
						{allPorts && (
							<Autocomplete
								onChange={(event, value) => setDeparturePortId(value?.id)}
								options={allPorts}
								filterOptions={filterOptions}
								loading={portsLoading}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Loading Port"
										variant="filled"
										size="small"
										name="loadingPort"
										required
										margin="dense"
										inputProps={{
											...params.inputProps,
											endAdornment: (
												<React.Fragment>
													{portsLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						)}
						{allPorts && (
							<Autocomplete
								onChange={(event, value) => setArrivalPortId(value?.id)}
								options={allPorts}
								filterOptions={filterOptions}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Unloading Port"
										name="unloadingPort"
										variant="filled"
										size="small"
										margin="dense"
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
						)}
					</FormGroup>
					<FormGroup className="formGrp" row>
						{/* <TextField
							label="Departure date"
							name="departureDate"
							onChange={({ target: { value } }) => {
								setDepartureDate(value)
							}}
							type="date"
							InputLabelProps={{ shrink: true }}
							variant="filled"
							size="small"
							margin="dense"
						/> */}
						<TextField
							onChange={({ target: { value } }) => {
								setDepartureDate(value)
							}}
							label="Departure date"
							name="departureDate"
							variant="filled"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							size="small"
							margin="dense"
						/>
						<TextField
							label="Arrival date"
							name="arrivalDate"
							disabled={departureDate && departureDate ? false : true}
							helperText={
								departureDate ? '' : 'Please select a departure date first'
							}
							onChange={({ target: { value } }) => {
								setArrivalDate(value)
							}}
							type="datetime-local"
							d
							InputLabelProps={{ shrink: true }}
							InputProps={{
								inputProps: {
									min: departureDate && departureDate,
									//	new Date(departureDate).toISOString().slice(0, 10),
								},
							}}
							variant="filled"
							size="small"
							margin="dense"
						/>
					</FormGroup>

					{euroAndUsdDollar && (
						<Autocomplete
							onChange={(event, value) => setCurrencyId(value?.id)}
							options={euroAndUsdDollar}
							getOptionLabel={(option) => option.currency}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.currency}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Currency"
									variant="filled"
									size="small"
									name="currency"
									required
									margin="dense"
									inputProps={{
										...params.inputProps,
										endAdornment: (
											<React.Fragment>
												{portsLoading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					)}
				</fieldset>
				<fieldset>
					<legend>Pick up</legend>
					<FormGroup className="formGrp" row>
						{depotsByPort && (
							<Autocomplete
								onChange={(event, value) => setDepotId(value?.id)}
								options={depotsByPort}
								filterOptions={filterOptions}
								loading={portsLoading}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.name}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Container Terminal"
										variant="filled"
										size="small"
										name="containerTerminal"
										required
										margin="dense"
										inputProps={{
											...params.inputProps,
											endAdornment: (
												<React.Fragment>
													{portsLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						)}
					</FormGroup>
				</fieldset>
				<fieldset>
					<legend>VGM | DOC | Port Cut-Off</legend>
					<FormGroup className="formGrp" row>
						<TextField
							label="VGM CLOSING"
							name="vgmClosing"
							variant="filled"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							size="small"
							margin="dense"
						/>
						<TextField
							label="DOC CLOSING"
							name="docClosing"
							variant="filled"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							size="small"
							margin="dense"
						/>
						<TextField
							label="Port Cut-Off"
							name="portCutoff"
							variant="filled"
							type="datetime-local"
							InputLabelProps={{ shrink: true }}
							size="small"
							margin="dense"
						/>
					</FormGroup>
				</fieldset>
				{/* Price by Container type */}
				<fieldset>
					<legend>Price by Container type </legend>
					<FormGroup className="formGrp" row>
						<TableContainer component={Paper} style={{ marginTop: '20px' }}>
							<Table size="small" dense table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>
											Container Type
										</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>
											Contract Price
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{allContainerTypes &&
										allContainerTypes.map((elm) => {
											return (
												<TableRow>
													<TableCell>{elm?.type}</TableCell>
													<TableCell>
														<TextField
															variant="outlined"
															size="small"
															margin="dense"
															type="number"
															data-type={elm?.id}
															InputProps={{ inputProps: { min: 0 } }}
															onWheel={(e) => e.target.blur()}
															onChange={(e) => {
																let newState = containerTypesPrice.map(
																	(obj) => {
																		if (obj.typeId === elm.id) {
																			return {
																				...obj,
																				price: Number(e.target.value),
																			}
																		}
																		return obj
																	}
																)
																setContainerTypesPrice(newState)
															}}
														></TextField>
													</TableCell>
													<TableCell>
														<TextField
															variant="outlined"
															size="small"
															margin="dense"
															type="number"
															data-type={elm?.id}
															InputProps={{ inputProps: { min: 0 } }}
															onWheel={(e) => e.target.blur()}
															onChange={(e) => {
																let newState = containerTypesPrice.map(
																	(obj) => {
																		if (obj.typeId === elm.id) {
																			return {
																				...obj,
																				contractPrice: Number(e.target.value),
																			}
																		}
																		return obj
																	}
																)

																setContainerTypesPrice(newState)
															}}
														/>
													</TableCell>
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</FormGroup>
				</fieldset>
				<Button
					className="submitBtn"
					type="submit"
					variant="contained"
					color="primary"
				>
					{' '}
					<SaveIcon />
					&nbsp;Submit{' '}
				</Button>
			</Box>
		</>
	)
}

export default CreateVoyage
