import React from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@mui/material/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import CountriesList from './countries.json'

const CREATE_VESSEL = loader('./graphql/createVessel.graphql')

const CreateVessel = () => {
	const [flag, setFlag] = React.useState()
	const [createVessel, { data, error, loading }] = useMutation(CREATE_VESSEL)

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			vesselName,
			vesselOwner,
			vesselOperator,
			imo,
			callSign,
			registerNumber,
			teu,
			tdw,
			grt,
			nrt,
			length,
			breadth,
			speed,
			power,
			builtYear,
			abbreviation,
			portRegistry,
		} = e.target
		return createVessel({
			variables: {
				input: {
					name: vesselName.value,
					operator: vesselOperator.value,
					owner: vesselOwner.value,
					imoNumber: imo.value,
					callSign: callSign.value,
					registerNumber: registerNumber.value,
					deadWeight: Number(tdw.value),
					grossTonage: Number(grt.value),
					netTonage: Number(nrt.value),
					teu: Number(teu.value),
					lengthOverall: Number(length.value),
					breadthOverall: Number(breadth.value),
					speed: Number(speed.value),
					power: Number(power.value),
					yearBuilt: builtYear.value,
					flag: flag,
					abbreviation: abbreviation.value,
					portOfRegistry: portRegistry.value,
				},
			},
		})
	}

	if (loading) return <Spinner />

	if (data && !error) {
		localStorage.setItem(
			'createVesselState',
			'✔️ Vessel created successfully ! '
		)
		return <Redirect to="/vessel-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Vessel</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Vessel name"
							name="vesselName"
							variant="filled"
							size="small"
							margin="dense"
						/>

						<TextField
							label="Vessel Owner"
							name="vesselOwner"
							variant="filled"
							size="small"
							margin="dense"
						/>
						<TextField
							label="Vessel Operator"
							name="vesselOperator"
							variant="filled"
							size="small"
							margin="dense"
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="IMO Number"
							name="imo"
							variant="filled"
							size="small"
							margin="dense"
						/>
						<TextField
							label="Call Sign"
							name="callSign"
							variant="filled"
							size="small"
							margin="dense"
						/>
						<TextField
							label="Register Number"
							name="registerNumber"
							variant="filled"
							size="small"
							margin="dense"
						/>
					</FormGroup>
					<fieldset>
						<FormGroup className="formGrp" row>
							<TextField
								label="Deadweight (tdw)"
								name="tdw"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">Kg</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Gross Tonnage (GRT)"
								name="grt"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">Kg</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Net Tonnage (NRT)"
								name="nrt"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">Kg</InputAdornment>
									),
								}}
							/>
							<TextField
								label="TEU"
								name="teu"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">Unit</InputAdornment>
									),
								}}
							/>
						</FormGroup>
					</fieldset>
					<fieldset>
						<FormGroup className="formGrp" row>
							<TextField
								label="Length Overall"
								name="length"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">m</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Breadth Overall"
								name="breadth"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">m</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Speed"
								name="speed"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">Kn</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Power"
								name="power"
								type="number"
								variant="filled"
								size="small"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">KW</InputAdornment>
									),
								}}
							/>
						</FormGroup>
					</fieldset>
					<fieldset>
						<FormGroup className="formGrp" row>
							<TextField
								label="Year Built"
								name="builtYear"
								variant="filled"
								size="small"
								margin="dense"
							/>

							<Autocomplete
								onChange={(event, value) => setFlag(value?.label)}
								autoHighlight
								options={CountriesList}
								getOptionLabel={(option) => option?.label}
								renderOption={(props, option) => (
									<Box
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										<img
											loading="lazy"
											width="20"
											src={`https://flagcdn.com/w20/${props?.code?.toLowerCase()}.png`}
											srcSet={`https://flagcdn.com/w40/${props?.code?.toLowerCase()}.png 2x`}
											alt=""
										/>{' '}
										{props?.label}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Flag"
										name="flag"
										variant="filled"
										size="small"
										margin="dense"
										inputProps={{
											...params.inputProps,
											autoComplete: 'new-password', // disable autocomplete and autofill
										}}
									/>
								)}
							/>
							<TextField
								label="Abbreviation"
								name="abbreviation"
								variant="filled"
								size="small"
								margin="dense"
							/>
							<TextField
								label="Port of Registry"
								name="portRegistry"
								variant="filled"
								size="small"
								margin="dense"
							/>
						</FormGroup>
					</fieldset>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateVessel
