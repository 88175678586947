import React, { useState } from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

const ALL_BOOKINGS = loader('./graphql/allBookings.graphql')
const ADD_CREDIT_ENTRY = loader('./graphql/addCreditItemToBooking.graphql')
const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')
const ALL_CREDIT_ITEMS = loader('./graphql/allCreditItems.graphql')

const CreateCreditEntry = () => {
	const currentUser = useCurrentUser()

	const [bookingId, setBookingId] = useState()
	const [currencyId, setCurrencyId] = useState()
	const [creditItemId, setCreditItemId] = useState()

	const { data: { allBookings = null } = {}, loading: bookingLoading } =
		useQuery(ALL_BOOKINGS)
	const { data: { allCurrencies = null } = {}, loading: currenciesLoading } =
		useQuery(ALL_CURRENCIES)
	const { data: { allCreditItems = null } = {}, loading: creditItemsLoading } =
		useQuery(ALL_CREDIT_ITEMS)
	const [addCreditEntry, { data, error, loading }] =
		useMutation(ADD_CREDIT_ENTRY)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { price } = e.target
		return addCreditEntry({
			variables: {
				input: {
					price: Number(price.value),
					bookingId,
					creditItemId,
					currencyId: currencyId,
				},
			},
		})
	}

	if (loading) return <Spinner />
	if (!currentUser) return <Redirect to="/" />

	if (data?.addCreditEntry && !error) {
		localStorage.setItem(
			'createCreditEntryState',
			'✔️ Credit Entry created successfully ! '
		)
		return <Redirect to="debit-entry-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Credit Entry</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						{allBookings && (
							<Autocomplete
								fullWidth
								onChange={(event, value) => setBookingId(value?.id)}
								options={allBookings}
								getOptionLabel={(option) => option.number}
								renderOption={(props, option) => (
									<Box
										key={option.id}
										component="li"
										sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
										{...props}
									>
										&nbsp;&nbsp;{option.number}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Booking number"
										name="bookingNbr"
										variant="filled"
										size="large"
										margin="normal"
									/>
								)}
							/>
						)}
						{allCurrencies && (
							<Autocomplete
								getOptionLabel={(option) => option?.currency?.toString()}
								onChange={(event, value) => setCurrencyId(value?.id)}
								options={allCurrencies}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Currency"
										name="currency"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}
						{allCreditItems && (
							<Autocomplete
								getOptionLabel={(option) => option?.item}
								onChange={(event, value) => setCreditItemId(value?.id)}
								options={allCreditItems}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Credit Item"
										name="creditItem"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}
						<TextField
							label="Credit Amount"
							name="price"
							variant="filled"
							size="small"
							margin="normal"
							type="number"
						/>
					</FormGroup>

					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						disabled={!currencyId || !creditItemId}
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateCreditEntry
