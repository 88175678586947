import * as React from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Moment from 'react-moment'

import './style.css'

import Logo from './asset/img/logo.png'
import Qr from './asset/img/asaslineQR.png'

const BOOKING = loader('./graphql/booking.graphql')

const BookingTemplateFR = ({ id }) => {
	let cpt = 0

	const pricesArr = [
		{ name: 'priceTransportMaritime', label: 'Prix transport maritime' },
		{ name: 'transport', label: 'Tranport' },
		{ name: 'waiver', label: 'Waiver' },
		{ name: 'thco', label: 'Terminal Handling Charge Orig.' },
		{ name: 'tsco', label: 'Terminal Security Charge Orig.' },
		{ name: 'carrierSecurityFee', label: 'Marine Fuel Recovery' },
		{ name: 'marineFuelRecovery', label: 'Carrier Security Fee' },
		{ name: 'thcd', label: 'Terminal Handling Charge Dest.' },
		{ name: 'ose', label: 'Outward Service Expenses' },
		{ name: 'surveyExpenses', label: 'Survey Expenses' },
		{ name: 'document', label: 'Documentation' },
		{
			name: 'terminalSecurityDestination',
			label: 'Terminal Security Destination',
		},
		{ name: 'clearanceExportCustom', label: 'Clearance export costume' },
	]

	const {
		data: { booking = null } = {},
		loading,
		refetch,
	} = useQuery(BOOKING, {
		variables: {
			id,
		},
	})

	return (
		<>
			{booking && (
				<table className="bookingPage">
					<tr>
						<table className="bookingHeader">
							<tr>
								<th style={{ width: '30%' }}>
									<img src={Logo} className="logo" />
								</th>
								<th style={{ width: '40%' }}>
									<h1>Booking Confirmation</h1>
								</th>
								<th style={{ width: '30%' }}>
									<img src={Qr} className="qrCode" />
								</th>
							</tr>
						</table>
						<table className="bookingInfo">
							<tr>
								<td className="bookingInfoLeft">
									<tr>
										<td>
											<h3>ASASLINE S.A.</h3>
										</td>
									</tr>
									<tr>
										<td>Rue de Douvres 115</td>
									</tr>
									<tr>
										<td>1070 Bruxelles - Belgiqie</td>
									</tr>
									<tr>
										<td>(+32) 02 3 15 14 15 </td>
									</tr>
									<tr>
										<td>contact@asasline.com</td>
									</tr>
									<tr>
										<td>www.asasline.com</td>
									</tr>
									<tr>
										<td>TVA:BE0772 649 540</td>
									</tr>
									<tr>
										<td></td>
									</tr>
									<tr>
										<td>
											<b>Personne de contact: </b>
										</td>
									</tr>
									<tr>
										<td className="contactPerson">{booking.user.name}</td>
									</tr>
								</td>
								<td className="bookingInfoRight">
									<tr>
										<td>
											<b>N° DE BOOKING:</b> {booking.number}
										</td>
									</tr>
									<tr>
										<td>
											<b>
												DATE:{' '}
												<Moment format="DD/MM/YYYY">
													{Date(booking.createdAt)}
												</Moment>
											</b>
										</td>
									</tr>
									<tr>
										<td>
											<b>RÉF DOC: {booking.documentRef}</b>
										</td>
									</tr>
									<tr>
										<td></td>
									</tr>
									<tr>
										<td></td>
									</tr>
									<tr>
										<td></td>
									</tr>
									<tr>
										<td>Socité: {booking.client.company}</td>
									</tr>
									<tr>
										<td>Adresse: {booking.client.address}</td>
									</tr>
									<tr>
										<td>E-mail: {booking.client.email}</td>
									</tr>
								</td>
							</tr>
						</table>
					</tr>
					<tr>
						<h6>Détails de Transport:</h6>
						<table className="bookingDetails">
							<tr className="darkLine">
								<td>Type de Conteneur:</td>
								<td>{booking.containerType}</td>
							</tr>
							<tr>
								<td>Quantité:</td>
								<td>{booking.quantity}</td>
							</tr>
							<tr className="darkLine">
								<td>Marchandises:</td>
								<td>{booking.marchandise.name}</td>
							</tr>
							<tr>
								<td>Lieu de chargement:</td>
								<td>{booking.loadingZone}</td>
							</tr>
							<tr className="darkLine">
								<td>Date et heure de chargement:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">
										{booking.dateOfLoading}
									</Moment>
								</td>
							</tr>
							<tr>
								<td>Port de chargement:</td>
								<td>{booking.portOfLoading}</td>
							</tr>
							<tr className="darkLine">
								<td>Port de déchargement:</td>
								<td>{booking.portOfUnLoading}</td>
							</tr>
							<tr>
								<td>Pays de départ:</td>
								<td>{booking.departure}</td>
							</tr>
							<tr className="darkLine">
								<td>Pays d’arrivée:</td>
								<td>{booking.arriving}</td>
							</tr>
							<tr>
								<td>Armateur:</td>
								<td>{booking.shipOwner}</td>
							</tr>
							<tr className="darkLine">
								<td>Navire/Voyage:</td>
								<td>{booking.trip}</td>
							</tr>
							<tr>
								<td>Pick up container:</td>
								<td>{booking.pickupContainer}</td>
							</tr>
							<tr className="darkLine">
								<td>Pick up date:</td>
								<td>
									<Moment format="DD/MM/YYYY">{booking.pickupDate}</Moment>
								</td>
							</tr>
							<tr>
								<td>Container Terminal:</td>
								<td>{booking.containerTerminal}</td>
							</tr>
							<tr className="darkLine">
								<td>VGM CLOSING:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">
										{booking.vgmClosing}
									</Moment>
								</td>
							</tr>
							<tr>
								<td>DOC CLOSING:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">
										{booking.docClosing}
									</Moment>
								</td>
							</tr>
							<tr className="darkLine">
								<td>Port Cut-Off Date/Time:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">
										{booking.portCutOfDate}
									</Moment>
								</td>
							</tr>
							<tr>
								<td>ETD:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">
										{booking.voyage.etd}
									</Moment>
								</td>
							</tr>
							<tr>
								<td>ETA:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">
										{booking.voyage.eta}
									</Moment>
								</td>
							</tr>
							{/* <tr className="darkLine">
								<td>ETA:</td>
								<td>
									<Moment format="DD/MM/YYYY HH:MM:SS">{booking.eta}</Moment>
								</td>
							</tr> */}
						</table>
					</tr>
					<tr>
						<h6>Détails du fret:</h6>
						<table className="bookingPrices">
							<tr className="darkLine">
								<td>Total prix {booking.priceDetails ? 'ALL IN' : ''}:</td>
								<td>
									{booking.price}{' '}
									<span className="clientCurrency">
										{booking.client.currency === 'eur' ? '€' : '$'}
									</span>
								</td>
							</tr>
							{pricesArr.map((obj, i) => {
								if (booking[obj.name] !== 0) {
									if (cpt % 2 === 0) {
										cpt++
										return (
											<tr key={i}>
												<td>{obj.label}</td>
												<td>
													{booking[obj.name]}
													<span className="clientCurrency">
														{booking.client.currency === 'eur' ? '€' : '$'}
													</span>
												</td>
											</tr>
										)
									} else {
										cpt++
										return (
											<tr className="darkLine">
												<td>{obj.label}</td>
												<td>
													{booking[obj.name]}
													<span className="clientCurrency">
														{booking.client.currency === 'eur' ? '€' : '$'}
													</span>
												</td>
											</tr>
										)
									}
								}
							})}
							{/* <tr>
							<td>Prix transport maritime</td>
							<td>{booking.priceTransportMaritime}</td>
						</tr>
						<tr className="darkLine">
							<td>Tranport</td>
							<td>{booking.transport}</td>
						</tr>
						<tr>
							<td>Waiver</td>
							<td>{booking.waiver}</td>
						</tr>
						<tr className="darkLine">
							<td>Terminal Handling Charge Orig.</td>
							<td>{booking.thco}</td>
						</tr>
						<tr>
							<td>Terminal Security Charge Orig.</td>
							<td>{booking.tsco}</td>
						</tr>
						<tr className="darkLine">
							<td>Marine Fuel Recovery</td>
							<td>{booking.marineFuelRecovery}</td>
						</tr>
						<tr>
							<td>Carrier Security Fee </td>
							<td>{booking.carrierSecurityFee}</td>
						</tr>
						<tr className="darkLine">
							<td>Terminal Handling Charge Dest.</td>
							<td>{booking.thcd}</td>
						</tr>
						<tr>
							<td>Outward Service Expenses</td>
							<td>{booking.ose}</td>
						</tr>
						<tr className="darkLine">
							<td>Survey Expenses</td>
							<td>{booking.surveyExpenses}</td>
						</tr>
						<tr>
							<td>Documentation</td>
							<td>{booking.document}</td>
						</tr>
						<tr className="darkLine">
							<td>Terminal Security Destination</td>
							<td>{booking.terminalSecurityDestination}</td>
						</tr> */}
						</table>
					</tr>
					<tr className="footerInfo">
						<td>
							(€) Belfius Bank | IBAN: BE41 0689 4162 5810 | BIC/SWIFT: GKCCBEBB
						</td>
					</tr>
					<tr className="footerMsg">
						<td>NOUS VOUS REMERCIONS DE VOTRE CONFIANCE.</td>
					</tr>
					<div id="pageFooter">Page </div>
				</table>
			)}
		</>
	)
}

export default BookingTemplateFR
