import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'

import SaveIcon from '@material-ui/icons/Save'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const DEPOT = loader('./graphql/depot.graphql')
const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')
const UPDATE_DEPOT = loader('./graphql/updateDepot.graphql')

const EditDepot = () => {
	const params = useParams() //get the depot ID
	const [countryId, setCountryId] = useState()

	const { data: { depot = {} } = {}, loading: depotLoading } = useQuery(DEPOT, {
		variables: { id: params.id },
	})

	const [updateDepot, { data, error, loading }] = useMutation(UPDATE_DEPOT)

	const { data: { allCountries = [] } = {}, loading: countriesLoading } =
		useQuery(ALL_COUNTRIES)

	const { id } = params

	useEffect(() => {
		if (data) {
			localStorage.setItem('depotState', '✔️ Depot updated successfully ! ')
			window.location.reload()
		}
	}, [data])

	if (depotLoading || loading || countriesLoading)
		return <Spinner style={{ marginTop: '400px' }} />

	if (!useCurrentUser) return <Redirect to="/" />

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, country, city } = e.target
		return updateDepot({
			variables: {
				input: {
					id,
					name: name.value,
					country: countryId,
					city: city.value,
				},
			},
		})
	}

	return (
		<>
			{error ? (
				<p>{error.message}</p>
			) : (
				depot && (
					<>
						<Box
							className="mainZone"
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div
								style={{
									width: '100%',
									height: '50px',
									backgroundColor: '#0c1836',
									marginBottom: '10px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h1 style={{ color: '#fff' }}>
									Edit Depot - {depot?.name?.toUpperCase()}
								</h1>
							</div>

							<fieldset>
								<FormGroup className="formGrp" row>
									{/* {allCurrencies && (
										<Autocomplete
											defaultValue={bank && bank.currency}
											getOptionLabel={(option) => option?.currency}
											onChange={(event, value) => setCurrencyId(value?.id)}
											options={allCurrencies}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Currency"
													name="currency"
													variant="filled"
													size="small"
													margin="normal"
													required
												/>
											)}
										/>
									)} */}
								</FormGroup>
								<FormGroup className="formGrp" row>
									<TextField
										defaultValue={depot.name}
										label="Depot name"
										name="name"
										variant="filled"
										size="small"
										margin="normal"
									/>

									{allCountries && (
										<Autocomplete
											defaultValue={depot && depot.country}
											getOptionLabel={(option) => option.toUpperCase()}
											onChange={(event, value) => setCountryId(value)}
											options={allCountries}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Country"
													name="Country"
													variant="filled"
													size="small"
													margin="normal"
													required
												/>
											)}
										/>
									)}

									<TextField
										defaultValue={depot.city}
										label="City"
										name="city"
										variant="filled"
										size="small"
										margin="normal"
									/>
								</FormGroup>

								<Button
									className="submitBtn"
									type="submit"
									variant="contained"
									color="primary"
								>
									{' '}
									<SaveIcon />
									&nbsp;UPDATE{' '}
								</Button>
							</fieldset>
						</Box>
					</>
				)
			)}
		</>
	)
}

export default EditDepot
