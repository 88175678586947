import React, { useState } from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

const CREATE_DEBIT_ENTRY = loader('./graphql/createDebitEntry.graphql')
const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')
const ALL_DEBIT_ITEMS = loader('./graphql/allDebitItems.graphql')

const CreateDebitEntry = () => {
	const currentUser = useCurrentUser()

	const [currencyId, setCurrencyId] = useState()
	const [debitItemId, setDebitItemId] = useState()

	const { data: { allCurrencies = null } = {}, loading: currenciesLoading } =
		useQuery(ALL_CURRENCIES)
	const { data: { allDebitItems = null } = {}, loading: debitItemsLoading } =
		useQuery(ALL_DEBIT_ITEMS)
	const [createDebitEntry, { data, error, loading }] =
		useMutation(CREATE_DEBIT_ENTRY)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { amount, date } = e.target
		return createDebitEntry({
			variables: {
				input: {
					amount: Number(amount.value),
					date: date.value,
					debitItemId: debitItemId,
					currencyId: currencyId,
				},
			},
		})
	}

	if (loading) return <Spinner />
	if (!currentUser) return <Redirect to="/" />

	if (data?.createDebitEntry && !error) {
		localStorage.setItem(
			'createDebitEntryState',
			'✔️ Debit Entry created successfully ! '
		)
		return <Redirect to="/debit-entry-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Debit Entry</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						{allCurrencies && (
							<Autocomplete
								getOptionLabel={(option) => option?.currency?.toString()}
								onChange={(event, value) => setCurrencyId(value?.id)}
								options={allCurrencies}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Currency"
										name="currency"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}
						{allDebitItems && (
							<Autocomplete
								getOptionLabel={(option) => option?.item}
								onChange={(event, value) => setDebitItemId(value?.id)}
								options={allDebitItems}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Debit Item"
										name="debitItem"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}
						<TextField
							label="Debit Amount"
							name="amount"
							variant="filled"
							size="small"
							margin="normal"
							type="number"
						/>
						<TextField
							label="Date"
							InputLabelProps={{ shrink: true }}
							name="date"
							variant="filled"
							size="small"
							margin="normal"
							type="date"
						/>
					</FormGroup>

					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						disabled={!currencyId || !debitItemId}
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateDebitEntry
