import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'

import SaveIcon from '@material-ui/icons/Save'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const BANK = loader('./graphql/bank.graphql')
const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')
const UPDATE_BANK = loader('./graphql/updateBank.graphql')

const EditBank = () => {
	const params = useParams() //get the bank ID
	const [currencyId, setCurrencyId] = useState()

	const { data: { bank = {} } = {}, loading: bankLoading } = useQuery(BANK, {
		variables: { id: params.id },
	})

	const [updateBank, { data, error, loading }] = useMutation(UPDATE_BANK)

	const { data: { allCurrencies = [] } = {}, loading: currenciesLoading } =
		useQuery(ALL_CURRENCIES)

	const { id } = params

	useEffect(() => {
		if (data) {
			window.location.reload()
		}
	}, [data])

	if (bankLoading || loading || currenciesLoading)
		return <Spinner style={{ marginTop: '400px' }} />

	if (!useCurrentUser) return <Redirect to="/" />

	if (data) {
		localStorage.setItem('bankState', '✔️ Bank updated successfully ! ')
		//return <Redirect to="/bank-list" />
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, location, iban, bic, holder } = e.target
		return updateBank({
			variables: {
				input: {
					id,
					currencyId: currencyId !== '' ? currencyId : bank.currency.id,
					name: name.value,
					location: location.value,
					IBAN: iban.value,
					BIC: bic.value,
					cardHolderName: holder.value,
				},
			},
		})
	}

	return (
		<>
			{error ? (
				<p>{error.message}</p>
			) : (
				bank && (
					<>
						<Box
							className="mainZone"
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div
								style={{
									width: '100%',
									height: '50px',
									backgroundColor: '#0c1836',
									marginBottom: '10px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h1 style={{ color: '#fff' }}>Edit Bank - {bank.name}</h1>
							</div>

							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										defaultValue={bank.name}
										label="Bank name"
										name="name"
										variant="filled"
										size="small"
										margin="normal"
									/>
									<TextField
										defaultValue={bank.cardHolderName}
										label="Card holder’s name"
										name="holder"
										variant="filled"
										size="small"
										margin="normal"
									/>
								</FormGroup>
								<FormGroup className="formGrp" row>
									<TextField
										defaultValue={bank.location}
										label="Location"
										name="location"
										variant="filled"
										size="small"
										margin="normal"
									/>

									{allCurrencies && (
										<Autocomplete
											defaultValue={bank && bank.currency}
											getOptionLabel={(option) => option?.currency}
											onChange={(event, value) => setCurrencyId(value?.id)}
											options={allCurrencies}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Currency"
													name="currency"
													variant="filled"
													size="small"
													margin="normal"
													required
												/>
											)}
										/>
									)}
								</FormGroup>
								<FormGroup className="formGrp" row>
									<TextField
										defaultValue={bank.IBAN}
										label="IBAN"
										name="iban"
										variant="filled"
										size="small"
										margin="normal"
									/>
									<TextField
										defaultValue={bank.BIC}
										label="BIC"
										name="bic"
										variant="filled"
										size="small"
										margin="normal"
									/>
								</FormGroup>

								<Button
									className="submitBtn"
									type="submit"
									variant="contained"
									color="primary"
								>
									{' '}
									<SaveIcon />
									&nbsp;UPDATE{' '}
								</Button>
							</fieldset>
						</Box>
					</>
				)
			)}
		</>
	)
}

export default EditBank
