import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'

import * as moment from 'moment'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_CONTRACTS = loader('./graphql/allContract.graphql')

export default function ContactList() {
	const {
		data: { allContracts = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_CONTRACTS)

	const columns = [
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-contract/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			filterable: false,
			minWidth: 150,
			hide: true,
			flex: 1,
		},
		{ field: 'number', headerName: 'Number', minWidth: 150, flex: 1 },
		{
			field: 'client',
			headerName: 'Company',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => value?.company,
		},
		{
			field: 'originPort',
			headerName: 'Origin port',
			minWidth: 200,
			flex: 1,
			valueGetter: ({ value }) => value?.name,
		},
		{
			field: 'destinationPort',
			headerName: 'Destination port',
			minWidth: 200,
			flex: 1,
			valueGetter: ({ value }) => value?.name,
		},
		{
			field: 'startDate',
			headerName: 'Start date',
			minWidth: 100,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD-MM-YYYY')
			},
		},
		{
			field: 'endDate',
			headerName: 'End Date',
			minWidth: 100,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD-MM-YYYY')
			},
		},
	]

	if (localStorage.getItem('contactState') !== '') {
		toast.success(localStorage.getItem('contactState'))
	}

	if (localStorage.getItem('createContactState') !== '') {
		toast.success(localStorage.getItem('createContactState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('contactState', '')
			localStorage.setItem('createContactState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allContracts])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allContracts && (
				<DataGrid
					rows={allContracts}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
