import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import Modal from '@mui/material/Modal'
import { Link } from 'react-router-dom'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import SaveIcon from '@material-ui/icons/Save'
import Ship from '@mui/icons-material/DirectionsBoatFilled'
import * as moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel'
import EventIcon from '@mui/icons-material/Event'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import PageviewIcon from '@mui/icons-material/Pageview'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import DirectionsIcon from '@mui/icons-material/Directions'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

// import EditIcon from '@material-ui/icons/BorderColor'
import '../../../asset/style.css'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
}

const ALL_ASSIGNEDCONTAINERS = loader('./graphql/allAssignedContainers.graphql')
const ALL_VOYAGES = loader('./graphql/allVoyages.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')
const VOYAGE = loader('./graphql/voyagesByDestinationAndDate.graphql')
const UPDATE_ASSIGNED_CONTAINERS_VOYAGE = loader(
	'./graphql/updateAssignedContainersVoyage.graphql'
)

export default function AssignedContainerList() {
	const [selectionModel, setSelectionModel] = useState([])
	const [open, setOpen] = useState(false)
	const [newVoyage, setNewVoyage] = useState()
	const [voyageValues, setVoyageValues] = useState({})

	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const [
		updateAssignedContainersVoyage,
		{
			data: updateAssignedContainersVoyageData = null,
			error: uupdateAssignedContainersVoyageError,
			loading: bolLoading,
		},
	] = useMutation(UPDATE_ASSIGNED_CONTAINERS_VOYAGE)

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const [
		getVoyages,
		{ data: { voyagesByDestinationAndDate = null } = {}, voyageLoading },
	] = useLazyQuery(VOYAGE)

	const {
		data: { allAssignedContainers = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_ASSIGNEDCONTAINERS)

	const { data: { allVoyages = null } = {}, loading: voygaeLoading } =
		useQuery(ALL_VOYAGES)

	const handleFetchVoyages = () => {
		return getVoyages({
			variables: voyageValues,
		})
	}

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					onClick={handleOpen}
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<ModeOfTravelIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Bulk update voyage
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			disableFilter: true,
		},
		{
			field: 'container.number',
			flex: 1,
			headerName: 'Container No.',
			minWidth: 130,
			valueGetter: (params) => params.row.container.number,
		},
		{
			field: 'container.type',
			flex: 1,
			headerName: 'Container type',
			minWidth: 130,
			valueGetter: (params) => params.row.container.type.type,
		},
		{
			field: 'container.status',
			flex: 1,
			headerName: 'Container No.',
			minWidth: 130,
			valueGetter: (params) => params.row.container.status.status.toUpperCase(),
		},
		{
			field: 'booking',
			flex: 1,
			headerName: 'Booking No.',
			minWidth: 200,
			valueGetter: ({ value }) => value?.number,
		},
		{
			field: 'voyage',
			flex: 1,
			headerName: 'Voyage No.',
			minWidth: 200,
			valueGetter: ({ value }) => value?.number,
		},
		{
			field: 'vgm',
			flex: 1,
			headerName: 'VGM',
			minWidth: 200,
			valueGetter: ({ row }) => row.vgm,
		},
	]

	if (localStorage.getItem('containerState') !== '') {
		toast.success(localStorage.getItem('containerState'))
	}

	if (localStorage.getItem('CreateContainerState') !== '') {
		toast.success(localStorage.getItem('CreateContainerState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('containerState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('CreateContainerState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		if (updateAssignedContainersVoyageData?.updateAssignedContainersVoyage) {
			refetch()
			setOpen(false)
		}
	}, [updateAssignedContainersVoyageData])

	const handleUpdateContainersVoyage = () => {
		return updateAssignedContainersVoyage({
			variables: {
				input: {
					containerTypeIds: selectionModel,
					voyageId: newVoyage,
				},
			},
		})
	}

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allAssignedContainers && (
				<DataGrid
					checkboxSelection
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel)
					}}
					selectionModel={selectionModel}
					rows={allAssignedContainers}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}

			<Modal open={open} onClose={handleClose}>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Select a voyage
					</Typography>
					{selectionModel.length !== 0 ? (
						<>
							<FormGroup className="formGrp" row>
								{allPorts && (
									<Autocomplete
										options={allPorts}
										filterOptions={filterOptions}
										onChange={(event, value) =>
											setVoyageValues({
												...voyageValues,
												loadingPortId: value?.id,
											})
										}
										getOptionLabel={(option) => option.name}
										renderOption={(props, option) => (
											<Box
												key={option.id}
												component="li"
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}
											>
												&nbsp;&nbsp;{option.name}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Location of departure"
												variant="filled"
												size="small"
												required
												margin="dense"
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
									/>
								)}
								<DirectionsIcon
									style={{
										alignSelf: 'center',
										marginLeft: '0px',
										marginRight: '0px',
										paddingLeft: '0px',
									}}
								/>
								{allPorts && (
									<Autocomplete
										onChange={(event, value) =>
											setVoyageValues({
												...voyageValues,
												unLoadingPortId: value?.id,
											})
										}
										filterOptions={filterOptions}
										options={allPorts}
										getOptionLabel={(option) => option.name}
										renderOption={(props, option) => (
											<Box
												key={option.id}
												component="li"
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}
											>
												&nbsp;&nbsp;{option.name}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												required
												label="location of arrival"
												variant="filled"
												size="small"
												margin="dense"
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
									/>
								)}
								<AccessTimeFilledIcon
									style={{
										alignSelf: 'center',
										marginLeft: '0px',
										marginRight: '0px',
										paddingLeft: '0px',
									}}
								/>
								<TextField
									label="Date"
									variant="filled"
									type="date"
									onChange={({ target: { value } }) => {
										setVoyageValues({ ...voyageValues, date: value })
									}}
									InputProps={{
										inputProps: { min: new Date().toISOString().slice(0, 10) },
									}}
									size="small"
									margin="dense"
									InputLabelProps={{ shrink: true }}
								/>

								<Button
									disabled={
										typeof voyageValues.loadingPortId === 'undefined' ||
										voyageValues.loadingPortId === '' ||
										typeof voyageValues.unLoadingPortId === 'undefined' ||
										voyageValues.unLoadingPortId === '' ||
										typeof voyageValues.date === 'undefined' ||
										voyageValues.date === ''
											? true
											: false
									}
									variant="contained"
									color="primary"
									onClick={handleFetchVoyages}
								>
									<PageviewIcon /> &nbsp;Search for a voyage
								</Button>
							</FormGroup>
							{voyagesByDestinationAndDate && (
								<FormControl className="formGrp" row>
									<Box id="voyageListingContainer" fullwidth>
										<FormLabel id="demo-radio-buttons-group-label">
											{voyagesByDestinationAndDate.length !== 0 ? (
												'Select a voyage'
											) : (
												<span style={{ display: 'flex', alignItems: 'center' }}>
													{' '}
													<ReportProblemIcon /> &nbsp;&nbsp; No result found.
													Please adjust search filters.
												</span>
											)}
										</FormLabel>
										<RadioGroup
											fullwidth
											name="radio-buttons-group"
											id="voyageListing"
											onChange={({ target: { value } }) => {
												setNewVoyage(value)
											}}
										>
											{voyagesByDestinationAndDate.map(
												(
													{
														arrivalDate,
														departureDate,
														transit,
														ship,
														transitDays,
														id,
													},
													i
												) => {
													return (
														<FormControlLabel
															key={id}
															className={
																Number(i + 1) % 2 === 1
																	? 'voyageElemModulo'
																	: 'voyageElem'
															}
															value={id}
															color="primary"
															control={<Radio />}
															label={
																<span
																	style={{
																		display: 'flex',
																		alignContent: 'center',
																	}}
																>
																	<div
																		style={{
																			display: 'flex',
																			alignContent: 'center',
																			marginRight: '50px',
																			marginLeft: '20px',
																		}}
																	>
																		<EventIcon /> {''}&nbsp;
																		{moment(
																			new Date(Number(departureDate))
																		).format('DD-MM-YYYY')}{' '}
																		<ArrowForwardIosIcon />{' '}
																		{moment(
																			new Date(Number(arrivalDate))
																		).format('DD-MM-YYYY')}
																	</div>
																	<div
																		style={{
																			display: 'flex',
																			alignContent: 'center',
																			marginRight: '50px',
																		}}
																	>
																		<TimelapseIcon /> {''} &nbsp;{transitDays}{' '}
																		days
																	</div>
																	<div
																		style={{
																			display: 'flex',
																			alignContent: 'center',
																			marginRight: '50px',
																		}}
																	>
																		<DirectionsBoatFilledIcon /> {''} &nbsp;
																		{ship.name}
																	</div>
																	{transit !== '' ? (
																		<div
																			style={{
																				display: 'flex',
																				alignContent: 'center',
																			}}
																		>
																			<AirlineStopsIcon /> {''} &nbsp;{' '}
																			{transit?.name}
																		</div>
																	) : (
																		''
																	)}
																</span>
															}
														/>
													)
												}
											)}
										</RadioGroup>
									</Box>
								</FormControl>
							)}
							<Button
								style={{ display: 'block' }}
								disabled={newVoyage && selectionModel ? false : true}
								className="submitBtn"
								variant="contained"
								color="primary"
								onClick={handleUpdateContainersVoyage}
							>
								<SaveIcon /> UPDATE
							</Button>
						</>
					) : (
						<section
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<WarningAmberIcon style={{ fontSize: '40' }} />
							<p style={{ fontSize: '30' }}>
								Please select at least one container
							</p>
						</section>
					)}
				</Box>
			</Modal>
		</>
	)
}
