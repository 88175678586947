import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import Box from '@material-ui/core/Box'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const CLIENT = loader('./graphql/client.graphql')
const UPDATE_CLIENT = loader('./graphql/updateClient.graphql')
const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')

const EditClient = () => {
	const params = useParams() //get the Booking ID
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})
	const [country, setCountry] = useState()
	const [inputLengths, setInputLengths] = useState({
		name: 0,
		company: 0,
		email: 0,
		address: 0,
		taxNumber: 0,
		phone: 0,
		postalCode: 0,
		city: 0,
		country: 0,
	})

	const {
		data: { client = {} } = {},
		loading: clientLoading,
		refetch,
	} = useQuery(CLIENT, {
		variables: { id: params.id },
	})
	const { data: { allCountries = null } = {}, loading: allCountriesLoading } =
		useQuery(ALL_COUNTRIES)
	const [updateClient, { data, error, loading }] = useMutation(UPDATE_CLIENT)

	const handleChangeNewClient = (e) => {
		const { value, name } = e.target
		setInputLengths((prevInputLengths) => ({
			...prevInputLengths,
			[name]: value.length,
		}))
	}

	useEffect(() => {
		setInputLengths({
			name: client?.name && client.name.length,
			company: client?.company && client.company.length,
			email: client?.email && client.email.length,
			address: client?.address && client.address.length,
			taxNumber: client?.taxNumber && client.taxNumber.length,
			phone: client?.phone && client.phone.length,
			postalCode: client?.postalCode && client.postalCode.length,
			city: client?.city && client.city.length,
			country: client?.country && client.country.length,
		})
	}, [client])

	useEffect(() => {
		refetch()
	}, [client])

	useEffect(() => {
		if (data) {
			window.location.reload()
		}
	}, [data])

	const { id } = params

	if (clientLoading || loading)
		return <Spinner style={{ marginTop: '400px' }} />

	if (data) {
		localStorage.setItem('contactState', '✔️ Contact updated successfully ! ')
	}

	const handleSubmit = (e, v) => {
		e.preventDefault()
		const {
			active,
			name,
			company,
			email,
			address,
			language,
			currency,
			taxNumber,
			phone,
			city,
			postalCode,
		} = e.target
		return updateClient({
			variables: {
				input: {
					active: active.value === 'true' ? true : false,
					id,
					company: company.value,
					name: name.value,
					address: address.value,
					email: email.value,
					language: language.value,
					currency: currency.value,
					taxNumber: taxNumber.value,
					phone: phone.value,
					country: country || client.country,
					city: city.value,
					postalCode: postalCode.value,
				},
			},
		})
	}

	return (
		<>
			{loading ? (
				<Spinner style={{ marginTop: '400px', color: 'secondary' }} />
			) : error ? (
				<p>{error.message}</p>
			) : (
				<>
					<Box
						className="mainZone"
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div
							style={{
								width: '100%',
								height: '50px',
								backgroundColor: '#0c1836',
								marginBottom: '10px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1 style={{ color: '#fff' }}>Edit Contact</h1>
						</div>
						<fieldset>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={client.name}
									label="Name"
									name="name"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 30 }}
									helperText={`${inputLengths.name}/30`}
									onChange={handleChangeNewClient}
								/>
								<TextField
									defaultValue={client.company}
									style={{ width: '400px' }}
									label="Company"
									name="company"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 40 }}
									helperText={`${inputLengths.company}/40`}
									onChange={handleChangeNewClient}
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={client.email}
									label="E-mail"
									name="email"
									type="email"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 30 }}
									helperText={`${inputLengths.email}/30`}
									onChange={handleChangeNewClient}
								/>
								<FormControl
									className="formSelect"
									variant="filled"
									size="small"
									margin="normal"
								>
									<InputLabel>Language</InputLabel>
									<Select
										labelId="Language"
										name="language"
										defaultValue={client.language}
									>
										<MenuItem key="en" value="en">
											EN
										</MenuItem>
										<MenuItem key="fr" value="fr">
											FR
										</MenuItem>
									</Select>
								</FormControl>
								<FormControl
									className="formSelect"
									variant="filled"
									size="small"
									margin="normal"
								>
									<InputLabel>Currency</InputLabel>
									<Select
										labelId="Currency"
										name="currency"
										defaultValue={client.currency}
									>
										<MenuItem key="eur" value="eur">
											EUR
										</MenuItem>
										<MenuItem key="usd" value="usd">
											USD
										</MenuItem>
									</Select>
								</FormControl>
								<FormControl
									className="formSelect"
									variant="filled"
									size="small"
									margin="normal"
								>
									<InputLabel>Active</InputLabel>
									<Select
										labelId="Active"
										name="active"
										defaultValue={client.active}
									>
										<MenuItem key="active" value={true}>
											Active
										</MenuItem>
										<MenuItem key="notActive" value={false}>
											Not Active
										</MenuItem>
									</Select>
								</FormControl>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={client.phone}
									label="Phone"
									name="phone"
									type="tel"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 20 }}
									helperText={`${inputLengths.phone}/20`}
									onChange={handleChangeNewClient}
								/>
								<TextField
									defaultValue={client.taxNumber}
									label="Tax ID"
									name="taxNumber"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 15 }}
									helperText={`${inputLengths.taxNumber}/15`}
									onChange={handleChangeNewClient}
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={client.address}
									fullWidth
									label="Address"
									name="address"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 65 }}
									helperText={`${inputLengths.address}/65`}
									onChange={handleChangeNewClient}
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={client.postalCode}
									label="Postal code"
									name="postalCode"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 10 }}
									helperText={`${inputLengths.postalCode}/10`}
									onChange={handleChangeNewClient}
								/>
								<TextField
									defaultValue={client.city}
									label="City"
									name="city"
									variant="filled"
									size="small"
									margin="normal"
									inputProps={{ maxLength: 30 }}
									helperText={`${inputLengths.city}/30`}
									onChange={handleChangeNewClient}
								/>
								{allCountries && (
									<Autocomplete
										defaultValue={client.country}
										filterOptions={filterOptions}
										style={{ width: '40%' }}
										sx={{ width: '40% !important' }}
										getOptionLabel={(option) => option}
										options={allCountries}
										onChange={(event, value) => {
											setCountry(value)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Depot"
												variant="filled"
												size="small"
												margin="normal"
											/>
										)}
									/>
								)}
							</FormGroup>
							<Button
								className="submitBtn"
								type="submit"
								variant="contained"
								color="primary"
							>
								{' '}
								<SaveIcon />
								&nbsp;UPDATE{' '}
							</Button>
						</fieldset>
					</Box>
				</>
			)}
		</>
	)
}

export default EditClient
