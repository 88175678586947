import React, { useState, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Autocomplete from '@mui/material/Autocomplete'
import { Button } from '@material-ui/core'

import * as moment from 'moment'

import '../../../asset/style.css'

const TRACK_VESSEL_VOYAGE = loader('./graphql/vesselTrackByIdAndVoyage.graphql')
const ALL_VESSELS = loader('./graphql/allVessels.graphql')
const ALL_VOYAGES = loader('./graphql/allVoyages.graphql')

const TrackVesselByVoyage = () => {
	const [vesselId, setVesselId] = useState('')
	const [voyageId, setVoyageId] = useState('')
	const [formError, setFormError] = useState(false)

	const {
		data: { allVoyages = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_VOYAGES)

	const [
		getHistory,
		{ data = null, loading: vesselLoading, error: vesselError },
	] = useLazyQuery(TRACK_VESSEL_VOYAGE)

	const { data: { allVessels = null } = {}, loading: vesselsLoading } =
		useQuery(ALL_VESSELS)

	const handleFetchVesselHistory = (e) => {
		e.preventDefault()
		if (vesselId && voyageId) {
			setFormError(false)
			const VesselHistory = getHistory({
				variables: {
					id: vesselId,
					voyageNumber: voyageId,
				},
			})
			return VesselHistory
		} else {
			setFormError(true)
		}
	}

	useEffect(() => {
		refetch()
	}, [allVoyages])

	return (
		<>
			<Box className="mainZone" component="form" noValidate autoComplete="off">
				<section className="bookingSearchSection">
					{allVessels && (
						<Autocomplete
							onChange={(event, value) => setVesselId(value?.id)}
							options={allVessels}
							getOptionLabel={(option) => option.name}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.name}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Ship name"
									name="shipName"
									variant="filled"
									size="small"
									margin="normal"
									required
								/>
							)}
						/>
					)}
					{allVoyages && (
						<Autocomplete
							onChange={(event, value) => setVoyageId(value?.id)}
							options={allVoyages}
							getOptionLabel={(option) => option.number}
							renderOption={(props, option) => (
								<Box
									key={option.id}
									component="li"
									sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
									{...props}
								>
									&nbsp;&nbsp;{option.name}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Voyage number"
									name="shipName"
									variant="filled"
									size="small"
									margin="normal"
									required
								/>
							)}
						/>
					)}
					<Button
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleFetchVesselHistory}
						style={{ marginLeft: '20px' }}
					>
						Search
					</Button>
				</section>

				{formError && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						Please select a vessel and a voyage No
					</Paper>
				)}
				{vesselError && vesselError?.graphQLErrors[0]?.message && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						ERROR: {vesselError?.graphQLErrors[0]?.message}
					</Paper>
				)}
				{vesselLoading && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						Loading...
					</Paper>
				)}

				{data &&
					data.vesselTrackByIdAndVoyage &&
					data.vesselTrackByIdAndVoyage.length === 0 && (
						<Paper
							variant="outlined"
							style={{ width: '100%', padding: '10px' }}
						>
							No results
						</Paper>
					)}

				{data &&
					data.vesselTrackByIdAndVoyage &&
					data.vesselTrackByIdAndVoyage.length !== 0 && (
						<>
							<Paper variant="outlined" className="containerDetailsHistory">
								<span>
									<b>Container No. </b>
								</span>
								<span>
									<b>ISO No. </b>
								</span>
								<span>
									<b>Type: </b>
								</span>
							</Paper>

							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }}>
									<TableHead>
										<TableRow>
											<TableCell component="th">Date</TableCell>
											<TableCell component="th">State</TableCell>
											<TableCell component="th">Port</TableCell>
											<TableCell component="th">Updated by</TableCell>
											<TableCell component="th">note</TableCell>
											<TableCell component="th">booking</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.vesselTrackByIdAndVoyage.map((elm) => {
											return (
												<TableRow key={elm.id} hover>
													<TableCell>
														{/* {moment(elm.date).format('DD/MM/YYYY hh:mm:ss')} */}
													</TableCell>
													<TableCell></TableCell>
													<TableCell>
														{/* {elm.port?.name ? elm.port?.name : 'N/A'} */}
													</TableCell>
													<TableCell>
														{/* {elm.user?.name ? elm.user?.name : 'N/A'} */}
													</TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}
			</Box>
		</>
	)
}

export default TrackVesselByVoyage
