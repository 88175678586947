import * as React from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import Cookies from 'js-cookie'
// import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import Tooltip from '@material-ui/core/Tooltip'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import Badge from '@mui/material/Badge'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Popover from '@mui/material/Popover'

import LoadingBar from '../../UI/Spinner'

//icons
import LogoutBT from '@material-ui/icons/ExitToApp'

import '../../../asset/style.css'

const CURRENT_USER = loader('./graphql/currentUser.graphql')

const Logout = ({ client }) => {
	const { data: { currentUser = null } = {}, loading } = useQuery(CURRENT_USER)
	const [isfullscreen, setFullscreen] = React.useState(true)

	const handleLogout = () => {
		client.resetStore()
		return Cookies.remove('token')
	}

	function toggleFullScreen(elem) {
		// ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
		if (
			(document.fullScreenElement !== undefined &&
				document.fullScreenElement === null) ||
			(document.msFullscreenElement !== undefined &&
				document.msFullscreenElement === null) ||
			(document.mozFullScreen !== undefined && !document.mozFullScreen) ||
			(document.webkitIsFullScreen !== undefined &&
				!document.webkitIsFullScreen)
		) {
			setFullscreen(false)
			if (elem.requestFullScreen) {
				elem.requestFullScreen()
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen()
			} else if (elem.webkitRequestFullScreen) {
				elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen()
			}
		} else {
			setFullscreen(true)
			if (document.cancelFullScreen) {
				document.cancelFullScreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			}
		}
	}

	if (loading) return <LoadingBar />

	return (
		<>
			<div
				style={{
					marginRight: '0px',
					display: 'flex',
					marginLeft: 'auto',
					alignItems: 'center',
					textTransform: 'capitalize',
				}}
			>
				<Badge
					badgeContent={4}
					color="warning"
					style={{
						marginRight: '20px',
					}}
				>
					<NotificationsIcon color="info" />
				</Badge>

				<AssignmentIndIcon />
				{currentUser ? currentUser.userName : ''}
			</div>

			{isfullscreen ? (
				<Tooltip title="Full Screen">
					<FullscreenIcon
						style={{ cursor: 'pointer', marginLeft: '10px' }}
						onClick={() => {
							toggleFullScreen(document.body)
						}}
					/>
				</Tooltip>
			) : (
				<Tooltip title="Exit Full Screen">
					<FullscreenExitIcon
						style={{ cursor: 'pointer', marginLeft: '10px' }}
						onClick={() => {
							toggleFullScreen(document.body)
						}}
					/>
				</Tooltip>
			)}
			<Tooltip title={currentUser ? 'Logout' : 'Login'}>
				<Link
					to="/"
					style={{
						marginRight: '0px',
						cursor: 'pointer',
						width: '50px !important',
					}}
				>
					<IconButton onClick={handleLogout}>
						<LogoutBT color="secondary" />
					</IconButton>
				</Link>
			</Tooltip>
		</>
	)
}

export default withApollo(Logout)
