import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'

import Autocomplete from '@mui/material/Autocomplete'
import SaveIcon from '@material-ui/icons/Save'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const PORT = loader('./graphql/port.graphql')
const UPDATE_PORT = loader('./graphql/editPort.graphql')
const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')

const EditPort = () => {
	const params = useParams() //get the Port ID
	const [currencyId, setCurrencyId] = useState()

	const {
		data: { port = {} } = {},
		loading: portLoading,
		refetch,
	} = useQuery(PORT, {
		variables: { id: params.id },
	})

	const { data: { allCurrencies = null } = {}, loading } =
		useQuery(ALL_CURRENCIES)

	const [updatePort, { data, error, loading: currencyLoading }] =
		useMutation(UPDATE_PORT)

	useEffect(() => {
		refetch()
	}, [port])

	useEffect(() => {
		if (data) {
			localStorage.setItem('portState', '✔️ Port updated successfully ! ')
			window.location.reload()
		}
	}, [data])

	const { id } = params

	if (portLoading || loading) return <Spinner style={{ marginTop: '400px' }} />

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			name,
			abbreviation,
			city,
			country,
			timezone,
			code,
			detention,
			demurrage,
		} = e.target
		return updatePort({
			variables: {
				input: {
					id,
					name: name.value,
					abbreviation: abbreviation.value,
					city: city.value,
					country: country.value,
					timezone: timezone.value,
					code: code.value,
					currencyId,
					detention: Number(detention.value),
					demurrage: Number(demurrage.value),
				},
			},
		})
	}

	return (
		<>
			{loading ? (
				<Spinner style={{ marginTop: '400px', color: 'secondary' }} />
			) : error ? (
				<p>{error.message}</p>
			) : (
				<>
					<Box
						className="mainZone"
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div
							style={{
								width: '100%',
								height: '50px',
								backgroundColor: '#0c1836',
								marginBottom: '10px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1 style={{ color: '#fff' }}>Edit Port</h1>
						</div>
						<fieldset>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={port.name}
									label="Port name"
									name="name"
									variant="filled"
									size="small"
									margin="normal"
									disabled
								/>
								<TextField
									defaultValue={port.abbreviation}
									label="Abbreviation"
									name="abbreviation"
									variant="filled"
									size="small"
									margin="normal"
									disabled
								/>

								<TextField
									style={{ textTransform: 'capitalize !important' }}
									defaultValue={port.city}
									label="City"
									name="city"
									variant="filled"
									size="small"
									margin="normal"
									disabled
								/>
								<TextField
									defaultValue={port.country}
									label="Country"
									name="country"
									variant="filled"
									size="small"
									margin="normal"
									disabled
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={port.timezone}
									label="Timezone"
									name="timezone"
									variant="filled"
									size="small"
									margin="normal"
									disabled
								/>
								<TextField
									defaultValue={port.code}
									label="Code"
									name="code"
									variant="filled"
									size="small"
									margin="normal"
									disabled
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									defaultValue={port.detention}
									label="Detention"
									name="detention"
									variant="filled"
									size="small"
									margin="normal"
									type="number"
									InputProps={{
										inputProps: { min: 0 },
									}}
									onWheel={(e) => e.target.blur()}
								/>
								<TextField
									defaultValue={port.demurrage}
									label="Demurrage"
									name="demurrage"
									variant="filled"
									size="small"
									margin="normal"
									type="number"
									InputProps={{
										inputProps: { min: 0 },
									}}
									onWheel={(e) => e.target.blur()}
								/>
								<Autocomplete
									defaultValue={port.currency}
									onChange={(event, value) => setCurrencyId(value?.id)}
									options={allCurrencies}
									getOptionLabel={(option) => option.currency}
									renderOption={(props, option) => (
										<Box key={option.id} component="li" {...props}>
											&nbsp;&nbsp;{option.currency}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Currency"
											name="currencyId"
											variant="filled"
											size="small"
											margin="dense"
										/>
									)}
								/>
							</FormGroup>
							<Button
								className="submitBtn"
								type="submit"
								variant="contained"
								color="primary"
							>
								{' '}
								<SaveIcon />
								&nbsp;UPDATE{' '}
							</Button>
						</fieldset>
					</Box>
				</>
			)}
		</>
	)
}

export default EditPort
