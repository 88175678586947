import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './app/App'
import * as serviceWorker from './serviceWorker'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import {
	ApolloProvider,
	ApolloClient,
	InMemoryCache,
	ApolloLink,
} from '@apollo/client'
import Cookies from 'js-cookie'
import { onError } from '@apollo/client/link/error'

// const { hostname, port } = window.location

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = Cookies.get('token')
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? token : '',
		},
	}
})

const uploadLink = createUploadLink({
	// credentials: 'same-origin', // Send the cookie along with every request
	uri: '/graphql',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		)
	if (networkError) console.log(`[Network error]: ${networkError}`)
})

// const httpLink = new ApolloLink.from([
// 	new HttpLink({
// 		// credentials: 'include',
// 		uri: '/graphql',
// 	}),
// ])

const client = new ApolloClient({
	cache: new InMemoryCache(),
	defaultOptions: {
		mutate: { errorPolicy: 'all' },
		query: { errorPolicy: 'all', partialRefetch: true },
		watchQuery: { errorPolicy: 'all' },
	},
	link: ApolloLink.from([authLink, uploadLink, errorLink]),
	// resolvers,
	shouldBatch: true,
	// typeDefs
})

// ReactPDF.render(<PrintOrder />, `${__dirname}/example.pdf`)

ReactDOM.render(
	<ApolloProvider client={client}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ApolloProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
