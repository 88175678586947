import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import '../../../asset/style.css'

const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')
const CREATE_CLIENT = loader('./graphql/createClient.graphql')

const CreateContact = () => {
	const [CreateClient, { data, error, loading }] = useMutation(CREATE_CLIENT)
	const { data: { allCountries = [] } = {}, loading: countriesLoading } =
		useQuery(ALL_COUNTRIES)
	const [country, setCountry] = useState('')
	const [inputLengths, setInputLengths] = useState({
		name: 0,
		company: 0,
		email: 0,
		address: 0,
		taxNumber: 0,
		phone: 0,
		postalCode: 0,
		city: 0,
		country: 0,
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			name,
			company,
			email,
			address,
			language,
			currency,
			postalCode,
			city,
			taxNumber,
			phone,
		} = e.target
		return CreateClient({
			variables: {
				input: {
					company: company.value,
					name: name.value,
					address: address.value,
					email: email.value,
					language: language.value,
					currency: currency.value,
					postalCode: postalCode.value,
					city: city.value,
					country,
					taxNumber: taxNumber.value,
					phone: phone.value,
				},
			},
		})
	}

	const handleChangeNewClient = (e) => {
		const { value, name } = e.target
		setInputLengths((prevInputLengths) => ({
			...prevInputLengths,
			[name]: value.length,
		}))
	}

	if (loading) return <Spinner />

	if (data && !error) {
		localStorage.setItem(
			'createContactState',
			'✔️ Contact created successfully ! '
		)
		return <Redirect to="/contact-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Contact</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Name"
							name="name"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 30 }}
							helperText={`${inputLengths.name}/30`}
							onChange={handleChangeNewClient}
						/>
						<TextField
							label="Company"
							style={{ width: '400px' }}
							name="company"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 40 }}
							helperText={`${inputLengths.company}/40`}
							onChange={handleChangeNewClient}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="E-mail"
							name="email"
							type="email"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 30 }}
							helperText={`${inputLengths.email}/30`}
							onChange={handleChangeNewClient}
						/>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="normal"
						>
							<InputLabel>Language</InputLabel>
							<Select labelId="Language" name="language" defaultValue="en">
								<MenuItem key="en" value="en">
									EN
								</MenuItem>
								<MenuItem key="fr" value="fr">
									FR
								</MenuItem>
							</Select>
						</FormControl>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="normal"
						>
							<InputLabel>Currency</InputLabel>
							<Select labelId="Currency" name="currency" defaultValue="eur">
								<MenuItem key="eur" value="eur">
									EUR | €
								</MenuItem>
								<MenuItem key="usd" value="usd">
									USD | $
								</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Phone"
							name="phone"
							type="tel"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 20 }}
							helperText={`${inputLengths.phone}/20`}
							onChange={handleChangeNewClient}
						/>
						<TextField
							label="Tax ID"
							name="taxNumber"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 15 }}
							helperText={`${inputLengths.taxNumber}/15`}
							onChange={handleChangeNewClient}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							fullWidth
							label="Address"
							name="address"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 65 }}
							helperText={`${inputLengths.address}/65`}
							onChange={handleChangeNewClient}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Postal code"
							name="postalCode"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 10 }}
							helperText={`${inputLengths.postalCode}/10`}
							onChange={handleChangeNewClient}
						/>
						<TextField
							label="City"
							name="city"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 30 }}
							helperText={`${inputLengths.city}/30`}
							onChange={handleChangeNewClient}
						/>
						{/* <TextField
							label="Country"
							name="country"
							variant="filled"
							size="small"
							margin="normal"
							inputProps={{ maxLength: 20 }}
							helperText={`${inputLengths.country}/20`}
							onChange={handleChangeNewClient}
						/> */}
						{allCountries && (
							<Autocomplete
								getOptionLabel={(option) => option.toUpperCase()}
								onChange={(event, value) => setCountry(value)}
								options={allCountries}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Country"
										name="Country"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}
					</FormGroup>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateContact
