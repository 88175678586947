import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button } from '@material-ui/core'

import * as moment from 'moment'

import '../../../asset/style.css'

const TRACK_CONTAINER = loader(
	'./graphql/containerTrackByContainerNumber.graphql'
)

const TrackByContainer = () => {
	const [containerNumber, setContainerNumber] = useState('')

	const [
		getHistory,
		{ data = null, loading: containerLoading, error: containerError },
	] = useLazyQuery(TRACK_CONTAINER)

	const handleFetchContainerHistory = (e) => {
		e.preventDefault()
		const ContainerHistory = getHistory({
			variables: {
				number: containerNumber,
			},
		})

		return ContainerHistory
	}

	return (
		<>
			<Box className="mainZone" component="form" noValidate autoComplete="off">
				<section className="bookingSearchSection">
					<TextField
						fullWidth
						label="Track container"
						variant="filled"
						size="small"
						margin="normal"
						defaultValue={containerNumber}
						onChange={({ target: { value } }) => {
							setContainerNumber(value)
						}}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleFetchContainerHistory}
						style={{ marginLeft: '20px' }}
					>
						Search
					</Button>
				</section>
				{containerError && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						{containerError.graphQLErrors[0].message}
					</Paper>
				)}
				{containerLoading && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						Loading...
					</Paper>
				)}

				{data && data.containerTrackByContainerNumber && (
					<>
						<Paper variant="outlined" className="containerDetailsHistory">
							<span>
								<b>Container No. </b>
								{data?.containerTrackByContainerNumber[0]?.container.number}
							</span>
							<span>
								<b>ISO No. </b>
								{data?.containerTrackByContainerNumber[0]?.container.isoNumber}
							</span>
							<span>
								<b>Type: </b>
								{data?.containerTrackByContainerNumber[0]?.container.type.type}
							</span>
						</Paper>

						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }}>
								<TableHead>
									<TableRow>
										<TableCell component="th">Date</TableCell>
										<TableCell component="th">State</TableCell>
										<TableCell component="th">Port</TableCell>
										<TableCell component="th">Depot</TableCell>
										<TableCell component="th">Updated by</TableCell>
										<TableCell component="th">booking</TableCell>
										<TableCell component="th">voyage</TableCell>
										<TableCell component="th">ship</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.containerTrackByContainerNumber.map((elm) => {
										return (
											<TableRow key={elm.id} hover>
												<TableCell>
													{moment(Number(elm.createdAt)).format(
														'DD/MM/YYYY hh:mm:ss'
													)}
												</TableCell>
												<TableCell>{elm.status.status}</TableCell>
												<TableCell>
													{elm.port?.name ? elm.port?.name : 'N/A'}
												</TableCell>
												<TableCell>
													{elm.depot?.name ? elm.depot?.name : 'N/A'}
												</TableCell>
												<TableCell>
													{elm.user?.name ? elm.user?.name : 'N/A'}
												</TableCell>
												<TableCell>{elm.booking?.number || 'N/A'}</TableCell>
												<TableCell>
													{elm.booking?.voyage?.number || 'N/A'}
												</TableCell>
												<TableCell>
													{elm.booking?.voyage?.ship?.name || 'N/A'}
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				)}
			</Box>
		</>
	)
}

export default TrackByContainer
