import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'
import InputAdornment from '@material-ui/core/InputAdornment'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import '../../../asset/style.css'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

const CREATE_BANK = loader('./graphql/createCurrency.graphql')

const CreateCurrency = () => {
	const [CreateBank, { data, error, loading }] = useMutation(CREATE_BANK)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { currency, inEuro, inUsd, symbol } = e.target
		return CreateBank({
			variables: {
				input: {
					currency: currency.value,
					inEuro: Number(inEuro.value),
					inUsd: Number(inUsd.value),
					symbol: symbol.value,
				},
			},
		})
	}

	if (loading) return <Spinner />

	if (data && !error) {
		localStorage.setItem(
			'createCurrencyState',
			'✔️ Currency created successfully ! '
		)
		return <Redirect to="/currency-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Currency</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Currency"
							name="currency"
							variant="filled"
							size="small"
							margin="normal"
						/>

						<TextField
							label="Symbol/Abbreviation"
							name="symbol"
							variant="filled"
							size="small"
							margin="normal"
						/>

						<TextField
							label="In Euro"
							name="inEuro"
							variant="filled"
							size="small"
							margin="normal"
							type="number"
							InputProps={{
								inputProps: { min: 0 },
								endAdornment: (
									<InputAdornment position="end">
										<EuroSymbolIcon />
									</InputAdornment>
								),
							}}
							onWheel={(e) => e.target.blur()}
						/>

						<TextField
							label="In Usd"
							name="inUsd"
							variant="filled"
							size="small"
							margin="normal"
							type="number"
							InputProps={{
								inputProps: { min: 0 },
								endAdornment: (
									<InputAdornment position="end">
										<AttachMoneyIcon />
									</InputAdornment>
								),
							}}
							onWheel={(e) => e.target.blur()}
						/>
					</FormGroup>

					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateCurrency
