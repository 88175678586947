import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../currentUserContext'
import { Link } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Chip from '@mui/material/Chip'
import { InputAdornment } from '@mui/material'

import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

import Modal from '@mui/material/Modal'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import CompanyIcon from '@mui/icons-material/Apartment'
import EmployeeIcon from '@mui/icons-material/Badge'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import Spinner from '../../UI/Spinner'

import '../../../asset/style.css'

const BOOKING = loader('./graphql/booking.graphql')
const ALL_CLIENTS = loader('./graphql/allClients.graphql')
const CREATE_BILL_OF_LANDING = loader('./graphql/createBillOfLanding.graphql')
const CREATE_CLIENT = loader('./graphql/createClient.graphql')

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50vw',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
}

export default function CreateBillOfLanding() {
	const currentUser = useCurrentUser()
	const { id } = useParams()
	const [inputLengths, setInputLengths] = useState({
		newname: 0,
		newcompany: 0,
		newemail: 0,
		newaddress: 0,
		newtaxNumber: 0,
		newphone: 0,
		newpostalCode: 0,
		newcity: 0,
		newcountry: 0,
	})
	const [values, setValues] = useState({
		csac: '',
		svcContract: '',
		exportReference: '',
		oiRouting: 'Draft',
		freightPrepaid: '',
		freightPayableAt: 'Origin',
		dateOfIssue: '',
		numberOfOriginalBOL: 'One (1/1)',
		shipper: '',
		notifyParty: null,
		consignees: null,
		forwardingAgent: null,
		additionalNotifyParty: null,
		placeOfReceipt: '',
		placeOfDelivery: '',
	})
	const [listItemArr, setListItemArr] = useState([])
	const [open, setOpen] = useState(false)

	const {
		data: { allClients = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_CLIENTS)

	const { data: { booking = null } = {}, loading: bookingLoading } = useQuery(
		BOOKING,
		{
			variables: {
				id,
			},
		}
	)

	const [
		createBillOfLanding,
		{ data = null, error, loading: createBolLoading },
	] = useMutation(CREATE_BILL_OF_LANDING)

	const [
		CreateClient,
		{
			data: newClientData,
			error: newClientError,
			loading: newClientLoading,
			reset,
		},
	] = useMutation(CREATE_CLIENT)

	const handleChange = (event) => {
		const { value } = event.target
		const filterArray = [...new Set(value)]
		setListItemArr(filterArray)
	}

	const handleUpdateListItemArr = ({ value, key, index }) => {
		const updatedListItemArr = [...listItemArr].map((el, i) => {
			if (i === index) {
				return {
					...el,
					[key]: value,
				}
			} else {
				return el
			}
		})
		setListItemArr(updatedListItemArr)
	}

	const handleCreateBillOfLanding = () => {
		return createBillOfLanding({
			variables: {
				input: {
					bookingId: id,
					bookingContainers: listItemArr.map(
						({
							id,
							sealNumber,
							weight,
							vgm,
							quantity,
							packageType,
							measurement,
							description,
						}) => ({
							id,
							sealNumber,
							weight: Number(weight),
							vgm: Number(vgm),
							quantity,
							packageType,
							measurement,
							description,
						})
					),
					...values,
				},
			},
		})
	}

	const handleClientSubmit = (e) => {
		e.preventDefault()
		const {
			newname,
			newcompany,
			newemail,
			newaddress,
			newlanguage,
			newcurrency,
			newpostalCode,
			newcity,
			newcountry,
			newtaxNumber,
			newphone,
		} = e.target
		return CreateClient({
			variables: {
				input: {
					company: newcompany.value,
					name: newname.value,
					address: newaddress.value,
					email: newemail.value,
					language: newlanguage.value,
					currency: newcurrency.value,
					postalCode: newpostalCode.value,
					city: newcity.value,
					country: newcountry.value,
					taxNumber: newtaxNumber.value,
					phone: newphone.value,
				},
			},
		})
	}

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => `${option.name} ${option.company}`,
	})

	const handleChangeNewClient = (e) => {
		const { value, name } = e.target
		setInputLengths((prevInputLengths) => ({
			...prevInputLengths,
			[name]: value.length,
		}))
	}

	const handleOpen = () => setOpen(true)
	const handleClose = ({ error, success }) => {
		if (error) {
			setOpen(false)
			reset()
			toast.error(error.graphQLErrors[0].message)
		} else if (success) {
			setOpen(false)
			refetch()
			reset()
			toast.success('ok')
		} else {
			setOpen(false)
		}
	}

	if (!currentUser) return <Redirect to="/" />
	if (data && !error && !newClientError && data.createBillOfLanding)
		return (
			<Redirect
				to={{
					pathname: `/edit-bill-of-lading/${data.createBillOfLanding.billOfLanding.id}`,
					state: {
						success: true,
						msg: '✔️ Bill of lading created successfully',
					},
				}}
			/>
		)
	else if (error || newClientError) {
		var errorTxt = ''
		var separetor = '\n***************************************\n'
		if (newClientError) {
			errorTxt += newClientError?.message + separetor
		}

		if (error) {
			errorTxt += error?.message + separetor
		}
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>
				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	if (bookingLoading || createBolLoading || loading) return <Spinner />

	return (
		booking && (
			<Box className="mainZone" component="form" noValidate autoComplete="off">
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Bill of Lading</h1>
				</div>

				<fieldset>
					<FormGroup className="formGrp" row>
						<div className="InputWithLink">
							<TextField
								label="Booking N°"
								variant="filled"
								size="small"
								margin="dense"
								defaultValue={booking.number}
								disabled
							/>
							<IconButton
								className="bookingLink"
								color="primary"
								component={Link}
								to={'/edit-booking/' + booking.id}
							>
								<InfoRoundedIcon />
							</IconButton>
						</div>

						<TextField
							label="B/L N°"
							variant="filled"
							helperText="Generated after submit"
							size="small"
							margin="dense"
							disabled
						/>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Owward inland routing</InputLabel>
							<Select
								defaultValue="Draft"
								labelId="State"
								onChange={({ target: { value } }) => {
									setValues({ ...values, oiRouting: value })
								}}
							>
								<MenuItem value="Original">Original</MenuItem>
								<MenuItem value="Draft">Draft</MenuItem>
								<MenuItem value="Telex release">Telex release</MenuItem>
								<MenuItem value="Sea way bill">Sea way bill</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
				</fieldset>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Export Reference"
							variant="filled"
							size="small"
							margin="dense"
							onChange={({ target: { value } }) => {
								setValues({ ...values, exportReference: value })
							}}
						/>
						<TextField
							label="SVC Contract"
							variant="filled"
							size="small"
							margin="dense"
							onChange={({ target: { value } }) => {
								setValues({ ...values, svcContract: value })
							}}
						/>
						<TextField
							label="CSAC"
							variant="filled"
							size="small"
							margin="dense"
							onChange={({ target: { value } }) => {
								setValues({ ...values, csac: value })
							}}
						/>
					</FormGroup>
				</fieldset>
				{/* Contact fieldset */}
				<fieldset>
					<legend>Contact</legend>

					<FormGroup className="formGrp" row>
						{/* Shipper */}
						{allClients && (
							<Autocomplete
								margin="dense"
								options={allClients}
								// disabled={clientInput.name === 'shipper' && clientInput.checked}
								getOptionLabel={(option) => option.company}
								filterOptions={filterOptions}
								renderOption={(props, option) => (
									<Box component="li" {...props}>
										<span className="contactSelectItemsCompany">
											<CompanyIcon />
											<p>{props?.company}</p>
										</span>
										<span className="contactSelectItemsName">
											<EmployeeIcon />
											<p>{props?.name}</p>
										</span>
									</Box>
								)}
								getOptionSelected={(option, value) =>
									option.company === value.company
								}
								onChange={(event, value) =>
									setValues({ ...values, shipper: value?.id })
								}
								renderInput={(params) => (
									<TextField
										{...params}
										className="contactWidth"
										variant="filled"
										size="small"
										margin="dense"
										label="Shipper"
									/>
								)}
							/>
						)}
						<Button variant="contained" color="primary" onClick={handleOpen}>
							<PersonAddIcon /> &nbsp;Create Client
						</Button>
					</FormGroup>
					<FormGroup className="formGrp" row>
						{/* notifyParty */}
						<Autocomplete
							width={500}
							disablePortal
							margin="dense"
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, notifyParty: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Notify Party"
								/>
							)}
						/>
						{/* notifyParty (additional) */}
						<Autocomplete
							width={500}
							margin="dense"
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, additionalNotifyParty: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Additional Party"
									style={{ float: 'right' }}
								/>
							)}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						{/* consignees */}
						<Autocomplete
							width={400}
							margin="dense"
							// disabled={
							// 	clientInput.name === 'consignees' && clientInput.checked
							// }
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, consignees: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Consignes"
									name="consignees"
								/>
							)}
						/>
						{/* Forwarding Agent:*/}
						<Autocomplete
							width={400}
							margin="dense"
							// disabled={
							// 	clientInput.name === 'forwardingAgent' && clientInput.checked
							// }
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, forwardingAgent: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Forwarding Agent"
									name="forwarding Agent"
									style={{ float: 'right' }}
								/>
							)}
						/>
					</FormGroup>
				</fieldset>
				<fieldset>
					<legend>Partuiculars Furnished by shipper</legend>
					<FormGroup className="formGrp" row>
						{/* <TextField
							style={{ width: '900px' }}
							label="Quantity (Total)"
							variant="filled"
							size="small"
							margin="dense"
							type="number"
							onChange={({ target: { value } }) => {
								setValues({ ...values, quantity: Number(value) }) //Quantity
							}}
						/> */}
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Assigned containers</InputLabel>
							<Select
								style={{ width: '900px' }}
								variant="filled"
								size="small"
								margin="dense"
								multiple
								value={listItemArr}
								//
								onChange={handleChange}
								renderValue={(selected) => (
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: 0.5,
											marginLeft: '5px',
										}}
									>
										{selected.map((elm) => (
											<Chip
												style={{
													marginLeft: '5px',
													backgroundColor: '#0c1836',
													color: '#fff',
												}}
												key={elm.id}
												label={
													booking.assignedContainers?.find(
														(e) => e.id === elm.id
													)?.container?.number
												}
											/>
										))}
									</Box>
								)}
							>
								{booking.assignedContainers
									.filter(({ bol }) => !bol)
									.map((elm) => (
										<MenuItem key={elm.id} value={elm}>
											<Checkbox checked={listItemArr.includes(elm)} />
											<ListItemText
												primary={
													elm.container.number + ' | ' + elm.container.type.type
												}
											/>
										</MenuItem>
									))}
							</Select>
						</FormControl>
						{/* <Button
							variant="contained"
							color="primary"
							onClick={() => setListItemArr([...listItemArr])}
						>
							<PlaylistAddIcon />
							&nbsp;Add new row
						</Button> */}
					</FormGroup>
					<FormGroup className="formGrp" row style={{ overflowX: 'scroll' }}>
						<Table>
							{listItemArr.length ? (
								<TableHead>
									<TableRow>
										<TableCell>Container No.</TableCell>
										<TableCell>Description</TableCell>
										<TableCell>Seal No.</TableCell>
										<TableCell>VGM</TableCell>
										<TableCell>Package type</TableCell>
										<TableCell>Quantity</TableCell>
										<TableCell>Weight</TableCell>
										<TableCell>Measurement</TableCell>
									</TableRow>
								</TableHead>
							) : null}

							<TableBody>
								{listItemArr.map(
									(
										{
											id,
											sealNumber,
											vgm,
											weight,
											container,
											description,
											quantity,
											packageType,
											measurement,
										},
										index
									) => {
										return (
											<TableRow key={id}>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														disabled
														variant="standard"
														size="small"
														margin="dense"
														value={container?.number}
													/>
												</TableCell>
												<TableCell>
													<TextField
														multiline={true}
														style={{ width: '400px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={description || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'description',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={sealNumber || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'sealNumber',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={vgm || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value: Number(value),
																key: 'vgm',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={packageType || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'packageType',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														type="number"
														size="small"
														margin="dense"
														defaultValue={quantity || 0}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'quantity',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														type="number"
														defaultValue={weight || 0}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'weight',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={measurement || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'measurement',
																index,
															})
														}
													/>
												</TableCell>
											</TableRow>
										)
									}
								)}
							</TableBody>
						</Table>
					</FormGroup>
				</fieldset>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Place of reciept"
							variant="filled"
							size="small"
							margin="dense"
							onChange={({ target: { value } }) => {
								setValues({ ...values, placeOfReceipt: value }) //Place of reciept
							}}
						/>

						<TextField
							label="Place of delivery"
							variant="filled"
							size="small"
							margin="dense"
							onChange={({ target: { value } }) => {
								setValues({ ...values, placeOfDelivery: value }) //Place of delivery
							}}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Shipped on board"
							defaultValue={new Date(Number(booking.voyage?.departureDate))
								.toISOString()
								.slice(0, 10)}
							type="date"
							InputLabelProps={{ shrink: true }}
							variant="filled"
							size="small"
							margin="dense"
							disabled
						/>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Freight payable at</InputLabel>
							<Select
								labelId="State"
								defaultValue={values.freightPayableAt}
								onChange={({ target: { value } }) => {
									setValues({ ...values, freightPayableAt: value })
								}}
							>
								<MenuItem value="Origin">Origin</MenuItem>
								<MenuItem value="Destination">Destination</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Date and palce of issue"
							variant="filled"
							size="small"
							margin="dense"
							onChange={({ target: { value } }) => {
								setValues({ ...values, dateOfIssue: value })
							}}
						/>

						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Number of original B/L</InputLabel>
							<Select
								labelId="State"
								onChange={({ target: { value } }) => {
									setValues({ ...values, numberOfOriginalBOL: value })
								}}
								defaultValue="One (1/1)"
							>
								<MenuItem value="One (1/1)">One (1/1)</MenuItem>
								<MenuItem value="Two (2/2)">Two (2/2)</MenuItem>
								<MenuItem value="Three (3/3)">Three (3/3)</MenuItem>
								<MenuItem value="Four (4/4)">Four (4/4)</MenuItem>
								<MenuItem value="Five (5/5)">Five (5/5)</MenuItem>
								<MenuItem value="Six (6/6)">Six (6/6)</MenuItem>
								<MenuItem value="Seven (7/7)">Seven (7/7)</MenuItem>
								<MenuItem value="Eight (8/8)">Eight (8/8)</MenuItem>
								<MenuItem value="Nine (9/9)">Nine (9/9)</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<Button
						className="submitBtn"
						variant="contained"
						color="primary"
						onClick={handleCreateBillOfLanding}
					>
						<SaveIcon /> &nbsp; Submit{' '}
					</Button>
				</fieldset>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					onSubmit={handleClientSubmit}
				>
					<Box sx={style} component="form">
						{newClientLoading && !newClientData ? (
							<Spinner />
						) : newClientData && newClientData.createClient && open ? (
							handleClose({ success: true })
						) : newClientError && open ? (
							handleClose({ error: newClientError })
						) : (
							<>
								<fieldset>
									<legend>Create a new user</legend>
									<FormGroup className="formGrp" row>
										<TextField
											label="Name"
											name="newname"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 30 }}
											helperText={`${inputLengths.newname}/30`}
											onChange={handleChangeNewClient}
										/>
										<TextField
											label="Company"
											style={{ width: 430 }}
											name="newcompany"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 40 }}
											helperText={`${inputLengths.newcompany}/40`}
											onChange={handleChangeNewClient}
										/>
									</FormGroup>
									<FormGroup className="formGrp" row>
										<TextField
											label="E-mail"
											name="newemail"
											type="email"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 30 }}
											helperText={`${inputLengths.newemail}/30`}
											onChange={handleChangeNewClient}
										/>
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="normal"
										>
											<InputLabel>Language</InputLabel>
											<Select
												labelId="Language"
												name="newlanguage"
												defaultValue="en"
											>
												<MenuItem key="en" value="en">
													EN
												</MenuItem>
												<MenuItem key="fr" value="fr">
													FR
												</MenuItem>
											</Select>
										</FormControl>
										<FormControl
											className="formSelect"
											variant="filled"
											size="small"
											margin="normal"
										>
											<InputLabel>Currency</InputLabel>
											<Select
												labelId="Currency"
												name="newcurrency"
												defaultValue="eur"
											>
												<MenuItem key="eur" value="eur">
													EUR | €
												</MenuItem>
												<MenuItem key="usd" value="usd">
													USD | $
												</MenuItem>
											</Select>
										</FormControl>
									</FormGroup>
									<FormGroup className="formGrp" row>
										<TextField
											label="Phone"
											name="newphone"
											type="tel"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 20 }}
											helperText={`${inputLengths.newphone}/20`}
											onChange={handleChangeNewClient}
										/>
										<TextField
											label="Tax ID"
											name="newtaxNumber"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 15 }}
											helperText={`${inputLengths.newtaxNumber}/15`}
											onChange={handleChangeNewClient}
										/>
									</FormGroup>
									<FormGroup className="formGrp" row>
										<TextField
											fullWidth
											label="Address"
											name="newaddress"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 65 }}
											helperText={`${inputLengths.newaddress}/65`}
											onChange={handleChangeNewClient}
										/>
									</FormGroup>
									<FormGroup className="formGrp" row>
										<TextField
											label="Postal code"
											name="newpostalCode"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 10 }}
											helperText={`${inputLengths.newpostalCode}/10`}
											onChange={handleChangeNewClient}
										/>
										<TextField
											label="City"
											name="newcity"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 30 }}
											helperText={`${inputLengths.newcity}/30`}
											onChange={handleChangeNewClient}
										/>
										<TextField
											label="Country"
											name="newcountry"
											variant="filled"
											size="small"
											margin="normal"
											inputProps={{ maxLength: 20 }}
											helperText={`${inputLengths.newcountry}/20`}
											onChange={handleChangeNewClient}
										/>
									</FormGroup>
									<Button
										className="submitBtn"
										type="submit"
										variant="contained"
										color="primary"
									>
										{' '}
										<SaveIcon />
										&nbsp;Submit{' '}
									</Button>
								</fieldset>
							</>
						)}
					</Box>
				</Modal>
			</Box>
		)
	)
}
