import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import TextField from '@material-ui/core/TextField'
import * as moment from 'moment'
import Button from '@material-ui/core/Button'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import UploadZone from './UploadZone'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'

import Box from '@material-ui/core/Box'
import Autocomplete from '@mui/material/Autocomplete'

const CONTAINER = loader('./graphql/containerByNumber.graphql')
const ALL_STATUS = loader('./graphql/maintainanceStatusList.graphql')
const CONTAINER_UPDATE = loader('./graphql/updateContainerStatus.graphql')

const ContainerPage = () => {
	const [statusId, setStatusId] = useState()
	const [containerNumber, setContainerNumber] = useState('')
	const [containerId, setContainerId] = useState()

	const [remark, setRemark] = useState('')
	const [date, setDate] = useState(
		moment().format('YYYY-MM-DD[T]HH:mm').toString()
	)
	const [photos, setPhotos] = useState({
		firstPhoto: '',
		// secondPhoto: '',
		// thirdPhoto: '',
		// fourthPhoto: '',
	})

	const [
		getContainer,
		{
			data: { containerByNumber = null } = {},
			error: containerByNumberError,
			loading: containerLoading,
		},
	] = useLazyQuery(CONTAINER)

	const [
		updateContainerStatus,
		{
			data: operateContainerData = null,
			error: operateContainerupdateContainerStatusError,
			loading: updateContainerStatusLoading,
		},
	] = useMutation(CONTAINER_UPDATE)

	const {
		data: { maintainanceStatusList = null } = {},
		loading: statusLoading,
	} = useQuery(ALL_STATUS)

	const handleUpdateContainerStatus = () => {
		return updateContainerStatus({
			variables: {
				input: {
					id: containerId,
					statusId: statusId
						? statusId
						: maintainanceStatusList.find(
								({ status }) => status === 'MAINTAINANCE'
						  ).id,
					...photos,
					remark,
					date,
				},
			},
		})
	}

	const handleFetchContainer = () => {
		const getctrn = getContainer({
			variables: {
				number: containerNumber,
			},
		})

		return getctrn
	}

	useEffect(() => {
		if (containerByNumber) {
			setContainerId(containerByNumber.id)
		}
	}, [containerByNumber])

	if (operateContainerData && operateContainerData.updateContainerStatus)
		return (
			<Alert onClose={() => window.location.reload()} severity="success">
				Your request has been submitted successfully!
			</Alert>
		)

	if (operateContainerupdateContainerStatusError)
		return (
			<Alert severity="error">
				Something went wrong, please try again or contact your administrator!
			</Alert>
		)

	// confirm('Successful Message!')
	// window.location.reload()

	return (
		<Box className="operatorContainer" variant="fullWidth">
			<section className="operatorContainerHeader"></section>
			<section className="bookingSearchSection">
				<TextField
					fullWidth
					label="Container"
					variant="filled"
					size="small"
					margin="normal"
					defaultValue={containerNumber}
					onChange={({ target: { value } }) => {
						setContainerNumber(value)
					}}
				/>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					onClick={handleFetchContainer}
					style={{ marginLeft: '20px' }}
				>
					Search
				</Button>
			</section>
			{containerByNumberError && (
				<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
					{containerByNumberError.graphQLErrors[0].message}
				</Paper>
			)}

			{containerLoading && (
				<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
					Loading...
				</Paper>
			)}

			{containerByNumber && (
				<>
					<section
						className="maintenanceContainerInfo"
						style={{ width: '100%' }}
					>
						<TableContainer component={Paper}>
							<Table
								style={{ width: '100% !important' }}
								aria-label="simple table"
								size="small"
							>
								<TableBody>
									<TableRow hover>
										<TableCell component="th" scope="row">
											Container No :
										</TableCell>
										<TableCell align="right">
											{containerByNumber.number}
										</TableCell>
									</TableRow>
									<TableRow hover>
										<TableCell component="th" scope="row">
											Container ISO :
										</TableCell>
										<TableCell align="right">
											{containerByNumber.isoNumber}
										</TableCell>
									</TableRow>
									<TableRow hover>
										<TableCell component="th" scope="row">
											Container Mark :
										</TableCell>
										<TableCell align="right">
											{containerByNumber.mark}
										</TableCell>
									</TableRow>
									<TableRow hover>
										<TableCell component="th" scope="row">
											Container Type :
										</TableCell>
										<TableCell align="right">
											{containerByNumber.type.type}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</section>

					{maintainanceStatusList && (
						<Autocomplete
							fullWidth
							defaultValue={
								maintainanceStatusList.find(({ id }) => id === statusId) ||
								maintainanceStatusList[0]
							}
							getOptionLabel={(option) => option?.status}
							options={maintainanceStatusList}
							onChange={(event, value) => {
								setStatusId(value?.id)
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Status"
									variant="filled"
									size="small"
									margin="normal"
								/>
							)}
						/>
					)}

					<TextField
						fullWidth
						defaultValue={date}
						label="Date"
						type="datetime-local"
						name="name"
						variant="filled"
						size="small"
						margin="normal"
						InputLabelProps={{ shrink: true }}
						onChange={({ target: { value } }) => {
							setDate(value)
						}}
					/>
					<section className="uploadImgsContainer">
						{Object.keys(photos).map((el, i) => {
							return (
								<UploadZone
									key={i}
									photo={(url) => setPhotos({ ...photos, [el]: url })}
								/>
							)
						})}
					</section>

					<TextareaAutosize
						aria-label="minimum height"
						minRows={4}
						placeholder="Minimum 3 rows"
						style={{ width: '100%', marginTop: '10px' }}
						onChange={({ target: { value } }) => {
							setRemark(value)
						}}
					/>

					<Button
						fullWidth
						style={{ marginTop: '20px' }}
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleUpdateContainerStatus}
					>
						Submit
					</Button>
				</>
			)}
		</Box>
	)
}

export default ContainerPage
