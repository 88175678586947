import React from 'react'
import './style.css'

const ConditionsPages = () => {
	return (
		<table className="bookingPage">
			<tr>
				<td>
					<div style={{ pageBreakBefore: 'always', color: '#000' }}>
						<section style={{ textAlign: 'right' }}>
							<p>العناصر المدرجة في سعرنا:</p>
							<ul style={{ textAlign: 'right' }}>
								<li>لوكال ميناء تركيا TR</li>
								<li>الشحن البحري</li>
							</ul>

							<p>العناصر غير المدرجة في سعرنا:</p>
							<ul style={{ textAlign: 'right' }}>
								<li>Vgm</li>
								<li>ميناء الوجهة Thc</li>
								<li>تأمين النقل النهائي والتخليص الجمركي في كلا البلدين</li>
							</ul>

							<p>شروط العرض:</p>
							<ul style={{ textAlign: 'right' }}>
								<li>
									يتم تطبيق تكلفة OWS بقيمة 100 دولار أمريكي/طن على الحاويات
									مقاس 20 قدما التي تتجاوز 24 طنًا.
								</li>
								<li>
									يتم تطبيق تكلفة OWS بقيمة 100 دولار أمريكي/طن على الحاويات
									مقاس 40 قدمًا التي تتجاوز 25 طنًا.
								</li>
							</ul>
							<p>الأيام المجانية 14 يوما.</p>
							<ul style={{ textAlign: 'right' }}>
								<li>
									التكلفة لطلبات الأيام المجانية الإضافية لمدة 7 أيام، تبلغ
									الرسوم 150 دولارًا أمريكيًا.
								</li>
								<li>البوالص الإضافية 75 دولارًا أمريكيًا/لكل بوليصة.</li>
								<li>
									يتم تطبيق رسوم قدرها 75 دولارًا أمريكيًا + Kdv على التغييرات
									في طلبات إصدار التلكس / طلبات SWB بعد تاريخ Cut off.
								</li>
								<li>
									يتم تطبيق رسوم قدرها 75 دولارًا أمريكيًا على طلبات تعديل
									بوليصة الشحن بعد تاريخ Cut off.
								</li>
								<li>
									يتم تطبيق رسوم قدرها 150 دولارًا أمريكيًا على طلبات تعديل
									بوليصة الشحن بعد مغادرة السفينة.
								</li>
								<li>
									يتم تطبيق 75 دولارًا أمريكيًا + Kdv على طلبات تخزين الحاويات
									الممتلئة.
								</li>
								<li>
									يتم تطبيق رسوم طلب إلغاء الحجز وتأخير الشحن بقيمة 150 دولارًا
									أمريكيًا/الحاوية المكافئة.
								</li>
								<li>
									بالنسبة لعمليات التحميل التي يتم إلغاؤها أو تأجيلها إلى
									السفينة التالية بناءً على طلب العميل، بعد تأكيد الحجز المقدم
									من الوكالة خلال 6 أيام عمل، بما في ذلك يوم Cut off، على أساس
									الرحلة، يحق لمالك السفينة أن يطلب من العميل مبلغ navlun المتفق
									عليه.
								</li>
							</ul>
						</section>

						<p>Items included in our price:</p>
						<ul>
							<li>TR port locales</li>
							<li>Sea freight</li>
						</ul>

						<p>Items not included in our price:</p>
						<ul>
							<li>Vgm</li>
							<li>Destination port Thc</li>
							<li>
								Final transportation insurance and customs clearance in both
								countries
							</li>
						</ul>

						<p>Terms Of Conditions:</p>
						<ul>
							<li>
								USD100/ton OWS is applied for 20' containers exceeding 24 tons.
							</li>
							<li>
								USD100/ton OWS is applied for 40' containers exceeding 25 tons.
							</li>
						</ul>
						<p>Free time 14 days.</p>
						<ul>
							<li>
								For additional 7 days free time requests, the fee is 150 USD.
							</li>
							<li>Additional bills of lading 75 USD/per BL.</li>
							<li>
								75usd + VAT is applied to changes in Telex Release / SWB
								requests after the Cut off Date.
							</li>
							<li>
								75 USD is applied for bill of lading revision requests after the
								cut off date.
							</li>
							<li>
								150 USD is applied for bill of lading revision requests after
								ship departure.
							</li>
							<li>
								75usd + VAT is applied to your full container storage requests.
							</li>
							<li>
								Booking Cancellation & postponement fee is applied as USD
								150/TEU.
							</li>
							<li>
								For loadings that are canceled or postponed to the next ship
								upon customer request, after the reservation confirmation given
								by the agency within 6 business days, including the Cut-Off day,
								On a voyage basis, the shipowner has the right to demand the
								quoted freight amount from the customer.
							</li>
						</ul>

						<p>Fiyatımıza dahil olan kalemler :</p>
						<ul>
							<li>TR liman lokalleri</li>
							<li>Deniz yolu navlunu</li>
						</ul>

						<p>Fiyatımıza dahil olmayan kalemler :</p>
						<ul>
							<li>Vgm</li>
							<li>Varış limanı Thc</li>
							<li>Son taşıma sigorta ve her iki ülke gümrükleme</li>
						</ul>

						<p>Teklif şartları :</p>
						<ul>
							<li>
								24 tonu gecen 20’ lik konteynerlerde USD100/tons OWS
								uygulanmaktadır.
							</li>
							<li>
								25 Tonu geçen 40’lık konteynerlerde USD100/tons OWS
								uygulanmaktadır.
							</li>
						</ul>
						<p>Free time 14 Gün .</p>
						<ul>
							<li>Ek 7 gün Free time taleplerinde bedeli 150 USD ‘dir .</li>
							<li>Ek konşimentolar 75 USD/her BL için .</li>
							<li>
								Cut off Tarihinden sonra Telex Release / SWB taleplerindeki
								değişikliklere 75usd + Kdv uygulanmaktadır .
							</li>
							<li>
								Cut off Tarihinden sonra konşimento revize taleplerinde 75usd
								ücret uygulanmaktadır .
							</li>
							<li>
								Gemi çıkışından sonra konşimento revize taleplerinde 150usd
								ücret uygulanmaktadır
							</li>
							<li>
								Dolu konteyner Depolama taleplerinize 75usd +Kdv
								uygulanmaktadır.
							</li>
							<li>
								Booking İptal & Gemi Öteleme talebi ücreti USD 150/ TEU olarak
								uygulanmaktadır .
							</li>
							<li>
								Cut-Off günü dahil 6 iş günü içinde acentenin vereceği
								rezervasyon teyidinden sonra, müşteri talebi ile iptal veya bir
								sonraki gemiye ötelenen yüklemelerde, sefer bazında armatör,
								teklif edilen navlun tutarını müşteriden talep etme hakkına
								sahiptir.
							</li>
						</ul>
					</div>
				</td>
			</tr>
		</table>
	)
}

export default ConditionsPages
