import React from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download'

import Spinner from '../../UI/Spinner'

import images from './images'
import signature from './signature'

const VOYAGE = loader('./graphql/voyage.graphql')

export default function ExcelDownloader(props) {
	const id = props.id

	const { data: { voyage = null } = {}, loading: voyageLoading } = useQuery(
		VOYAGE,
		{
			variables: { id },
		}
	)

	const workbook = new ExcelJS.Workbook()
	if (voyage && !voyageLoading) {
		workbook.creator = 'Asasline'
		workbook.lastModifiedBy = 'Asasline'
		workbook.created = new Date()
		workbook.modified = new Date()

		const Base64Logo = images.filter((elm) => elm.image === 'logo')[0].data
		const logo = workbook.addImage({
			base64: Base64Logo,
			extension: 'png',
		})

		const Base64Signature = signature.filter(
			(elm) => elm.image === 'signature'
		)[0].data
		const Signature = workbook.addImage({
			base64: Base64Signature,
			extension: 'png',
		})

		let sheet = workbook.addWorksheet(
			'Cargo Manifest ' + voyage.number.replace(/[\*\?:\\/\[\]]+/g, '-')
		)

		//add sheet images
		sheet.addImage(logo, 'A3:E5')
		sheet.getCell('A3').alignment = { horizontal: 'center', vertical: 'middle' }
		// sheet.addImage(logo, {
		// 	tl: { col: 1, row: 3 },
		// 	ext: { width: 500, height: 200 },
		// })

		/*********************************/
		sheet.getRow(1) //empty row
		/*********************************/
		//Voyage Detailes
		{
			//Vessel
			sheet.getRow(2).values = [
				'',
				,
				,
				,
				,
				'CARGO MANIFEST',
				,
				,
				,
				,
				,
				,
				'Vessel',
				,
				,
				`: ${voyage && voyage?.ship?.name.replace(/[\*\?:\\/\[\]]+/g, '-')}`,
			]
			//Flag
			sheet.getRow(3).values = [
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				'Flag',
				,
				,
				`: ${voyage && voyage?.ship?.flag}`,
			]
			//Port Of Loading
			sheet.getRow(4).values = [
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				'Port Of Loading',
				,
				,
				`: ${voyage && voyage?.loadingPort?.name}`,
			]
			//Final Destination
			sheet.getRow(5).values = [
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				'Final Destination',
				,
				,
				`: ${voyage && voyage?.unLoadingPort?.name}`,
			]
			//Voyage Number
			sheet.getRow(6).values = [
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				'Voyage Number',
				,
				,
				`: ${voyage && voyage?.number.replace(/[\*\?:\\/\[\]]+/g, '-')}`,
			]
			//Sailing Date
			sheet.getRow(7).values = [
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				,
				'Sailing Date',
				,
				,
				`: ${voyage && voyage?.departureDate}`,
			]
		}

		//Header merge
		{
			sheet.mergeCells('A2:E7') //logo
			sheet.mergeCells('F2:L7') //main title

			sheet.mergeCells('M2:N2')
			sheet.mergeCells('M3:N3')
			sheet.mergeCells('M4:N4')
			sheet.mergeCells('M5:N5')
			sheet.mergeCells('M6:N6')
			sheet.mergeCells('M7:N7')

			sheet.mergeCells('P2:Q2')
			sheet.mergeCells('P3:Q3')
			sheet.mergeCells('P4:Q4')
			sheet.mergeCells('P5:Q5')
			sheet.mergeCells('P6:Q6')
			sheet.mergeCells('P7:Q7')
		}

		/*********************************/
		sheet.getRow(8) //empty row
		/*********************************/
		//main table
		sheet.getRow(9).values = [
			'Booking',
			'B/L NO',
			'SHIPPERS',
			,
			,
			,
			'CONSIGNEE/NOTIFY',
			,
			,
			'TOTAL CONTAINERS',
			,
			,
			'DESCRIPTION OF GOODS',
			,
			,
			,
			,
			'TOTAL GROSS WEIGHT',
			,
		]

		//MAin table header merge
		{
			sheet.mergeCells('C9:F9')
			sheet.mergeCells('G9:I9')
			sheet.mergeCells('J9:L9')
			sheet.mergeCells('M9:Q9')
			sheet.mergeCells('R9:S9')
		}

		//MAin table header style
		{
			sheet.getCell('A9').font = { size: 12, bold: true }
			sheet.getCell('B9').font = { size: 12, bold: true }
			sheet.getCell('F9').font = { size: 12, bold: true }
			sheet.getCell('I9').font = { size: 12, bold: true }
			sheet.getCell('L9').font = { size: 12, bold: true }
			sheet.getCell('Q9').font = { size: 12, bold: true }
			sheet.getCell('Q9').font = { size: 12, bold: true }
			sheet.getCell('R9').font = { size: 12, bold: true }
		}

		//header Border
		{
			sheet.getCell('A9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell('B9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell('F9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell('I9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell('L9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell('Q9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell('R9').border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
		}

		/*********************************/
		//Data
		let cpt = Number(10)
		voyage &&
			voyage?.bol &&
			voyage?.bol?.length &&
			voyage?.bol.map((bl) => {
				bl.containers?.map((elm) => {
					sheet.getRow(cpt).values = [
						bl?.booking?.number,
						bl.number,
						`${
							bl.shipper?.name && bl.shipper.name !== ''
								? 'NAME:' + bl.shipper.name
								: ''
						}  \r
						${
							bl.shipper?.company && bl.shipper.company !== ''
								? 'COMPANY:' + bl.shipper.company
								: ''
						}  \r ADRESS: ${bl.shipper?.address} \r ${
							bl.shipper?.postalCode
						} - ${bl.shipper?.city} `,
						,
						,
						,
						`NAME: ${bl.consignees?.name} \r COMPANY: ${bl.consignees?.company} \r ADRESS: ${bl.consignees?.address} \r  ${bl.consignees?.postalCode} - ${bl.consignees?.city} \r NOTIFY ADRESS: \r NAME: ${bl.notifyParty?.name} \r COMPANY: ${bl.notifyParty?.company} \r ADRESS: ${bl.notifyParty?.address} \r ${bl.notifyParty?.postalCode} - ${bl.notifyParty?.city}`,
						,
						,
						`1 Container \r Container# ${elm.container.number} \r CONTAINER TYPE: ${elm.container.type?.type} \r SEAL N°: ${elm.sealNumber}
						`,
						,
						,
						`TYPE: ${elm.packageType} \r ${elm.description}`,
						,
						,
						,
						,
						`${new Intl.NumberFormat('be-BE', {
							style: 'unit',
							unit: 'kilogram',
						}).format(elm.weight)}`,
						,
					]

					// data rows merge
					{
						sheet.mergeCells(`C${cpt}:F${cpt}`)
						sheet.mergeCells(`G${cpt}:I${cpt}`)
						sheet.mergeCells(`J${cpt}:L${cpt}`)
						sheet.mergeCells(`M${cpt}:Q${cpt}`)
						sheet.mergeCells(`R${cpt}:S${cpt}`)
					}

					//data rows border
					{
						sheet.getCell(`A${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`B${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`C${cpt}:F${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`G${cpt}:I${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`J${cpt}:L${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`M${cpt}:Q${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`R${cpt}:S${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
						sheet.getCell(`Q${cpt}:R${cpt}`).border = {
							top: { style: 'thin' },
							left: { style: 'thin' },
							bottom: { style: 'thin' },
							right: { style: 'thin' },
						}
					}

					sheet.getCell(`C${cpt}:F${cpt}`).alignment = {
						vertical: 'top',
						horizontal: 'left',
					}

					sheet.getCell(`C${cpt}`).alignment = {
						vertical: 'top',
						horizontal: 'left',
					}

					sheet.getCell(`G${cpt}:I${cpt}`).alignment = {
						vertical: 'top',
						horizontal: 'left',
					}

					sheet.getCell(`G${cpt}`).alignment = {
						vertical: 'top',
						horizontal: 'left',
					}

					sheet.getRow(cpt).font = { size: 8 }

					sheet.getRow(cpt).height = 90

					cpt++
				})
			})
		/*********************************/
		//signature

		{
			sheet.addImage(Signature, `A${cpt}:I${cpt + 4}`)
			sheet.getCell(`A${cpt}:I${cpt + 4}`).alignment = {
				horizontal: 'center',
				vertical: 'middle',
			}
		}

		{
			// sheet.getCell(`J${cpt}:S${cpt + 4}`)`PAGE TOTAL CONTAINERS/ UNIT & KGS`

			sheet.getCell(`A${cpt}:I${cpt + 4}`).alignment = {
				horizontal: 'center',
				vertical: 'middle',
			}
		}

		{
			let tot = sheet.getCell(`J${cpt + 2}`)
			tot.value = `TOTAL: ${new Intl.NumberFormat('be-BE', {
				style: 'unit',
				unit: 'kilogram',
			}).format(voyage.weight)} \r TOTAL CONTAINERS: ${cpt - 10}\r TOTAL TEU: ${
				voyage.teu
			}`
		}

		//signature merge

		{
			sheet.mergeCells(`A${cpt}:I${cpt + 4}`)
			sheet.mergeCells(`J${cpt}:S${cpt + 1}`)
			sheet.mergeCells(`J${cpt + 2}:S${cpt + 4}`)
		}

		sheet.getCell(`J${cpt}`).font = { size: 8, bold: true }

		//signature border
		{
			sheet.getCell(`A${cpt}`).border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell(`J${cpt}`).border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
			sheet.getCell(`J${cpt + 2}`).border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			}
		}

		const row9 = sheet.getRow(9)
		row9.eachCell((cell, rowNumber) => {
			sheet.getColumn(rowNumber).alignment = {
				vertical: 'middle',
				horizontal: 'center',
				wrapText: true,
			}
			//sheet.getColumn(rowNumber).font = { size: 10, family: 2 }
		})

		// Header style
		{
			//alignment
			sheet.getCell('M2').alignment = { horizontal: 'left' }
			sheet.getCell('M3').alignment = { horizontal: 'left' }
			sheet.getCell('M4').alignment = { horizontal: 'left' }
			sheet.getCell('M5').alignment = { horizontal: 'left' }
			sheet.getCell('M6').alignment = { horizontal: 'left' }
			sheet.getCell('M7').alignment = { horizontal: 'left' }

			sheet.getCell('P2').alignment = { horizontal: 'left' }
			sheet.getCell('P3').alignment = { horizontal: 'left' }
			sheet.getCell('P4').alignment = { horizontal: 'left' }
			sheet.getCell('P5').alignment = { horizontal: 'left' }
			sheet.getCell('P6').alignment = { horizontal: 'left' }
			sheet.getCell('P7').alignment = { horizontal: 'left' }

			//font
			sheet.getCell('F2').font = { size: 22, bold: true }

			sheet.getCell('M2').font = { bold: true }
			sheet.getCell('M3').font = { bold: true }
			sheet.getCell('M4').font = { bold: true }
			sheet.getCell('M5').font = { bold: true }
			sheet.getCell('M6').font = { bold: true }
			sheet.getCell('M7').font = { bold: true }

			sheet.getCell('P2').font = { bold: true }
			sheet.getCell('P3').font = { bold: true }
			sheet.getCell('P4').font = { bold: true }
			sheet.getCell('P5').font = { bold: true }
			sheet.getCell('P6').font = { bold: true }
			sheet.getCell('P7').font = { bold: true }
		}

		const headerrow = sheet.getRow(9)
		headerrow.height = 50
	} else {
		return <Spinner style={{ marginTop: '400px', color: 'secondary' }} />
	}

	const download = () => {
		workbook.xlsx.writeBuffer().then(function (buffer) {
			const blob = new Blob([buffer], { type: 'applicationi/xlsx' })
			saveAs(blob, 'manifest-' + voyage?.number + '.xlsx')
		})
	}

	if (voyageLoading)
		<Spinner style={{ marginTop: '400px', color: 'secondary' }} />

	return (
		<>
			<span
				className="listEditBtn"
				style={{ margin: '0 auto', cursor: 'pointer' }}
				onClick={(e) => download()}
			>
				<DownloadIcon className="editBtnIcon" /> Manifest
			</span>
		</>
	)
}
