import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

const DEPOT = loader('./graphql/depot.graphql')

export default function DepotContainersList() {
	const params = useParams() //get the depot ID

	const {
		data: { depot = {} } = {},
		loading: depotLoading,
		refetch,
	} = useQuery(DEPOT, {
		variables: { id: params.id },
	})

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			sortable: false,
			filtrable: false,
			disableExport: true,
		},
		{ field: 'number', headerName: 'Number', minWidth: 150, flex: 1 },
		{ field: 'isoNumber', headerName: 'ISO No.', minWidth: 150, flex: 1 },
		{ field: 'mark', headerName: 'Mark', minWidth: 150, flex: 1 },
		{
			field: 'type',
			headerName: 'Container Type',
			minWidth: 200,
			flex: 1,
			valueGetter: ({ value }) => value?.type,
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => value?.status,
		},
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			disableExport: true,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-container/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<LaunchOutlinedIcon className="editBtnIcon" /> Open
					</Link>
				)
			},
		},
	]

	useEffect(() => {
		refetch()
	}, [depot])

	if (depotLoading) return <Spinner />

	return (
		<>
			{depot && (
				<>
					<section
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100vw',
						}}
					>
						<br />
						<TableContainer
							component={Paper}
							elevation={3}
							style={{
								maxWidth: '600px',
								alignSelf: 'flex-end',
							}}
						>
							<Table size="small">
								<TableBody>
									<TableRow>
										<TableCell
											component="th"
											scope="row"
											style={{ fontWeight: 'bolder' }}
										>
											Depot name
										</TableCell>
										<TableCell>{depot.name.toUpperCase()}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											component="th"
											scope="row"
											style={{ fontWeight: 'bolder' }}
										>
											Coutry
										</TableCell>
										<TableCell>{depot.country.toUpperCase()}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											component="th"
											scope="row"
											style={{ fontWeight: 'bolder' }}
										>
											City
										</TableCell>
										<TableCell>{depot.city.toUpperCase()}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											component="th"
											scope="row"
											style={{ fontWeight: 'bolder' }}
										>
											Available (In Empty)
										</TableCell>
										<TableCell>
											{depot?.containers?.filter(
												({ status }) => status.status === 'IN EMPTY'
											)?.length || 0}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											component="th"
											scope="row"
											style={{ fontWeight: 'bolder' }}
										>
											Total Containers
										</TableCell>
										<TableCell>{depot?.containers?.length}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<br />
						<DataGrid
							rows={depot?.containers}
							autoHeight={true}
							columns={columns}
							components={{
								Toolbar: GridToolbar,
								NoResultsOverlay: () => {
									return (
										<Stack
											style={{ marginTop: '600px !important' }}
											alignItems="center"
											justifyContent="center"
										>
											<FilterListOffIcon />
											<p>Filter returns no result</p>
										</Stack>
									)
								},
								NoRowsOverlay: () => {
									return (
										<Stack
											style={{ marginTop: '600px !important' }}
											alignItems="center"
											justifyContent="center"
										>
											<FilterListOffIcon />
											<p>No Rows</p>
										</Stack>
									)
								},
							}}
						/>
					</section>
				</>
			)}
		</>
	)
}
