import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import StateIcon from '@mui/icons-material/Autorenew'
import SaveIcon from '@material-ui/icons/Save'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'
import CountriesList from './countries.json'

import '../../../asset/style.css'

const VESSEL = loader('./graphql/vessel.graphql')
const UPDATE_VESSEL = loader('./graphql/updateVessel.graphql')
const ALL_STATUS = loader('./graphql/allStatus.graphql')
const ALL_PORTS = loader('./graphql/allPorts.graphql')

const EditVessel = () => {
	const [flag, setFlag] = useState()
	const [statusId, setStatusId] = useState()
	const [portId, setPortId] = useState()
	const params = useParams()

	const {
		data: { vessel = {} } = {},
		loading: vesselLoading,
		refetch,
	} = useQuery(VESSEL, {
		variables: { id: params.id },
	})

	const [updateVessel, { data, error, loading }] = useMutation(UPDATE_VESSEL)

	const { data: { allStatus = null } = {}, loading: allStatusLoading } =
		useQuery(ALL_STATUS)

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const { data: { allPorts = null } = {}, loading: portsLoading } =
		useQuery(ALL_PORTS)

	const [
		updateVesselStatus,
		{
			data: vesselStatusData = null,
			error: vesselStatusDataError,
			loading: vesselStatusDataLoading,
		},
	] = useMutation(UPDATE_VESSEL)

	const [
		updateVesselLocation,
		{
			data: vesselLocationData = null,
			error: vesselLocationDataError,
			loading: vesselLocationDataLoading,
		},
	] = useMutation(UPDATE_VESSEL)

	useEffect(() => {
		refetch()
	}, [vessel])

	useEffect(() => {
		if (data) {
			localStorage.setItem('vesselState', '✔️ Vessel updated successfully ! ')
			window.location.reload()
		}
	}, [data])

	const { id } = params

	if (vesselLoading || loading)
		return <Spinner style={{ marginTop: '400px' }} />

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			name,
			operator,
			owner,
			imoNumber,
			callSign,
			registerNumber,
			deadWeight,
			grossTonage,
			netTonage,
			teu,
			lengthOverall,
			breadthOverall,
			speed,
			power,
			yearBuilt,
			flag,
			abbreviation,
			portOfRegistry,
		} = e.target
		return updateVessel({
			variables: {
				input: {
					id,
					name: name.value,
					operator: operator.value,
					owner: owner.value,
					imoNumber: imoNumber.value,
					callSign: callSign.value,
					registerNumber: registerNumber.value,
					deadWeight: Number(deadWeight.value),
					grossTonage: Number(grossTonage.value),
					netTonage: Number(netTonage.value),
					teu: Number(teu.value),
					lengthOverall: Number(lengthOverall.value),
					breadthOverall: Number(breadthOverall.value),
					speed: Number(speed.value),
					power: Number(power.value),
					yearBuilt: yearBuilt.value,
					flag: flag.value,
					abbreviation: abbreviation.value,
					portOfRegistry: portOfRegistry.value,
				},
			},
		})
	}

	const handelVesselStateUpdate = () => {
		return updateVesselStatus({
			variables: {
				input: {
					id,
					statusId: statusId,
				},
			},
		})
	}

	const handelVesselLocationUpdate = () => {
		return updateVesselLocation({
			variables: {
				input: {
					id,
					portId: portId,
				},
			},
		})
	}

	return (
		<>
			{loading ? (
				<Spinner style={{ marginTop: '400px', color: 'secondary' }} />
			) : error ? (
				<p>{error.message}</p>
			) : (
				<>
					{vessel && (
						<Box
							className="mainZone"
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div
								style={{
									width: '100%',
									height: '50px',
									backgroundColor: '#0c1836',
									marginBottom: '10px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h1 style={{ color: '#fff' }}>Edit Vessel</h1>
							</div>
							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										label="Vessel name"
										name="name"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.name}
									/>

									<TextField
										label="Vessel Owner"
										name="owner"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.owner}
									/>
									<TextField
										label="Vessel Operator"
										name="operator"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.operator}
									/>
								</FormGroup>
								<FormGroup className="formGrp" row>
									<TextField
										label="IMO Number"
										name="imoNumber"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.imoNumber}
									/>
									<TextField
										label="Call Sign"
										name="callSign"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.callSign}
									/>
									<TextField
										label="Register Number"
										name="registerNumber"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.registerNumber}
									/>
								</FormGroup>
							</fieldset>
							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										label="Deadweight (tdw)"
										name="deadWeight"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.deadWeight}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">Kg</InputAdornment>
											),
										}}
									/>
									<TextField
										label="Gross Tonnage (GRT)"
										name="grossTonage"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.grossTonage}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">Kg</InputAdornment>
											),
										}}
									/>
									<TextField
										label="Net Tonnage (NRT)"
										name="netTonage"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.netTonage}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">Kg</InputAdornment>
											),
										}}
									/>
									<TextField
										label="TEU"
										name="teu"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.teu}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">Unit</InputAdornment>
											),
										}}
									/>
								</FormGroup>
							</fieldset>
							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										label="Length Overall"
										name="lengthOverall"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.lengthOverall}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">m</InputAdornment>
											),
										}}
									/>
									<TextField
										label="Breadth Overall"
										name="breadthOverall"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.breadthOverall}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">m</InputAdornment>
											),
										}}
									/>
									<TextField
										label="speed"
										name="speed"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.speed}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">Kn</InputAdornment>
											),
										}}
									/>
									<TextField
										label="Power"
										name="power"
										type="number"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.power}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">KW</InputAdornment>
											),
										}}
									/>
								</FormGroup>
							</fieldset>
							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										label="Year Built"
										name="yearBuilt"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.yearBuilt}
									/>
									<Autocomplete
										onChange={(event, value) => setFlag(value?.label)}
										defaultValue={CountriesList.find(
											(c) => (c.label = vessel.flag)
										)}
										autoHighlight
										options={CountriesList}
										getOptionLabel={(option) => option?.label}
										renderOption={(props, option) => (
											<Box
												component="li"
												sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
												{...props}
											>
												<img
													loading="lazy"
													width="20"
													src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
													srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
													alt=""
												/>
												&nbsp;
												{option?.label}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Flag"
												name="flag"
												variant="filled"
												size="small"
												margin="dense"
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password', // disable autocomplete and autofill
												}}
											/>
										)}
									/>
									<TextField
										label="Abbreviation"
										name="abbreviation"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.abbreviation}
									/>
									<TextField
										label="Port of Registry"
										name="portOfRegistry"
										variant="filled"
										size="small"
										margin="dense"
										defaultValue={vessel.portOfRegistry}
									/>
								</FormGroup>

								<Button
									className="submitBtn"
									type="submit"
									variant="contained"
									color="primary"
								>
									{' '}
									<SaveIcon />
									&nbsp;UPDATE{' '}
								</Button>
							</fieldset>
							<fieldset>
								<legend>Vessel status</legend>
								<FormGroup className="formGrp" row>
									{allStatus && (
										<Autocomplete
											style={{ width: '70%' }}
											sx={{ width: '70% !important' }}
											defaultValue={
												vessel.status
													? allStatus.find(({ id }) => id === vessel.status.id)
													: {}
											}
											getOptionLabel={(option) => option?.status}
											options={allStatus}
											onChange={(event, value) => {
												setStatusId(value.id)
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Status"
													variant="filled"
													size="small"
													margin="normal"
												/>
											)}
										/>
									)}
									<Button
										className="submitBtn"
										onClick={handelVesselStateUpdate}
										variant="contained"
										color="primary"
										size="small"
										margin="normal"
									>
										{' '}
										<StateIcon />
										&nbsp;UPDATE VESSEL STATE{' '}
									</Button>
								</FormGroup>
							</fieldset>
							<fieldset>
								<legend>Vessel location</legend>
								<FormGroup className="formGrp" row>
									{allPorts && (
										<Autocomplete
											filterOptions={filterOptions}
											defaultValue={
												vessel.port
													? allPorts.find(({ id }) => id === vessel.port.id)
													: {}
											}
											style={{ width: '70%' }}
											sx={{ width: '70% !important' }}
											getOptionLabel={(option) => option?.name}
											options={allPorts}
											onChange={(event, value) => {
												setPortId(value.id)
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Port"
													variant="filled"
													size="small"
													margin="normal"
												/>
											)}
										/>
									)}
									<Button
										className="submitBtn"
										onClick={handelVesselLocationUpdate}
										variant="contained"
										color="primary"
										size="small"
										margin="normal"
									>
										{' '}
										<StateIcon />
										&nbsp;UPDATE VESSEL LOCATION{' '}
									</Button>
								</FormGroup>
							</fieldset>
						</Box>
					)}
				</>
			)}
		</>
	)
}

export default EditVessel
