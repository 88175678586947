import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.scss'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { CurrentUserProvider } from './currentUserContext'

// Pages
import Home from './pages/Home'
import Login from './interaction/Login'
import CreateBooking from './pages/CreateBooking'
import CreateBillOfLanding from './pages/CreateBillOfLanding'
import BookingList from './pages/BookingList'
import CommodityList from './pages/CommodityList'
import Users from './pages/Users'
import ContactList from './pages/ContactList'
import Header from './UI/Header'
import EditBooking from './interaction/EditBooking'
import EditBillOfLanding from './interaction/EditBillOfLanding'
import BillOfLandingList from './pages/BillOfLandingList'
import EditClient from './interaction/EditContact'
import EditCommodity from './interaction/EditCommodity'
import CreateCommodity from './pages/CreateCommidity'
import EditUser from './interaction/EditUser'
import CreateUser from './pages/CreateUser'
import CreateContact from './pages/CreateContact'
import OperateBooking from './pages/OperateBooking'
import CreateContainer from './pages/CreateContainer'
import CreateVessel from './pages/CreateVessel'
import CreateVoyage from './pages/CreateVoyage'
import ContainerList from './pages/ContainerList'
import VoyageList from './pages/VoyageList'
import ContainerTypeList from './pages/ContainerTypeList'
import VesselList from './pages/VesselList'
import AssignedContainerList from './pages/AssignedContainerList'
import ContainerBulkStatusUpdate from './pages/ContainerBulkStatusUpdate'
import ContainerBulkStatusUpdateByDepot from './pages/ContainerBulkStatusUpdateByDepot'
import EditVessel from './interaction/EditVessel'
import EditContainer from './interaction/EditContainer'
import EditVoyage from './interaction/EditVoyage'
import TrackByContainer from './pages/TrackByContainer'
import TrackByBooking from './pages/TrackByBooking'
import TrackVesselByNumber from './pages/TrackVesselByNumber'
import TrackVesselByVoyage from './pages/TrackVesselByVoyage'
import CreateSubBol from './pages/CreateSubBol'
import SubBillOfLadingList from './pages/SubBillOfLadingList'
import EditSubBillOfLading from './interaction/EditSubBillOfLading'
import ContractList from './pages/ContractList'
import CreateContract from './pages/CreateContract'
// import EditContract from './interaction/editContract'
import CreateBank from './pages/CreateBank'
import BankList from './pages/BankList'
import EditBank from './interaction/EditBank'
import PortsList from './pages/PortsList'
import EditPort from './interaction/EditPort'
import CreateCurrency from './pages/CreateCurrency'
import EditCurrency from './interaction/EditCurrency'
import CurrencyList from './pages/CurrencyList'
import CreateInvoice from './pages/CreateInvoice'
import InvoiceList from './pages/InvoiceList'
import CreateDebitEntry from './pages/CreateDebitEntry'
import DebitEntryList from './pages/DebitEntryList'
import EditInvoice from './interaction/EditInvoice'
import CreateCreditEntry from './pages/CreateCreditEntry'
import CreateDepot from './pages/CreateDepot'
import DepotList from './pages/DepotList'
import EditDepot from './interaction/EditDepot'
import DepotContainersList from './pages/DepotContainersList'

import Page404 from './pages/Page404'

const App = () => {
	const theme = createTheme({
		palette: {
			secondary: {
				main: '#f7af3d',
			},
			primary: {
				main: '#0c1836',
			},
		},
	})

	return (
		<>
			<div className="App" id="root">
				<MuiThemeProvider theme={theme}>
					<CurrentUserProvider>
						<Header />
						<Switch>
							<Route
								path="/operate-booking/"
								render={() => <OperateBooking />}
							/>
							<Route path="/track-booking/" render={() => <TrackByBooking />} />
							<Route
								path="/track-container/"
								render={() => <TrackByContainer />}
							/>
							<Route
								path="/track-vessel/"
								render={() => <TrackVesselByNumber />}
							/>
							<Route
								path="/track-vessel-voyage/"
								render={() => <TrackVesselByVoyage />}
							/>
							<Route path="/create-user/" render={() => <CreateUser />} />
							<Route path="/edit-user/:id" render={() => <EditUser />} />
							<Route path="/edit-vessel/:id" render={() => <EditVessel />} />
							<Route path="/edit-voyage/:id" render={() => <EditVoyage />} />
							<Route
								path="/edit-container/:id"
								render={() => <EditContainer />}
							/>
							<Route
								path="/edit-commodity/:id"
								render={() => <EditCommodity />}
							/>
							<Route path="/edit-client/:id" render={() => <EditClient />} />
							<Route
								path="/edit-bill-of-lading/:id"
								render={() => <EditBillOfLanding />}
							/>
							<Route path="/edit-booking/:id" render={() => <EditBooking />} />
							<Route path="/create-booking/" render={() => <CreateBooking />} />
							<Route
								path="/create-bill-of-lading/:id"
								render={() => <CreateBillOfLanding />}
							/>
							<Route
								path="/create-sub-bill-of-lading/:bolId"
								render={() => <CreateSubBol />}
							/>
							<Route
								path="/bill-of-lading-list/"
								render={() => <BillOfLandingList />}
							/>
							<Route path="/booking-list/" render={() => <BookingList />} />
							<Route path="/contact-list/" render={() => <ContactList />} />
							<Route path="/create-contact/" render={() => <CreateContact />} />
							<Route path="/commodity-list/" render={() => <CommodityList />} />
							<Route
								path="/create-commodity/"
								render={() => <CreateCommodity />}
							/>
							<Route path="/container-list/" render={() => <ContainerList />} />
							<Route path="/voyage-list/" render={() => <VoyageList />} />
							<Route path="/vessel-list/" render={() => <VesselList />} />
							<Route
								path="/sub-bill-of-lading-list/"
								render={() => <SubBillOfLadingList />}
							/>
							<Route
								path="/edit-sub-bill-of-lading/:id"
								render={() => <EditSubBillOfLading />}
							/>
							<Route
								path="/container-type-list/"
								render={() => <ContainerTypeList />}
							/>
							<Route
								path="/assigned-container-list/"
								render={() => <AssignedContainerList />}
							/>
							<Route
								path="/update-containers-status-by-voyage/"
								render={() => <ContainerBulkStatusUpdate />}
							/>
							<Route
								path="/update-containers-status-by-depot/"
								render={() => <ContainerBulkStatusUpdateByDepot />}
							/>
							<Route
								path="/create-container/"
								render={() => <CreateContainer />}
							/>
							<Route path="/vessel-list/" render={() => <CreateVessel />} />
							<Route path="/create-voyage/" render={() => <CreateVoyage />} />
							<Route path="/create-vessel/" render={() => <CreateVessel />} />
							<Route path="/users/" render={() => <Users />} />
							<Route
								path="/create-contract/"
								render={() => <CreateContract />}
							/>
							<Route path="/contract-list/" render={() => <ContractList />} />
							{/* <Route
								path="/edit-contract/:number"
								render={() => <EditContract />}
							/> */}

							<Route path="/create-bank/" render={() => <CreateBank />} />
							<Route path="/bank-list/" render={() => <BankList />} />
							<Route path="/edit-bank/:id" render={() => <EditBank />} />

							<Route path="/depot-list/" render={() => <DepotList />} />
							<Route path="/create-depot/" render={() => <CreateDepot />} />
							<Route path="/edit-depot/:id" render={() => <EditDepot />} />
							<Route
								path="/depot-containers-list/:id"
								render={() => <DepotContainersList />}
							/>

							<Route
								path="/create-currency/"
								render={() => <CreateCurrency />}
							/>
							<Route
								path="/edit-currency/:id"
								render={() => <EditCurrency />}
							/>

							<Route path="/currency-list/" render={() => <CurrencyList />} />
							<Route path="/port-list/" render={() => <PortsList />} />
							<Route path="/edit-port/:id" render={() => <EditPort />} />
							<Route path="/create-invoice/" render={() => <CreateInvoice />} />
							<Route path="/invoice-list/" render={() => <InvoiceList />} />
							<Route
								path="/create-debit-entry/"
								render={() => <CreateDebitEntry />}
							/>
							<Route
								path="/debit-entry-list/"
								render={() => <DebitEntryList />}
							/>
							<Route path="/invoice-list/" render={() => <InvoiceList />} />
							<Route
								path="/edit-invoice/:number"
								render={() => <EditInvoice />}
							/>
							<Route
								path="/create-credit-entry/"
								render={() => <CreateCreditEntry />}
							/>

							<Route path="/home">
								<Home />
							</Route>
							<Route exact path="/">
								<Login />
							</Route>

							<Route path="*">
								<Page404 />
							</Route>
						</Switch>
					</CurrentUserProvider>
				</MuiThemeProvider>
			</div>
		</>
	)
}

export default App
