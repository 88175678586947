import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'

import * as moment from 'moment'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import '../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_CURRENCIES = loader('./graphql/allCurrencies.graphql')

export default function CurrencyList() {
	const [sortModel, setSortModel] = React.useState([
		{
			field: 'updatedAt',
			sort: 'desc',
		},
	])

	const {
		data: { allCurrencies = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_CURRENCIES)

	const columns = [
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-currency/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			filterable: false,
			minWidth: 150,
			hide: true,
			flex: 1,
		},
		{ field: 'currency', headerName: 'Currency', minWidth: 150, flex: 1 },
		{ field: 'symbol', headerName: 'Symbol', minWidth: 200, flex: 1 },
		{ field: 'inEuro', headerName: 'In Euro', minWidth: 150, flex: 1 },
		{ field: 'inUsd', headerName: 'In USD', minWidth: 200, flex: 1 },
		{
			field: 'updatedBy',
			headerName: 'Updated By',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => value?.userName,
		},
		{
			field: 'createdBy',
			headerName: 'Created By',
			minWidth: 150,
			flex: 1,
			hide: true,
			valueGetter: ({ value }) => value?.userName,
		},
		{
			field: 'createdAt',
			flex: 1,
			headerName: 'Created',
			minWidth: 120,
			hide: true,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
		{
			field: 'updatedAt',
			flex: 1,
			headerName: 'Updated',
			minWidth: 120,
			valueFormatter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
	]

	if (localStorage.getItem('currencyState') !== '') {
		toast.success(localStorage.getItem('currencyState'))
	}

	if (localStorage.getItem('createCurrencyState') !== '') {
		toast.success(localStorage.getItem('createCurrencyState'))
	}

	useEffect(() => {
		// Clear the local storage
		const timer = setTimeout(() => {
			localStorage.setItem('currencyState', '')
			localStorage.setItem('createCurrencyState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allCurrencies])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />

			{allCurrencies && (
				<DataGrid
					rows={allCurrencies}
					autoHeight={true}
					columns={columns}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
