import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import CircularProgress from '@mui/material/CircularProgress'

import Spinner from '../../UI/Spinner'
import './dropstyle.css'

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	width: 106,
	height: 76,
	margin: '0 auto',
}

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	width: '100%',
	height: '100%',
	margin: '0 auto',
	textAlign: 'center',
}

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden',
}

const img = {
	display: 'block',
	width: 'auto',
	height: '100%',
	margin: '0 auto',
}

const UploadZone = ({ photo }) => {
	const [imageFiles, setImageFiles] = useState([])
	const [photoLoding, setPhotoLoading] = useState(false)

	const onSelectFile = async (files) => {
		await setPhotoLoading(true)
		const file = files[0]
		const convertedFile = await convertToBase64(file)
		// Request will be sent from here in the future
		const s3URL = await axios.post('/upload', {
			image: convertedFile,
			name: file.name,
			type: file.type,
		})
		if (s3URL && s3URL.data) {
			setPhotoLoading(false)
			await setImageFiles(
				files.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
			return photo(s3URL.data.link)
		}
	}
	const convertToBase64 = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				resolve(reader.result)
			}
		})
	}

	const thumbs = imageFiles.map((file) => (
		<div style={thumb} key={file.name}>
			<div style={thumbInner}>
				<img src={file.preview} style={img} />
			</div>
		</div>
	))

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks
		imageFiles.forEach((file) => URL.revokeObjectURL(file.preview))
	}, [imageFiles])

	return (
		<>
			{photoLoding ? (
				<section className="dropZoneContainer">
					<CircularProgress style={{ margin: '0 auto' }} />
				</section>
			) : (
				<Dropzone
					maxFiles={1}
					multiple={false}
					accept="image/*"
					noDrag={true}
					//maxSize={3145728}
					onDrop={onSelectFile}
				>
					{({ getRootProps, getInputProps }) => (
						<section className="dropZoneContainer">
							<div {...getRootProps()} className="btnContainer">
								<p className="uploadBtn">Take a photo</p>
								<input {...getInputProps()} style={{ visibility: 'hidden' }} />
							</div>
							<aside style={thumbsContainer}>{thumbs}</aside>
						</section>
					)}
				</Dropzone>
			)}
		</>
	)
}
export default UploadZone
