import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useCurrentUser } from '../../currentUserContext'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'

import InputAdornment from '@material-ui/core/InputAdornment'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const CURRENCY = loader('./graphql/currency.graphql')
const UPDATE_CURRENCY = loader('./graphql/updateCurrency.graphql')

const EditCurrency = () => {
	const params = useParams() //get the currency ID

	const { data: { currency = {} } = {}, loading: currencyLoading } = useQuery(
		CURRENCY,
		{
			variables: { id: params.id },
		}
	)

	const [updateCurrency, { data, error, loading }] =
		useMutation(UPDATE_CURRENCY)

	const { id } = params

	useEffect(() => {
		if (data) {
			localStorage.setItem(
				'currencyState',
				'✔️ Currency updated successfully ! '
			)
			window.location.reload()
		}
	}, [data])

	if (currencyLoading || loading)
		return <Spinner style={{ marginTop: '400px' }} />

	if (!useCurrentUser) return <Redirect to="/" />

	const handleSubmit = (e) => {
		e.preventDefault()
		const { currency, inEuro, inUsd, symbol } = e.target
		return updateCurrency({
			variables: {
				input: {
					id,
					currency: currency.value,
					inEuro: Number(inEuro.value),
					inUsd: Number(inUsd.value),
					symbol: symbol.value,
				},
			},
		})
	}

	return (
		<>
			{error ? (
				<p>{error.message}</p>
			) : (
				currency && (
					<>
						<Box
							className="mainZone"
							component="form"
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div
								style={{
									width: '100%',
									height: '50px',
									backgroundColor: '#0c1836',
									marginBottom: '10px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h1 style={{ color: '#fff' }}>
									Edit Currency - {currency.currency}
								</h1>
							</div>

							<fieldset>
								<FormGroup className="formGrp" row>
									<TextField
										defaultValue={currency.currency}
										label="Currency"
										name="currency"
										variant="filled"
										size="small"
										margin="normal"
									/>

									<TextField
										defaultValue={currency.symbol}
										label="Symbol/Abbreviation"
										name="symbol"
										variant="filled"
										size="small"
										margin="normal"
									/>

									<TextField
										defaultValue={currency.inEuro}
										label="In Euro"
										name="inEuro"
										variant="filled"
										size="small"
										margin="normal"
										type="number"
										InputProps={{
											inputProps: { min: 0 },
											endAdornment: (
												<InputAdornment position="end">
													<EuroSymbolIcon />
												</InputAdornment>
											),
										}}
										onWheel={(e) => e.target.blur()}
									/>

									<TextField
										defaultValue={currency.inUsd}
										label="In Usd"
										name="inUsd"
										variant="filled"
										size="small"
										margin="normal"
										type="number"
										InputProps={{
											inputProps: { min: 0 },
											endAdornment: (
												<InputAdornment position="end">
													<AttachMoneyIcon />
												</InputAdornment>
											),
										}}
										onWheel={(e) => e.target.blur()}
									/>
								</FormGroup>

								<Button
									className="submitBtn"
									type="submit"
									variant="contained"
									color="primary"
								>
									{' '}
									<SaveIcon />
									&nbsp;UPDATE{' '}
								</Button>
							</fieldset>
						</Box>
					</>
				)
			)}
		</>
	)
}

export default EditCurrency
