import React, { useRef, useEffect, useState } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../currentUserContext'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CompanyIcon from '@mui/icons-material/Apartment'
import EmployeeIcon from '@mui/icons-material/Badge'
import PdfIcon from '@material-ui/icons/PictureAsPdf'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import Spinner from '../../UI/Spinner'

import ReactToPrint from 'react-to-print'

// import BillOfLandingTemplateEN from './printBillOfLanding/indexEN'

import '../../../asset/style.css'

const ALL_CLIENTS = loader(
	'../../interaction/EditBillOfLanding/graphql/allClients.graphql'
)
const CREATE_SUB_BILL_OF_LANING = loader(
	'./graphql/createSubBillOfLading.graphql'
)
const BILL_OF_LANDING = loader(
	'../../interaction/EditBillOfLanding/graphql/billOfLanding.graphql'
)

export default function CreateSubBol() {
	const componentRef = useRef()
	let location = useLocation()
	const currentUser = useCurrentUser()
	const { bolId } = useParams()
	const [values, setValues] = useState({})
	const [listItemArr, setListItemArr] = useState([])
	const [readOnly, setReadOnly] = useState(false)
	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const { data: { allClients = null } = {}, loading } = useQuery(ALL_CLIENTS)

	const { data: { billOfLanding = null } = {}, loading: billOfLandingLoading } =
		useQuery(BILL_OF_LANDING, {
			variables: {
				id: bolId,
			},
		})

	const [
		createSubBillOfLading,
		{
			data: subBillOfLandigData = null,
			error: createSubBillOfLadingError,
			loading: bolLoading,
		},
	] = useMutation(CREATE_SUB_BILL_OF_LANING)

	const removeKeysFromObject = (obj) => {
		let bolObj = { ...obj }
		delete bolObj.id
		delete bolObj.booking
		delete bolObj.containers
		delete bolObj.number
		delete bolObj.__typename
		delete bolObj.shipper
		delete bolObj.consignees
		delete bolObj.notifyParty
		delete bolObj.forwardingAgent
		delete bolObj.additionalNotifyParty
		return setValues({
			...bolObj,
			shipper: obj.shipper?.id,
			consignees: obj.consignees?.id,
			notifyParty: obj.notifyParty?.id,
			forwardingAgent: obj.forwardingAgent?.id,
			additionalNotifyParty: obj.additionalNotifyParty?.id,
		})
	}

	const handleUpdateListItemArr = ({ value, key, index }) => {
		let updatedListItemArr = [...listItemArr]
		updatedListItemArr = updatedListItemArr.map((el, i) => {
			if (i === index) {
				return { ...el, [key]: value }
			} else {
				return el
			}
		})
		setListItemArr(updatedListItemArr)
	}

	const handleSubmit = () => {
		createSubBillOfLading({
			variables: {
				input: {
					...values,
					bolId,
					// bookingContainers: listItemArr.map(
					// 	({
					// 		id,
					// 		sealNumber,
					// 		weight,
					// 		quantity,
					// 		packageType,
					// 		measurement,
					// 		description,
					// 	}) => ({
					// 		id,
					// 		sealNumber,
					// 		weight: Number(weight),
					// 		quantity,
					// 		packageType,
					// 		measurement,
					// 		description,
					// 	})
					// ),
				},
			},
		})
	}

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
		stringify: (option) => `${option.name} ${option.company}`,
	})

	useEffect(() => {
		if (billOfLanding) {
			removeKeysFromObject(billOfLanding)
			const bolContainers = [...billOfLanding.containers]
			setListItemArr(bolContainers)
		}
	}, [billOfLanding])

	useEffect(() => {
		if (subBillOfLandigData && !createSubBillOfLadingError) {
			localStorage.setItem(
				'createSubBillState',
				'✔️ Sub Bill of lading created successfully ! '
			)
			window.location.reload()
		}
	}, [subBillOfLandigData])

	useEffect(() => {
		// refetch()

		if (isAdmin) {
			setReadOnly(false)
		} else if (
			(billOfLanding && billOfLanding.oiRouting === 'Original') ||
			(billOfLanding && billOfLanding.oiRouting === 'Sea way bill') ||
			(billOfLanding && billOfLanding.oiRouting === 'Telex release')
		) {
			setReadOnly(true)
		} else if (
			billOfLanding &&
			billOfLanding.oiRouting === 'Draft' &&
			!isAdmin
		) {
			setReadOnly(false)
		}
	}, [billOfLanding])

	const handleChange = (event) => {
		const { value } = event.target
		const filterArray = [...new Set(value)]
		setListItemArr(filterArray)
	}

	if (!currentUser) return <Redirect to="/" />
	// if (billOfLandingData) return <Redirect to="/bill-of-lading-list" />
	if (loading || billOfLandingLoading || bolLoading) return <Spinner />

	if (location.state) {
		location.state.success
			? toast.success(location.state.msg)
			: toast.error(location.state.msg)
		location.state = '' //clear location
		toast.dismiss()
	}

	if (localStorage.getItem('createSubBillState') !== '') {
		toast.success(localStorage.getItem('createSubBillState'))
		localStorage.setItem('createSubBillState', '')
	}

	return (
		billOfLanding &&
		allClients && (
			<Box className="mainZone" component="form" noValidate autoComplete="off">
				<ToastContainer position="bottom-right" transition={Slide} />
				{/* <div style={{ display: 'none' }}>
					<BillOfLandingTemplateEN id={id} ref={componentRef} />
				</div> */}
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Sub Bill of Lading</h1>
				</div>
				<ButtonGroup
					className="actionBtnGroup"
					variant="contained"
					color="primary"
				>
					<Button
						type="submit"
						onClick={handleSubmit}
						disabled={readOnly}
						style={{
							borderRadius: '0 !important',
							borderRight: '1px solid #fff',
						}}
					>
						{' '}
						<SaveIcon /> &nbsp;Save
					</Button>
					<ReactToPrint
						documentTitle={'BL No.' + billOfLanding.number}
						trigger={() => (
							<Button
								variant="contained"
								color="primary"
								style={{
									borderRadius: '0',
								}}
							>
								<PdfIcon /> &nbsp;Download
							</Button>
						)}
						content={() => componentRef.current}
					/>
				</ButtonGroup>

				<FormGroup className="formGrp" row>
					<TextField
						label="B/L N°"
						variant="filled"
						size="small"
						margin="dense"
						disabled
						defaultValue={billOfLanding.number}
					/>

					<FormControl
						className="formSelect"
						variant="filled"
						size="small"
						margin="dense"
					>
						<InputLabel>Owward inland routing</InputLabel>
						<Select
							labelId="State"
							disabled={readOnly}
							defaultValue={billOfLanding.oiRouting}
							onChange={({ target: { value } }) => {
								setValues({ ...values, oiRouting: value })
							}}
						>
							<MenuItem value="Original">Original</MenuItem>
							<MenuItem value="Draft">Draft</MenuItem>
							<MenuItem value="Telex release">Telex release</MenuItem>
							<MenuItem value="Sea way bill">Sea way bill</MenuItem>
						</Select>
					</FormControl>
				</FormGroup>
				<fieldset>
					<FormGroup>
						<TextField
							label="Export Reference"
							variant="filled"
							size="small"
							margin="dense"
							disabled={readOnly}
							defaultValue={billOfLanding.exportReference}
							onChange={({ target: { value } }) => {
								setValues({ ...values, exportReference: value })
							}}
						/>
						<TextField
							label="SVC Contract"
							variant="filled"
							size="small"
							margin="dense"
							disabled={readOnly}
							defaultValue={billOfLanding.svcContract}
							onChange={({ target: { value } }) => {
								setValues({ ...values, svcContract: value })
							}}
						/>

						<TextField
							label="CSAC"
							variant="filled"
							size="small"
							margin="dense"
							disabled={readOnly}
							defaultValue={billOfLanding.csac}
							onChange={({ target: { value } }) => {
								setValues({ ...values, csac: value })
							}}
						/>
					</FormGroup>
				</fieldset>
				<fieldset>
					<legend>Contact</legend>
					<FormGroup className="formGrp" row>
						{/* Shipper */}
						{allClients && (
							<Autocomplete
								margin="dense"
								options={allClients}
								defaultValue={billOfLanding.shipper}
								getOptionLabel={(option) => option.company}
								filterOptions={filterOptions}
								renderOption={(props, option) => (
									<Box component="li" {...props}>
										<span className="contactSelectItemsCompany">
											<CompanyIcon />
											<p>{props?.company}</p>
										</span>
										<span className="contactSelectItemsName">
											<EmployeeIcon />
											<p>{props?.name}</p>
										</span>
									</Box>
								)}
								getOptionSelected={(option, value) =>
									option.company === value.company
								}
								onChange={(event, value) =>
									setValues({ ...values, shipper: value?.id })
								}
								renderInput={(params) => (
									<TextField
										{...params}
										className="contactWidth"
										variant="filled"
										size="small"
										margin="dense"
										label="Shipper"
									/>
								)}
							/>
						)}
					</FormGroup>
					<FormGroup className="formGrp" row>
						{/* notifyParty */}
						<Autocomplete
							width={500}
							disablePortal
							margin="dense"
							defaultValue={billOfLanding.notifyParty}
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, notifyParty: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Notify Party"
								/>
							)}
						/>
						{/* notifyParty (additional) */}
						<Autocomplete
							width={500}
							margin="dense"
							defaultValue={billOfLanding.additionalNotifyParty}
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, additionalNotifyParty: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Notify Party (Additional)"
									style={{ float: 'right' }}
								/>
							)}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						{/* consignees */}
						<Autocomplete
							width={400}
							margin="dense"
							defaultValue={billOfLanding.consignees}
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, consignees: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Consignees"
									name="consignees"
								/>
							)}
						/>
						{/* Forwarding Agent:*/}
						<Autocomplete
							width={400}
							margin="dense"
							defaultValue={billOfLanding.forwardingAgent}
							getOptionLabel={(option) => option.company}
							filterOptions={filterOptions}
							getOptionSelected={(option, value) =>
								option.company === value.company
							}
							options={allClients}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									<span className="contactSelectItemsCompany">
										<CompanyIcon />
										<p>{props?.company}</p>
									</span>
									<span className="contactSelectItemsName">
										<EmployeeIcon />
										<p>{props?.name}</p>
									</span>
								</Box>
							)}
							onChange={(event, value) =>
								setValues({ ...values, forwardingAgent: value?.id })
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="contactWidth"
									variant="filled"
									size="small"
									margin="dense"
									label="Forwarding Agent"
									name="forwarding Agent"
									style={{ float: 'right' }}
								/>
							)}
						/>
					</FormGroup>
				</fieldset>

				<fieldset>
					<legend>Partuiculars Furnished by shipper</legend>
					<FormGroup className="formGrp" row>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Assigned containers</InputLabel>
							<Select
								style={{ width: '900px' }}
								variant="filled"
								size="small"
								margin="dense"
								multiple
								value={listItemArr}
								onChange={handleChange}
								renderValue={(selected) => (
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: 0.5,
											marginLeft: '5px',
										}}
									>
										{selected.map((elm) => (
											<Chip
												style={{
													marginLeft: '5px',
													backgroundColor: '#0c1836',
													color: '#fff',
												}}
												key={elm.id}
												label={
													billOfLanding.containers?.find((e) => e.id === elm.id)
														?.container?.number
												}
											/>
										))}
									</Box>
								)}
							>
								{billOfLanding.containers
									.filter(({ bol }) => !bol)
									.map((elm) => (
										<MenuItem key={elm.id} value={elm}>
											<Checkbox checked={listItemArr.includes(elm)} />
											<ListItemText
												primary={
													elm.container.number + ' | ' + elm.container.type.type
												}
											/>
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup className="formGrp" row style={{ overflowX: 'scroll' }}>
						<Table>
							{listItemArr.length ? (
								<TableHead>
									<TableRow>
										<TableCell>Container No.</TableCell>
										<TableCell>Description</TableCell>
										<TableCell>Seal No.</TableCell>
										<TableCell>VGM</TableCell>
										<TableCell>Package type</TableCell>
										<TableCell>Quantity</TableCell>
										<TableCell>Weight</TableCell>
										<TableCell>Measurement</TableCell>
									</TableRow>
								</TableHead>
							) : null}

							<TableBody>
								{listItemArr.map(
									(
										{
											id,
											sealNumber,
											weight,
											vgm,
											container,
											description,
											quantity,
											packageType,
											measurement,
										},
										index
									) => {
										return (
											<TableRow key={id}>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														InputProps={{
															readOnly: true,
														}}
														variant="standard"
														size="small"
														margin="dense"
														value={container?.number}
													/>
												</TableCell>
												<TableCell>
													<TextField
														multiline={true}
														style={{ width: '400px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={description || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'description',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={sealNumber || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'sealNumber',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={vgm || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value: Number(value),
																key: 'vgm',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={packageType || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'packageType',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														type="number"
														size="small"
														margin="dense"
														defaultValue={quantity || 0}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'quantity',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														type="number"
														defaultValue={weight || 0}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'weight',
																index,
															})
														}
													/>
												</TableCell>
												<TableCell>
													<TextField
														style={{ width: '100px' }}
														variant="standard"
														size="small"
														margin="dense"
														value={measurement || ''}
														onChange={({ target: { value } }) =>
															handleUpdateListItemArr({
																value,
																key: 'measurement',
																index,
															})
														}
													/>
												</TableCell>
											</TableRow>
										)
									}
								)}
							</TableBody>
						</Table>
					</FormGroup>
				</fieldset>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Place of reciept"
							variant="filled"
							size="small"
							margin="dense"
							disabled={readOnly}
							defaultValue={billOfLanding.placeOfReceipt}
							onChange={({ target: { value } }) => {
								setValues({ ...values, placeOfReceipt: value }) //Place of reciept
							}}
						/>

						<TextField
							label="Place of delivery"
							variant="filled"
							size="small"
							margin="dense"
							disabled={readOnly}
							defaultValue={billOfLanding.placeOfDelivery}
							onChange={({ target: { value } }) => {
								setValues({ ...values, placeOfDelivery: value }) //Place of delivery
							}}
						/>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							label="Shipped on board"
							type="date"
							InputLabelProps={{ shrink: true }}
							variant="filled"
							size="small"
							margin="dense"
							InputProps={{
								readOnly: true,
							}}
							defaultValue={
								new Date(Number(billOfLanding.booking.voyage.departureDate))
									.toISOString()
									.split('T')[0]
							}
						/>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Freight payable at</InputLabel>
							<Select
								labelId="State"
								disabled={readOnly}
								defaultValue={billOfLanding.freightPayableAt}
								onChange={({ target: { value } }) => {
									setValues({ ...values, freightPayableAt: value })
								}}
							>
								<MenuItem value="Origin">Origin</MenuItem>
								<MenuItem value="Destination">Destination</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup className="formGrp" row>
						<TextField
							defaultValue={billOfLanding.dateOfIssue}
							label="Date of issue"
							variant="filled"
							size="small"
							margin="dense"
							disabled={readOnly}
							onChange={({ target: { value } }) => {
								setValues({ ...values, dateOfIssue: value })
							}}
						/>
						<FormControl
							className="formSelect"
							variant="filled"
							size="small"
							margin="dense"
						>
							<InputLabel>Number of original B/L</InputLabel>
							<Select
								labelId="State"
								disabled={readOnly}
								defaultValue={billOfLanding.numberOfOriginalBOL}
								onChange={({ target: { value } }) => {
									setValues({ ...values, numberOfOriginalBOL: value })
								}}
							>
								<MenuItem value="One (1/1)">One (1/1)</MenuItem>
								<MenuItem value="Two (2/2)">Two (2/2)</MenuItem>
								<MenuItem value="Three (3/3)">Three (3/3)</MenuItem>
								<MenuItem value="Four (4/4)">Four (4/4)</MenuItem>
								<MenuItem value="Five (5/5)">Five (5/5)</MenuItem>
								<MenuItem value="Six (6/6)">Six (6/6)</MenuItem>
								<MenuItem value="Seven (7/7)">Seven (7/7)</MenuItem>
								<MenuItem value="Eight (8/8)">Eight (8/8)</MenuItem>
								<MenuItem value="Nine (9/9)">Nine (9/9)</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						disabled={readOnly}
					>
						{' '}
						<SaveIcon /> &nbsp; CREATE
					</Button>
				</fieldset>
			</Box>
		)
	)
}
