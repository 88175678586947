import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
// import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
// import StateIcon from '@mui/icons-material/Autorenew'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'
import { useParams } from 'react-router-dom'

import '../../../asset/style.css'

const CONTAINER = loader('./graphql/container.graphql')
const ALL_CONTAINERTYPE = loader('./graphql/allContainerType.graphql')
const UPDATE_CONTAINER = loader('./graphql/updateContainer.graphql')
const ALL_STATUS = loader('./graphql/allStatus.graphql')
const ALL_DEPOTS = loader('./graphql/allDepots.graphql')

// const CONTAINER_UPDATE_STATUS = loader(
// 	'./graphql/updateContainerStatus.graphql'
// )

const EditContainer = () => {
	const params = useParams()
	const [typeId, setTypeId] = useState()
	const [statusId, setStatusId] = useState()
	const [depotId, setDepotId] = useState()

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 100,
	})

	const {
		data: { container = {} } = {},
		loading: containerLoading,
		refetch,
	} = useQuery(CONTAINER, {
		variables: { id: params.id },
	})

	const { data: { allDepots = null } = {}, loading: depotsLoading } =
		useQuery(ALL_DEPOTS)

	const {
		data: { allContainerTypes = null } = {},
		loading: containerTypeLoading,
	} = useQuery(ALL_CONTAINERTYPE)

	const [updateContainer, { data, error, loading }] =
		useMutation(UPDATE_CONTAINER)

	// const [
	// 	updateContainerStatus,
	// 	{
	// 		data: containerStatusData = null,
	// 		error: containerStatusDataError,
	// 		loading: containerStatusDataLoading,
	// 	},
	// ] = useMutation(CONTAINER_UPDATE_STATUS)

	const { data: { allStatus = null } = {}, loading: allStatusLoading } =
		useQuery(ALL_STATUS)

	useEffect(() => {
		if (data) {
			window.location.reload()
		}
	}, [data])

	useEffect(() => {
		refetch()
	}, [container])

	const { id } = params

	if (containerLoading || loading || containerTypeLoading)
		return <Spinner style={{ marginTop: '400px' }} />

	if (data) {
		localStorage.setItem(
			'containerState',
			'✔️ Container updated successfully ! '
		)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const {
			number,
			mark,
			tar,
			maxWeight,
			isoNumber,
			saleDate,
			salePrice,
			madeDate,
			note,
		} = e.target
		return updateContainer({
			variables: {
				input: {
					id,
					number: number.value,
					mark: mark.value,
					tar: tar.value,
					maxWeight: maxWeight.value,
					isoNumber: isoNumber.value,
					saleDate: saleDate.value,
					salePrice: salePrice.value,
					madeDate: madeDate.value,
					note: note.value,
					// numberOfTrips: Number(numberOfTrips.value),
					typeId,
					depotId,
					statusId: statusId || container.status.id,
				},
			},
		})
	}

	// const handelContainerStateUpdate = () => {
	// 	const currentDate = new Date()
	// 	return updateContainerStatus({
	// 		variables: {
	// 			input: {
	// 				statusId: statusId,
	// 				id: container?.id,
	// 				date: currentDate,
	// 			},
	// 		},
	// 	})
	// }

	return (
		<>
			{loading ? (
				<Spinner style={{ marginTop: '400px', color: 'secondary' }} />
			) : error ? (
				<p> {error.message} </p>
			) : (
				<>
					<Box
						className="mainZone"
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div
							style={{
								width: '100%',
								height: '50px',
								backgroundColor: '#0c1836',
								marginBottom: '10px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h1 style={{ color: '#fff' }}>Edit Container</h1>
						</div>

						<fieldset>
							<FormGroup className="formGrp" row>
								<TextField
									label="Mark"
									name="mark"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.mark}
								/>

								<TextField
									label="Number No."
									name="number"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.number}
								/>

								<TextField
									label="ISO No."
									name="isoNumber"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.isoNumber}
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									label="Empty weight (TAR)"
									name="tar"
									variant="filled"
									size="small"
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">Kg</InputAdornment>
										),
									}}
									defaultValue={container.tar}
								/>
								<TextField
									label="Max Weight"
									name="maxWeight"
									variant="filled"
									size="small"
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">Kg</InputAdornment>
										),
									}}
									defaultValue={container.maxWeight}
								/>
								<Autocomplete
									fullWidth
									defaultValue={container.type}
									onChange={(event, value) => setTypeId(value.id)}
									getOptionLabel={(option) => option?.type?.toString()}
									options={allContainerTypes}
									sx={{ width: 230 }}
									style={{ alignSelf: 'center' }}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Container type"
											name="typeId"
											variant="filled"
											size="small"
											margin="small"
											required
										/>
									)}
								/>
							</FormGroup>
							<FormGroup className="formGrp" row>
								<TextField
									label="Purchase price"
									name="salePrice"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.salePrice}
								/>
								<TextField
									label="Date of manufacture"
									name="madeDate"
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.madeDate}
								/>

								<TextField
									label="date of purchase"
									name="saleDate"
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.saleDate}
								/>
								<TextField
									label="Note"
									name="note"
									variant="filled"
									size="small"
									margin="normal"
									defaultValue={container.note}
								/>
							</FormGroup>

							{allDepots && (
								<Autocomplete
									defaultValue={container?.depot}
									filterOptions={filterOptions}
									style={{ width: '40%' }}
									sx={{ width: '40% !important' }}
									getOptionLabel={(option) =>
										option?.name?.toUpperCase() +
										' - ' +
										option?.city?.toUpperCase() +
										'/' +
										option?.country?.toUpperCase()
									}
									options={allDepots}
									onChange={(event, value) => {
										setDepotId(value?.id)
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Depot"
											variant="filled"
											size="small"
											margin="normal"
										/>
									)}
								/>
							)}
							<fieldset>
								<legend>Container state</legend>
								<FormGroup className="formGrp" row></FormGroup>
								<FormGroup className="formGrp" row>
									{allStatus && (
										<Autocomplete
											sx={{ width: '70%' }}
											getOptionLabel={(option) => option?.status}
											options={allStatus}
											defaultValue={container.status ? container.status : ''}
											onChange={(event, value) => {
												setStatusId(value?.id)
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Status"
													variant="filled"
													size="small"
													margin="normal"
												/>
											)}
										/>
									)}
									{/* <Button
									className="submitBtn"
									variant="contained"
									color="primary"
									size="small"
									margin="normal"
									onClick={handelContainerStateUpdate}
									disabled={statusId ? false : true}
								>
									{' '}
									<StateIcon />
									&nbsp;UPDATE CONTAINER STATUS{' '}
								</Button> */}
								</FormGroup>
							</fieldset>
							<Button
								className="submitBtn"
								type="submit"
								variant="contained"
								color="primary"
							>
								{' '}
								<SaveIcon />
								&nbsp;UPDATE{' '}
							</Button>
						</fieldset>
					</Box>
				</>
			)}
		</>
	)
}

export default EditContainer
