import React, { useEffect } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../UI/Spinner'
import Stack from '@mui/material/Stack'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import EditIcon from '@material-ui/icons/BorderColor'

const ALL_VESSELS = loader('./graphql/allVessels.graphql')

export default function VesselList() {
	const {
		data: { allVessels = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_VESSELS)

	const columns = [
		{
			field: 'actions',
			filterable: false,
			headerName: 'Action',
			sortable: false,
			filtrable: false,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<Link
						to={`/edit-vessel/${thisRow.id}`}
						style={{ margin: '0 auto' }}
						className="listEditBtn"
					>
						<EditIcon className="editBtnIcon" /> Edit
					</Link>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			filterable: false,
			flex: 1,
		},
		{ field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
		{ field: 'owner', headerName: 'Owner', minWidth: 150, flex: 1 },
		{
			field: 'registerNumber',
			headerName: 'Register No.',
			minWidth: 200,
			flex: 1,
		},
		{ field: 'imoNumber', headerName: 'IMO No.', minWidth: 150, flex: 1 },
		{ field: 'teu', headerName: 'TEU', minWidth: 50, flex: 1 },
		{
			field: 'deadWeight',
			headerName: 'Dead Weight (Kg)',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'grossTonage',
			headerName: 'Gross Tonage (Kg)',
			minWidth: 150,
			flex: 1,
		},
	]

	if (localStorage.getItem('vesselState') !== '') {
		toast.success(localStorage.getItem('vesselState'))
	}

	if (localStorage.getItem('createVesselState') !== '') {
		toast.success(localStorage.getItem('createVesselState'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('vesselState', '')
			localStorage.setItem('createVesselState', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allVessels])

	if (loading) return <Spinner />

	return (
		<>
			<ToastContainer position="bottom-right" transition={Slide} />
			{allVessels && (
				<DataGrid
					rows={allVessels}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: GridToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
