import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../UI/toastify/ReactToastify.min.css'

import Autocomplete from '@mui/material/Autocomplete'

import { Button } from '@material-ui/core'
import Spinner from '../../UI/Spinner'

import '../../../asset/style.css'

const CREATE_DEPOT = loader('./graphql/createDepot.graphql')
const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')

const CreateDepot = () => {
	const [countryId, setCountryId] = useState()

	const [createDepot, { data, error, loading }] = useMutation(CREATE_DEPOT)
	const { data: { allCountries = null } = [], loading: countriesLoading } =
		useQuery(ALL_COUNTRIES)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, city } = e.target
		return createDepot({
			variables: {
				input: {
					country: countryId,
					name: name.value,
					city: city.value,
				},
			},
		})
	}

	if (loading || countriesLoading) return <Spinner />

	if (data && !error) {
		localStorage.setItem('createDepotState', '✔️ Depot created successfully ! ')
		return <Redirect to="/depot-list" />
	} else if (error) {
		var errorTxt = ''
		errorTxt += error?.message
		toast.error(
			<div>
				<p>⚠️ Something wrong happened !</p>

				<Button
					style={{ backgroundColor: '#fff', cursor: 'pointer' }}
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(errorTxt)
					}}
				>
					Copy error
				</Button>
			</div>
		)
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<ToastContainer position="bottom-right" transition={Slide} />
				<div
					style={{
						width: '100%',
						height: '50px',
						backgroundColor: '#0c1836',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h1 style={{ color: '#fff' }}>Create Depot</h1>
				</div>
				<fieldset>
					<FormGroup className="formGrp" row>
						<TextField
							label="Depot name"
							name="name"
							variant="filled"
							size="small"
							margin="normal"
						/>

						{allCountries && (
							<Autocomplete
								getOptionLabel={(option) => option.toUpperCase()}
								onChange={(event, value) => setCountryId(value)}
								options={allCountries}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Country"
										name="Country"
										variant="filled"
										size="small"
										margin="normal"
										required
									/>
								)}
							/>
						)}

						<TextField
							label="City"
							name="city"
							variant="filled"
							size="small"
							margin="normal"
						/>
					</FormGroup>

					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Submit{' '}
					</Button>
				</fieldset>
			</Box>
		</>
	)
}

export default CreateDepot
