import React, { useState } from 'react'
import { useCurrentUser } from '../../currentUserContext'
import { Redirect, Link } from 'react-router-dom'
import clsx from 'clsx'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PeopleAlt from '@material-ui/icons/PeopleAlt'
import IconButton from '@material-ui/core/IconButton'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import Logo from '../../../asset/logo.png'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import VesselIcon from '@mui/icons-material/DirectionsBoat'
// import BookingIcon from '@mui/icons-material/BookOnline'
import BookingIcon from '@mui/icons-material/ConfirmationNumber'
import ContainerIcon from '@mui/icons-material/CalendarViewDay'
import VoyageIcon from '@mui/icons-material/ModeOfTravel'
import SettingsIcon from '@mui/icons-material/Settings'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContractIcon from '@mui/icons-material/Summarize'
import AnchorIcon from '@mui/icons-material/Anchor'
import InvoiceIcon from '@mui/icons-material/Receipt'

import ReorderIcon from '@mui/icons-material/Reorder'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import TreeView from '@mui/lab/TreeView'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import TrackingIcon from '@mui/icons-material/ShareLocation'
import BankIcon from '@mui/icons-material/AccountBalance'
import CurrencyIcon from '@mui/icons-material/CurrencyExchange'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import Button from '@mui/material/Button'

import Logout from '../../interaction/Logout'

import { useTheme } from '@material-ui/core/styles'

import useStyles from './useStyles'
import './style.css'

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		// borderTopRightRadius: theme.spacing(1),
		// borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(0),
		fontWeight: theme.typography.fontWeightMedium,
		'&.Mui-expanded': {
			fontWeight: theme.typography.fontWeightRegular,
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
			color: 'var(--tree-view-color)',
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'inherit',
			color: 'inherit',
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(1),
		},
	},
}))

function StyledTreeItem(props) {
	const {
		bgColor,
		color,
		labelIcon: LabelIcon,
		labelInfo,
		labelText,
		...other
	} = props

	return (
		<StyledTreeItemRoot
			label={
				<Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
					<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
					<Typography
						variant="body2"
						sx={{ fontWeight: 'inherit', flexGrow: 1 }}
					>
						{labelText}
					</Typography>
					<Typography variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</Box>
			}
			style={{
				'--tree-view-color': color,
				'--tree-view-bg-color': bgColor,
			}}
			{...other}
		/>
	)
}

StyledTreeItem.propTypes = {
	bgColor: PropTypes.string,
	color: PropTypes.string,
	labelIcon: PropTypes.elementType.isRequired,
	labelInfo: PropTypes.string,
	labelText: PropTypes.string.isRequired,
}
const Header = (props) => {
	const currentUser = useCurrentUser()
	const [open, setOpen] = useState(false)
	const [expanded, setExpanded] = React.useState([])
	const theme = useTheme()
	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')

	const isAccountant =
		currentUser && currentUser.roles.find(({ role }) => role === 'accountant')

	const isOperator =
		currentUser && currentUser.roles.find(({ role }) => role === 'operator')

	const {
		logo,
		appBar,
		appBarShift,
		menuButton,
		hide,
		drawer,
		drawerOpen,
		drawerClose,
		toolbar,
	} = useStyles()

	const handleExpandClick = () => {
		setExpanded((oldExpanded) =>
			oldExpanded.length === 0
				? [
						'1',
						'2',
						'3',
						'4',
						'5',
						'6',
						'7',
						'8',
						'9',
						'10',
						'11',
						'12',
						'13',
						'14',
						'15',
						'16',
						'17',
				  ]
				: []
		)
	}

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	if (!currentUser) return <Redirect to="/" />

	return (
		<>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(appBar, {
					[appBarShift]: open,
				})}
			>
				<Toolbar>
					{isOperator ? null : (
						<IconButton
							color="inherit"
							onClick={handleDrawerOpen}
							edge="start"
							className={clsx(menuButton, {
								[hide]: open,
							})}
						>
							<MenuIcon />
						</IconButton>
					)}

					<Link to="/home">
						<img src={Logo} alt="Home" className={logo} />
					</Link>
					<Typography variant="h5" noWrap>
						Asasline Booking Tool
					</Typography>

					<Logout
						style={{
							marginRight: '0px',
							marginLeft: 'auto',
							cursor: 'pointer',
						}}
					/>
				</Toolbar>
			</AppBar>
			{isOperator ? null : (
				<Drawer
					variant="permanent"
					className={clsx(drawer, {
						[drawerOpen]: open,
						[drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[drawerOpen]: open,
							[drawerClose]: !open,
						}),
					}}
				>
					<div className={toolbar}>
						<IconButton onClick={handleDrawerClose} style={{ float: 'right' }}>
							{theme.direction === 'rtl' ? (
								<ChevronRightIcon />
							) : (
								<ChevronLeftIcon />
							)}
						</IconButton>
					</div>
					<List className="mainMenuAccord">
						<Button onClick={handleExpandClick} className="collapseBtn">
							{expanded.length === 0 ? (
								<>
									<PlaylistPlayIcon className="collapseBtnIcon" />
									<p>Expand all</p>
								</>
							) : (
								<>
									<ReorderIcon className="collapseBtnIcon" />
									<p>Collapse all</p>
								</>
							)}
						</Button>
						<TreeView
							defaultCollapseIcon={<ArrowDropDownIcon />}
							defaultExpandIcon={<ArrowRightIcon />}
							expanded={expanded}
							onNodeToggle={handleToggle}
							defaultEndIcon={<div style={{ width: 24 }} />}
							sx={{
								flexGrow: 1,
								maxWidth: 600,
								overflowY: 'auto',
							}}
						>
							{!isAccountant && (
								<>
									{' '}
									<StyledTreeItem
										nodeId="1"
										labelText="Booking"
										labelIcon={BookingIcon}
										className="elemheaders"
									>
										<Link to="/create-booking/">
											<StyledTreeItem
												nodeId="create-booking"
												labelText="Create Booking"
											/>
										</Link>
										<Link to="/booking-list/">
											<StyledTreeItem
												nodeId="booking-list"
												labelText="List Bookings"
											/>
										</Link>
										<Link to="/operate-booking/">
											<StyledTreeItem
												nodeId="operate-booking"
												labelText="Operate Booking & VGM"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="2"
										labelText="Bill Of lading"
										labelIcon={HistoryEduIcon}
									>
										<Link to="/bill-of-lading-list/">
											<StyledTreeItem
												nodeId="1bill"
												labelText="Bill Of lading list"
												className="elemheaders"
											/>
										</Link>
										<Link to="/sub-bill-of-lading-list/">
											<StyledTreeItem
												nodeId="2bill"
												labelText="Sub Bill Of lading list"
												className="elemheaders"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="3"
										labelText="Voyage"
										className="elemheaders"
										labelIcon={VoyageIcon}
									>
										<Link to="/create-voyage/">
											<StyledTreeItem
												nodeId="create-voyage"
												labelText="Create voyage"
												// labelIcon={HistoryEduIcon}
											/>
										</Link>
										<Link to="/voyage-list/">
											<StyledTreeItem
												nodeId="voyage-list"
												labelText="Voyages list"
												// labelIcon={HistoryEduIcon}
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="4"
										labelText="Track"
										labelIcon={TrackingIcon}
										className="elemheaders"
									>
										<Link to="/track-container/">
											<StyledTreeItem
												nodeId="Track1"
												labelText="Track container"
											/>
										</Link>
										<Link to="/track-booking/">
											<StyledTreeItem
												nodeId="Track2"
												labelText="Track booking"
											/>
										</Link>
										<Link to="/track-vessel/">
											<StyledTreeItem
												nodeId="Track3"
												labelText="Track vessel"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="5"
										labelText="Vessel"
										className="elemheaders"
										labelIcon={VesselIcon}
									>
										<Link to="/create-vessel/">
											<StyledTreeItem
												nodeId="create-vessel"
												labelText="Create vessel"
												// labelIcon={HistoryEduIcon}
											/>
										</Link>
										<Link to="/vessel-list/">
											<StyledTreeItem
												nodeId="vessel-list"
												labelText="Vessels list"
												// labelIcon={HistoryEduIcon}
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="6"
										labelText="Port"
										className="elemheaders"
										labelIcon={AnchorIcon}
									>
										<Link to="/port-list/">
											<StyledTreeItem
												nodeId="portList"
												labelText="Ports list"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="16"
										labelText="Depot"
										className="elemheaders"
										labelIcon={WarehouseIcon}
									>
										<Link to="/create-depot/">
											<StyledTreeItem
												nodeId="create-depot"
												labelText="Create depot"
											/>
										</Link>
										<Link to="/depot-list/">
											<StyledTreeItem
												nodeId="depotList"
												labelText="Depots list"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="7"
										labelText="Container"
										className="elemheaders"
										labelIcon={ContainerIcon}
									>
										<Link to="/create-container/">
											<StyledTreeItem
												nodeId="create-container"
												labelText="Create container"
											/>
										</Link>
										<Link to="/container-list/">
											<StyledTreeItem
												nodeId="container-list"
												labelText="Containers list"
												// labelIcon={HistoryEduIcon}
											/>
										</Link>
										<Link to="/assigned-container-list/">
											<StyledTreeItem
												nodeId="assigned-container-list"
												labelText="Assigned containers"
												// labelIcon={HistoryEduIcon}
											/>
										</Link>
										<Link to="/update-containers-status-by-voyage/">
											<StyledTreeItem
												nodeId="status-update-by-voyage"
												labelText="Status update by voyage"
											/>
										</Link>
										<Link to="/update-containers-status-by-depot/">
											<StyledTreeItem
												nodeId="status-update-by-depot"
												labelText="Status update by depot"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="8"
										labelText="Contact"
										className="elemheaders"
										labelIcon={PeopleAlt}
									>
										<Link to="/create-contact/">
											<StyledTreeItem
												nodeId="create-contact"
												labelText="Create contact"
											/>
										</Link>
										<Link to="/contact-list/">
											<StyledTreeItem
												nodeId="contact-list"
												labelText="Contacts list"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="17"
										labelText="Commodity"
										className="elemheaders"
										labelIcon={LocalShippingIcon}
									>
										<Link to="/create-commodity/">
											<StyledTreeItem
												nodeId="create-commodity"
												labelText="Create Commodity"
												className="elemheaders"
											/>
										</Link>
										<Link to="/commodity-list/">
											<StyledTreeItem
												nodeId="commodity-list"
												labelText="Commodities list"
												className="elemheaders"
											/>
										</Link>
									</StyledTreeItem>
									<Divider />
									<StyledTreeItem
										nodeId="10"
										labelText="Contract"
										className="elemheaders"
										labelIcon={ContractIcon}
									>
										<Link to="/create-contract/">
											<StyledTreeItem
												nodeId="create-contract"
												labelText="Create contract"
											/>
										</Link>
										<Link to="/contract-list/">
											<StyledTreeItem
												nodeId="contract-list"
												labelText="Contracts list"
											/>
										</Link>
									</StyledTreeItem>{' '}
								</>
							)}
							<Divider />
							{isAccountant && (
								<>
									<StyledTreeItem
										nodeId="14"
										labelText="Invoice"
										className="elemheaders"
										labelIcon={InvoiceIcon}
									>
										<Link to="/create-invoice/">
											<StyledTreeItem
												nodeId="create-invoice"
												labelText="Create invoice"
											/>
										</Link>
										<Link to="/create-credit-entry/">
											<StyledTreeItem
												nodeId="create-credit"
												labelText="Add credit to booking"
											/>
										</Link>
										<Link to="/invoice-list/">
											<StyledTreeItem
												nodeId="invoice-list"
												labelText="Invoices list"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="15"
										labelText="Debit"
										className="elemheaders"
										labelIcon={InvoiceIcon}
									>
										<Link to="/create-debit-entry/">
											<StyledTreeItem
												nodeId="create-debit-entry"
												labelText="Create Debit Entry"
											/>
										</Link>
										<Link to="/debit-entry-list/">
											<StyledTreeItem
												nodeId="debit-entry-list"
												labelText="Debit Entries list"
											/>
										</Link>
									</StyledTreeItem>
								</>
							)}

							<Divider />
							{isAdmin && (
								<StyledTreeItem
									nodeId="11"
									labelText="Settings"
									className="elemheaders"
									labelIcon={SettingsIcon}
								>
									<Link to="/users/">
										<StyledTreeItem
											nodeId="users"
											labelText="Users management"
										/>
									</Link>
									<Link to="/container-type-list/">
										<StyledTreeItem
											nodeId="container-type-list"
											labelText="Container types"
										/>
									</Link>
									<StyledTreeItem
										nodeId="12"
										labelText="Bank"
										className="elemheaders"
										labelIcon={BankIcon}
									>
										<Link to="/create-bank/">
											<StyledTreeItem
												nodeId="create-bank"
												labelText="Create bank"
											/>
										</Link>
										<Link to="/bank-list/">
											<StyledTreeItem
												nodeId="bank-list"
												labelText="Banks list"
											/>
										</Link>
									</StyledTreeItem>
									<StyledTreeItem
										nodeId="13"
										labelText="Currency"
										className="elemheaders"
										labelIcon={CurrencyIcon}
									>
										<Link to="/create-currency/">
											<StyledTreeItem
												nodeId="create-currency"
												labelText="Create currency"
											/>
										</Link>
										<Link to="/currency-list/">
											<StyledTreeItem
												nodeId="currency-list"
												labelText="Currencies list"
											/>
										</Link>
									</StyledTreeItem>
								</StyledTreeItem>
							)}
						</TreeView>
					</List>
				</Drawer>
			)}
		</>
	)
}

export default Header
