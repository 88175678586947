import React from 'react'
import Box from '@material-ui/core/Box'

const TabPanel = (props) => {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-TabPanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<div style={{ overflowX: 'hidden' }}>{children}</div>
				</Box>
			)}
		</div>
	)
}

export default TabPanel
