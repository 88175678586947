import * as React from 'react'
import './style.css'
import Logo from '../../EditBillOfLanding/printBillOfLanding/asset/logoBill.png'

const subBillOfLadingTemplateEN = ({ subBillOfLading }) => {
	var waterMark = ''
	if (subBillOfLading) {
		if (subBillOfLading.oiRouting === 'Original') {
			waterMark = 'original'
		} else if (
			subBillOfLading.oiRouting === 'Draft' ||
			subBillOfLading.oiRouting === 'Draft (Sea WayBill)'
		) {
			waterMark = 'draft'
		} else if (subBillOfLading.oiRouting === 'Telex release') {
			waterMark = 'telex'
		} else if (subBillOfLading.oiRouting === 'Sea way bill') {
			waterMark = 'waybill'
		}
	}
	var kg = 0
	const date1 = subBillOfLading && new Date(subBillOfLading.containers)
	subBillOfLading &&
		subBillOfLading.containers.map((obj) => {
			kg += Number(obj.weight)
		})

	var packages = 0
	subBillOfLading &&
		subBillOfLading.containers.map((obj) => {
			packages += Number(obj.quantity)
		})

	window.onload = addPageNumbers

	function addPageNumbers() {
		var totalPages = Math.ceil(document.body.scrollHeight / 1123) //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
		return Number(totalPages)
	}

	const height = addPageNumbers() * 100

	let charCounter = 0
	let breakLinesCounter = 0

	return (
		<>
			{subBillOfLading && subBillOfLading.bol.booking && (
				<>
					<div className={'containerBillOfLanding ' + waterMark}>
						{
							<style media="print">
								{`.containerBillOfLanding:after {
						  			height:${height}% !important;
									}`}
							</style>
						}
						<section className="row vertical70 borderTop borderLeft borderRight">
							<div className="block50 blockLogo">
								<img src={Logo} className="logoPng" />
								<section className="companyInfo">
									<p>AsasLine S.A</p>
									<p>www.asasline.com</p>
								</section>
							</div>
							<div className="block25">
								<h1 className="title">
									{subBillOfLading.oiRouting === 'Draft (Sea WayBill)'
										? 'SEA WAYBILL'
										: 'BILL OF LADING'}
								</h1>
								<p className="slogan">
									For ocean transport or multimodel transpon
								</p>
							</div>
							<div className="block25">
								<section className="vertical35 bottomLine verticalAlign">
									<h2 className="labelPdfBill dataInlineLabel">B/L NO :</h2>
									<p className="data dataInline">{subBillOfLading.number}</p>
								</section>
								<section className="vertical35 verticalAlign">
									<h2 className="labelPdfBill dataInlineLabel">SCAC :</h2>
									<p className="data dataInline">{subBillOfLading.csac}</p>
								</section>
							</div>
						</section>

						<section className="row vertical90 borderLeft borderRight">
							<div className="block50">
								<h2 className="labelPdfBill">Shipper :</h2>
								<ul className="billOfladingContact">
									<li>{subBillOfLading?.shipper?.company}</li>
									<li>{subBillOfLading?.shipper?.name}</li>
									<li>
										{subBillOfLading?.shipper?.address}{' '}
										{subBillOfLading?.shipper?.postalCode}
									</li>
									<li>
										{subBillOfLading?.shipper?.email}{' '}
										{subBillOfLading?.shipper?.phone &&
										subBillOfLading?.shipper?.phone !== ''
											? 'TEL:' + subBillOfLading.shipper.phone
											: ' '}
									</li>
								</ul>
							</div>
							<div className="block25">
								<section className="vertical35 bottomLine verticalAlign">
									<h2 className="labelPdfBill">Booking : </h2>
									<p className="data dataInline">
										&nbsp;
										{subBillOfLading.bol.booking.number}
									</p>
								</section>
								<section className="vertical50 verticalAlign">
									<h2 className="labelPdfBill">Export ref :</h2>
									<p className="data dataInline">
										&nbsp;
										{subBillOfLading?.exportReference}
									</p>
								</section>
							</div>
							<div className="block25">
								<section className="vertical35 bottomLine verticalAlign">
									<h2 className="labelPdfBill">Svc contract :</h2>
									<p className="data dataInline">
										{subBillOfLading?.svcContract}
									</p>
								</section>
								<section className="vertical50">
									<h2 className="labelPdfBill">Owward inland routing :</h2>
									{subBillOfLading.oiRouting === 'Original' ? (
										<p className="data">
											<span className="originalStyle">
												{subBillOfLading.oiRouting}
											</span>
										</p>
									) : subBillOfLading.oiRouting === 'Telex release' ? (
										<p className="data">
											<span className="telexStyle">
												{subBillOfLading.oiRouting}
											</span>
										</p>
									) : (
										<p className="data">{subBillOfLading.oiRouting}</p>
									)}
								</section>
							</div>
						</section>

						<section className="row vertical90 borderLeft borderRight">
							<div className="block50">
								<h2 className="labelPdfBill">Consignees :</h2>
								<ul className="billOfladingContact">
									<li>{subBillOfLading.consignees.company}</li>
									<li>{subBillOfLading.consignees.name}</li>
									<li>
										{subBillOfLading.consignees.address}{' '}
										{subBillOfLading.consignees.postalCode}
									</li>
									<li>
										{subBillOfLading.consignees.email}{' '}
										{subBillOfLading.consignees.phone &&
										subBillOfLading.consignees.phone !== ''
											? 'TEL: ' + subBillOfLading.consignees.phone
											: ''}
									</li>
								</ul>
							</div>
							<div className="block50">
								<h2 className="labelPdfBill">Forwarding Agent :</h2>
								<ul className="billOfladingContact">
									<li>{subBillOfLading.forwardingAgent?.company}</li>
									<li>{subBillOfLading.forwardingAgent?.name}</li>
									<li>
										{subBillOfLading.forwardingAgent?.address}{' '}
										{subBillOfLading.forwardingAgent?.postalCode}
									</li>
									<li>
										{subBillOfLading.forwardingAgent?.email}{' '}
										{subBillOfLading.forwardingAgent?.phone &&
										subBillOfLading.forwardingAgent?.phone !== ''
											? 'TEL: ' + subBillOfLading.forwardingAgent?.phone
											: ''}
									</li>
								</ul>
							</div>
						</section>
						<section className="row vertical90 borderLeft borderRight">
							<div className="block50">
								<h2 className="labelPdfBill">Notify party :</h2>
								<ul className="billOfladingContact">
									<li>{subBillOfLading.notifyParty?.company}</li>
									<li>{subBillOfLading.notifyParty?.name}</li>
									<li>
										{subBillOfLading.notifyParty?.address}{' '}
										{subBillOfLading.notifyParty?.postalCode}
									</li>
									<li>
										{subBillOfLading.notifyParty?.email}{' '}
										{subBillOfLading.notifyParty?.phone &&
										subBillOfLading.notifyParty?.phone !== ''
											? 'TEL: ' + subBillOfLading.notifyParty?.phone
											: ''}
									</li>
								</ul>
							</div>

							<div className="block50">
								<h2 className="labelPdfBill">Additional Notify Party:</h2>
								<ul className="billOfladingContact">
									<li>{subBillOfLading.additionalNotifyParty?.company}</li>
									<li>{subBillOfLading.additionalNotifyParty?.name}</li>
									<li>
										{subBillOfLading.additionalNotifyParty?.address}{' '}
										{subBillOfLading.additionalNotifyParty?.postalCode}
									</li>
									<li>
										{subBillOfLading.additionalNotifyParty?.email}{' '}
										{subBillOfLading.additionalNotifyParty &&
										subBillOfLading.additionalNotifyParty?.phone !== ''
											? 'TEL: ' + subBillOfLading.additionalNotifyParty?.phone
											: ''}
									</li>
								</ul>
							</div>
						</section>
						<section className="row vertical40 borderLeft borderRight">
							<div className="block25">
								<h2 className="labelPdfBill">Vessel :</h2>
								<p className="data">
									{subBillOfLading.bol.booking?.voyage.ship?.name}
								</p>
							</div>
							<div className="block25">
								<h2 className="labelPdfBill">Voyage N° :</h2>
								<p className="data">
									{subBillOfLading.bol.booking?.voyage?.number}
								</p>
							</div>
							<div className="block50">
								<p className="PlaceDelivery">
									Place of reciept, Applicabale only when document used as
									multimodal waybill{' '}
									<span className="PlaceDeliveryValue">
										{subBillOfLading.placeOfReceipt}
									</span>
								</p>
							</div>
						</section>

						<section className="row vertical40 borderLeft borderRight borderBottom">
							<div className="block25">
								<h2 className="labelPdfBill">Port of loading :</h2>
								<p className="data">
									{subBillOfLading.bol?.booking?.voyage?.loadingPort?.name}
								</p>
							</div>
							<div className="block25">
								<h2 className="labelPdfBill">Port of discharge :</h2>
								<p className="data">
									{subBillOfLading.bol?.booking?.voyage?.unLoadingPort?.name}
								</p>
							</div>
							<div className="block50">
								<p className="PlaceDelivery">
									Place of delivery,Applicabale only when document used as
									multimodal transport B/L (see Clause 1){' '}
									<span className="PlaceDeliveryValue">
										{subBillOfLading.placeOfDelivery}
									</span>
								</p>
							</div>
						</section>

						<section>
							<p className="productlistTitle">
								PARTUICULARS FURNISHED BY SHIPPER
							</p>
						</section>
						<section>
							<div className="productlist">
								<div className="productlistLabelrowContainer">
									{/* <div className="productlistLabelrow productlistLabelrowOrder">
									Marks and numbers
								</div> */}
									<div className="productlistLabelrow productlistLabelrowDescription">
										Description of goods & kind of packages & containers No./
										Seal No
									</div>
									<div className="productlistLabelrow productlistLabelrowWeight">
										Weight
									</div>
									<div className="productlistLabelrow productlistLabelrowSize">
										Measurement
									</div>
								</div>

								{subBillOfLading.containers &&
									subBillOfLading.containers.map((elm) => {
										const words = elm?.description.split(' ')
										const spaceCount = words.filter(
											(word) => word === ' '
										).length
										charCounter += elm?.description.length + spaceCount
										breakLinesCounter += elm?.description?.match(/\n/g)?.length
									})}

								{subBillOfLading.containers &&
									subBillOfLading.containers.length > 4 && (
										<div
											className="productlisRowContainer"
											style={{ whiteSpace: 'pre-line' }}
										>
											<div className="productlistrowDescription small">
												<h2>See annexe</h2>
											</div>
										</div>
									)}

								{subBillOfLading.containers &&
									subBillOfLading.containers?.map((elm) => {
										if (
											subBillOfLading.containers?.length > 4 ||
											charCounter > 300 ||
											breakLinesCounter > 11
										) {
											if (subBillOfLading.containers?.length < 4) {
												return (
													<div
														className="productlisRowContainer"
														key={elm?.id}
														style={{ whiteSpace: 'pre-line' }}
													>
														<div className="productlistrowDescription small">
															No.{elm?.container?.number} /{' '}
															{elm?.container?.type?.type} (See annexe)
														</div>
														<div className="productlistrowWeight">
															{elm?.weight} KGS
														</div>
														<div className="productlistrowSize">
															{elm?.measurement}
														</div>
													</div>
												)
											}
										} else
											return (
												<div
													className="productlisRowContainer"
													key={elm?.id}
													style={{ whiteSpace: 'pre-line' }}
												>
													<div className="productlistrowDescription small">
														No.{elm?.container.number} /{' '}
														{elm?.container.type.type} <br />
														Package type: {elm?.packageType} /Seal No.{' '}
														{elm?.sealNumber} / {elm?.quantity}{' '}
														{elm?.packageType}
														<br />
														{elm?.description?.replace(/\n{2,}\s*/g, '\n')}
													</div>
													<div className="productlistrowWeight">
														{elm?.weight} KGS
													</div>
													<div className="productlistrowSize">
														{elm?.measurement}
													</div>
												</div>
											)
									})}

								<div className="productlisRowContainer">
									<div className="productlistrowDescription">
										<strong style={{ paddingLeft: '20px' }}>
											SHIPPER'S LOAD, STOW, WEIGHT AND COUNT
										</strong>
									</div>
									<div className="productlistrowWeight"></div>
									<div className="productlistrowSize"></div>
								</div>
							</div>
							<div className="totalInfo">
								<p>
									{subBillOfLading.containers.length}
									{subBillOfLading.containers.length > 1
										? ' Containers'
										: ' Container'}
								</p>
								<p>{subBillOfLading && '//Total Packages: ' + packages}</p>
								<p>{subBillOfLading && '//Total weight: ' + kg + 'KGS'}</p>
							</div>
						</section>

						<section className="row borderLeft borderRight">
							<div className="block50 block30" style={{ paddingTop: '5px' }}>
								<p className="data">
									{subBillOfLading.freightPayableAt === 'Origin'
										? 'FREIGHT PREPAID'
										: 'FREIGHT COLLECT'}
								</p>
								<hr style={{ marginTop: '5px' }} />
								<h2 className="labelPdfBill">Shipped on bored:</h2>
								{/* <p className="data">
									{date1 && subBillOfLading.freightPrepaid !== ''
										? ('0' + date1.getDate()).slice(-2) +
										  ' / ' +
										  ('0' + (date1.getMonth() + 1)).slice(-2) +
										  ' / ' +
										  date1.getFullYear()
										: ''}
								</p> */}
								<p className="data">
									{(
										'0' +
										new Date(
											Number(subBillOfLading.booking?.voyage?.departureDate)
										).getDate()
									).slice(-2) +
										' / ' +
										(
											'0' +
											(new Date(
												Number(subBillOfLading.booking?.voyage?.departureDate)
											).getMonth() +
												1)
										).slice(-2) +
										' / ' +
										new Date(
											Number(subBillOfLading.booking?.voyage?.departureDate)
										).getFullYear()}
								</p>
							</div>
							<div className="block50 block70 smallTxt">
								<p style={{ marginLeft: '5px', marginTop: '5px' }}>
									<strong>Shipped</strong> at the Port of Loading in apparent
									good order and condition on board the Vessel for carriage to
									the Port of Discharge or so near thereto as she may safely get
									this goods specified above. Weight, measure, quality,
									quantity, condition, contents and value unknown.
								</p>
								<p style={{ marginLeft: '5px' }}>
									<strong>IN WITNESS</strong> whereof the Master or Agent of the
									said Vessel has signed the number of Bills of Lading indicated
									below all of this tenor and date, Any one of which being
									accomplished the others shall be void. FOR CONDITIONS OF
									CARRIAGE SEE OVERLEAF
								</p>
							</div>
						</section>
						<section className="row vertical60 rowReverse borderRight">
							<div className="block40">
								<div className="labelPadding vertical30 borderRight borderLeft borderTop borderBottom">
									Freight payable at
								</div>
								<div className="data centerData vertical30 borderRight borderLeft borderTop borderBottom">
									{subBillOfLading.freightPayableAt}
								</div>
							</div>
							<div className="block40 floatRight">
								<div className="labelPadding vertical30 borderRight borderLeft borderTop borderBottom">
									Place and date of issue
								</div>
								<div className="data centerData vertical30 borderRight borderLeft borderTop borderBottom">
									{subBillOfLading.dateOfIssue}
								</div>
							</div>
						</section>
						<section className="row rowReverse borderRight">
							<div className="block40 floatRight">
								<div className="labelPadding vertical30 borderRight borderLeft borderTop borderBottom">
									Number of original B/L
								</div>
								<div className="data centerData vertical70 borderRight borderLeft borderTop borderBottom">
									{subBillOfLading.numberOfOriginalBOL}
								</div>
							</div>
							<div className="block40 floatRight">
								<div className="labelPadding vertical30 borderRight borderLeft borderTop borderBottom">
									Signature
								</div>
								<div className="data vertical70 borderRight borderLeft borderTop borderBottom"></div>
								{/* <div id="pageFooter"></div> */}
							</div>
							{/* <div class="page-footer">I'm The Footer</div> */}
						</section>
						{subBillOfLading.containers.length > 3 ||
						charCounter > 300 ||
						breakLinesCounter > 11 ? (
							<>
								<div style={{ pageBreakBefore: 'always' }} />
								<section>
									<div
										style={{
											width: '100%',
											border: '1px solid black',
											textAlign: 'center',
										}}
									>
										<h2>Annex</h2>
									</div>
									<div className="productlist">
										<div className="productlistLabelrowContainer">
											<div className="productlistLabelrow productlistLabelrowDescription">
												Description of goods & kind of packages & containers
												No./ Seal No
											</div>
											<div className="productlistLabelrow productlistLabelrowWeight">
												Weight
											</div>
											<div className="productlistLabelrow productlistLabelrowSize">
												Measurement
											</div>
										</div>
										{subBillOfLading.containers.map((elm) => {
											return (
												<>
													<div
														className="productlisRowContainer"
														key={elm?.id}
														style={{ whiteSpace: 'pre-line' }}
													>
														<div className="productlistrowDescription small">
															No.{elm?.container.number} /{' '}
															{elm?.container.type.type} <br />
															Package type: {elm?.packageType} /Seal No.{' '}
															{elm?.sealNumber} / {elm?.quantity}{' '}
															{elm?.packageType}
															<br />
															{elm?.description?.replace(/\n{2,}\s*/g, '\n')}
														</div>
														<div className="productlistrowWeight">
															{elm?.weight} KGS
														</div>
														<div className="productlistrowSize">
															{elm?.measurement}
														</div>
													</div>
												</>
											)
										})}
									</div>
								</section>
							</>
						) : (
							''
						)}
					</div>
				</>
			)}
		</>
	)
}

export default subBillOfLadingTemplateEN
