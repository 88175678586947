import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { _ } from 'lodash'

import TextField from '@material-ui/core/TextField'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { Button } from '@material-ui/core'

import * as moment from 'moment'

import '../../../asset/style.css'

const TRACK_CONTAINER = loader(
	'./graphql/containerTrackByBookingNumber.graphql'
)

const TrackByBooking = () => {
	const [bookingNumber, setBookingNumber] = useState('')

	const [
		getHistory,
		{ data = null, loading: containerLoading, error: containerError },
	] = useLazyQuery(TRACK_CONTAINER)

	const handleFetchBookingHistory = (e) => {
		e.preventDefault()
		const ContainerHistory = getHistory({
			variables: {
				number: bookingNumber,
			},
		})

		return ContainerHistory
	}
	const Row = (props) => {
		const [open, setOpen] = React.useState(false)

		return (
			<React.Fragment>
				<TableRow
					sx={{ '& > *': { borderBottom: 'unset' } }}
					hover
					key={props.ctr.id}
				>
					<TableCell>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell component="th" scope="row">
						{props.ctr.containerNbr}
					</TableCell>
					<TableCell align="right">{props.ctr.isoNumber}</TableCell>
					<TableCell align="right">{props.ctr.type}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<Typography variant="h6" gutterBottom component="div">
									History
								</Typography>
								<Table size="small" aria-label="purchases">
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell align="right">State</TableCell>
											<TableCell align="right">Location</TableCell>
											<TableCell align="right">Depot</TableCell>
											<TableCell align="right">Updated by</TableCell>
											<TableCell align="right">Note</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{props.ctr.histories.map((history) => {
											return (
												<TableRow key={history.id} hover>
													<TableCell component="th" scope="row">
														{moment(Number(history.createdAt)).format(
															'DD/MM/YYYY hh:mm:ss'
														)}
													</TableCell>
													<TableCell align="right">
														{history.status.status}
													</TableCell>
													<TableCell align="right">
														{history.port?.name ? history.port?.name : 'N/A'}
													</TableCell>
													<TableCell align="right">
														{history.depot?.name ? history.depot?.name : 'N/A'}
													</TableCell>
													<TableCell align="right">
														{history.user?.name}
													</TableCell>
													<TableCell align="right">{history.remark}</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		)
	}

	function formatFollowers(containers) {
		const categories = [
			...new Set(containers.map((containerObj) => containerObj.container.id)),
		]
		return categories.reduce((acc, container) => {
			const _containers = containers.filter(
				(containerObj) => containerObj.container.id === container
			)
			return [
				...acc,
				{
					id: _containers[0]?.container?.id,
					containerNbr: _containers[0]?.container?.number,
					isoNumber: _containers[0]?.container?.isoNumber,
					type: _containers[0]?.container.type.type,
					number: container,
					histories: _containers,
				},
			]
		}, [])
	}

	return (
		<>
			<Box className="mainZone" component="form" noValidate autoComplete="off">
				<section className="bookingSearchSection">
					<TextField
						fullWidth
						label="Track booking"
						variant="filled"
						size="small"
						margin="normal"
						defaultValue={bookingNumber}
						onChange={({ target: { value } }) => {
							setBookingNumber(value)
						}}
					/>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						onClick={handleFetchBookingHistory}
						style={{ marginLeft: '20px' }}
					>
						Search
					</Button>
				</section>
				{containerError && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						{containerError.graphQLErrors[0].message}
					</Paper>
				)}
				{containerLoading && (
					<Paper variant="outlined" style={{ width: '100%', padding: '10px' }}>
						Loading...
					</Paper>
				)}
				{data && data.containerTrackByBookingNumber && (
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>Container</TableCell>
									<TableCell align="right">ISO Number</TableCell>
									<TableCell align="right">Type</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{formatFollowers(data?.containerTrackByBookingNumber).map(
									(elm) => {
										return <Row ctr={elm} />
									}
								)}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Box>
		</>
	)
}

export default TrackByBooking
